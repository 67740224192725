import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './helpers/translations/en/EN-POP-WEB-translation-done.json'; 
import translationES from './helpers/translations/es/ES-POP-WEB-translation-done.json';
import translationCAT from './helpers/translations/cat/CAT-POP-WEB-translation-done.json';

const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  },
  cat: {
    translation: translationCAT
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: window.localStorage.getItem("i18nextLng") || "es",
    fallbackLng: "es",
    debug: true,
    interpolation: {
        escapeValue: false,
      },
  });

export default i18n;