import config from "../config/config";
import { authenticationService } from "./authentication.service";

export const restaurantsService = {
  getRestaurants,
  getProvinces,
  getServices
};

function getServices() {
  const requestOptions = {
    method: "GET",
    headers: authenticationService.prepareHeader(),
  };

  return fetch(
    `${config.apiUrl}/api/v1/services?language=${config.language}&key=${config.key}`,
    requestOptions
  ).then(authenticationService.handleResponse);
}

function getRestaurants() {
  const requestOptions = {
    method: "GET",
    headers: authenticationService.prepareHeader(),
  };

  return fetch(
    `${config.apiUrl}/api/v1/restaurants?language=${config.language}&key=${config.key}`,
    requestOptions
  ).then(authenticationService.handleResponse);
}

function getProvinces() {
    const requestOptions = {
      method: "GET",
      headers: authenticationService.prepareHeader(),
    };
  
    return fetch(
      `${config.apiUrl}/api/v1/provinces?language=${config.language}&key=${config.key}`,
      requestOptions
    ).then(authenticationService.handleResponse);
  }