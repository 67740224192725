import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./LegalsRewards.scss";

class LegalsRewards extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  render() {
    const { t } = this.props;
    return (
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center">
          <div className="w-100 title col-md-12 col-ls-12 bg-primary d-flex justify-content-center align-items-center">
            <div>
              <h1 className="condicion-reward text-white title-privacy mt-4">
                {t("RecompensasLegals.Condiciones-Legales")}
              </h1>
            </div>
          </div>
          <div className="col-md-12 p-3">
            <div className="card text-left mt-4 mb-4 border-0">
              <div className="card-text">
                <span>
                  {t("RecompensasLegals.Fidelización-denominado")}{" "}
                  <span className="text-primary sans-bold">
                    {t("RecompensasLegals.MIS-RECOMPENSAS")}
                  </span>
                  .{t("RecompensasLegals.Empresa-responsable")}
                </span>
                <br />
                <br />
                {t("RecompensasLegals.Programa-de-Fidelidad")}{" "}
                <span className="sans-bold text-primary">
                  {t("RecompensasLegals.MIS-RECOMPENSAS")}
                </span>{" "}
                {t("RecompensasLegals.Términos-y-Condiciones")}
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("RecompensasLegals.PRIMERA")}
                </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.PRIMERA-paragraf-1")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.PRIMERA-paragraf-2")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.PRIMERA-paragraf-3")}</span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("RecompensasLegals.SEGUNDA")}
                </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.SEGUNDA-paragraf-1")} </span>
                <br /> <br />
                <span>{t("RecompensasLegals.SEGUNDA-paragraf-2")} </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.SEGUNDA-paragraf-3")} </span>
                <br />
                <br />
                <span className="text-primary sans-bold">
                  {t("RecompensasLegals.TERCERA")}
                </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.TERCERA-paragraf-1")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.TERCERA-paragraf-2")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.TERCERA-paragraf-3")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.TERCERA-paragraf-4")} </span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("RecompensasLegals.CUARTA")}
                </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.CUARTA-paragraf-1")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.CUARTA-paragraf-2")}</span> <br />
                <br />
                <span>{t("RecompensasLegals.CUARTA-paragraf-3")} </span> <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("RecompensasLegals.Obtención-de-estrellas")}
                </span>{" "}
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.Obtención-de-estrellas-paragraf-1")}
                </span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.Obtención-de-estrellas-paragraf-2")}
                </span>
                <br />
                <br />
                <ul>
                  <li>{t("RecompensasLegals.Con-3-estrellas")}</li>
                  <li>{t("RecompensasLegals.Con-6-estrellas")}</li>
                  <li>{t("RecompensasLegals.Con-9-estrellas")}</li>
                </ul>
                <br />
                <span>
                  {t("RecompensasLegals.Obtención-de-estrellas-paragraf-3")}
                </span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.Obtención-de-estrellas-paragraf-4")}
                </span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.Obtención-de-estrellas-paragraf-5")}{" "}
                </span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.Obtención-de-estrellas-paragraf-6")}
                </span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.Obtención-de-estrellas-paragraf-7")}
                </span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.Obtención-de-estrellas-paragraf-8")}
                </span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("RecompensasLegals.Consulta-de-estrellas")}
                </span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.Consulta-de-estrellas-paragraf-1")}{" "}
                </span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.Consulta-de-estrellas-paragraf-2")}
                </span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("RecompensasLegals.Cómo-utilizar")}
                </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.Cómo-utilizar-paragraf-1")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.Cómo-utilizar-paragraf-2")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.Cómo-utilizar-paragraf-3")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.Cómo-utilizar-paragraf-4")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.Cómo-utilizar-paragraf-5")} </span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("RecompensasLegals.Cambios-o-modificaciones")}
                </span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.Cambios-o-modificaciones-paragraf-1")}
                </span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.Cambios-o-modificaciones-paragraf-2")}
                </span>
                <br />
                <br />
                <span className="border-bottom">
                  {t("RecompensasLegals.Cambios-o-modificaciones-paragraf-3")}
                </span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.Cambios-o-modificaciones-paragraf-4")}
                </span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.Cambios-o-modificaciones-paragraf-5")}
                </span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.Cambios-o-modificaciones-paragraf-6")}
                </span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("RecompensasLegals.QUINTA")}
                </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.QUINTA-paragraf-1")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.QUINTA-paragraf-2")}</span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("RecompensasLegals.SEXTA")}
                </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.SEXTA-paragraf-1")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.SEXTA-paragraf-2")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.SEXTA-paragraf-3")} </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.SEXTA-paragraf-4")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.SEXTA-paragraf-5")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.SEXTA-paragraf-6")}</span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("RecompensasLegals.SÉPTIMA")}
                </span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.SÉPTIMA-paragraf-1-part-1")}{" "}
                  <a target="_blank" rel="noreferrer" href="https://popeyes.es">
                    https://popeyes.es
                  </a>{" "}
                  {t("RecompensasLegals.SÉPTIMA-paragraf-1-part-2")}
                </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.SÉPTIMA-paragraf-2")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.SÉPTIMA-paragraf-3")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.SÉPTIMA-paragraf-4")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.SÉPTIMA-paragraf-5")}</span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("RecompensasLegals.OCTAVA")}{" "}
                </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.OCTAVA-paragraf-1")}</span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("RecompensasLegals.NOVENA")}
                </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.NOVENA-paragraf-1")}</span>
                <br />
                <br />
                <address>
                  {t("RecompensasLegals.NOVENA-Contacto")}{" "}
                  <a href="mailto:atencionalclientepopeyes@popeyesiberia.com">
                    atencionalclientepopeyes@popeyesiberia.com
                  </a>
                </address>
                <address>
                  {t("RecompensasLegals.NOVENA-Protección")}{" "}
                  <a href="mailto:legal@spaincompliance.com">
                    legal@spaincompliance.com
                  </a>
                </address>
                <br />
                <br />
                <span>{t("RecompensasLegals.NOVENA-paragraf-2")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.NOVENA-paragraf-3")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.NOVENA-paragraf-4")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.NOVENA-paragraf-5")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.NOVENA-paragraf-6")}</span>
                <br />
                <br />
                <ul>
                  <li>{t("RecompensasLegals.NOVENA-paragraf-7")}</li>
                  <li>{t("RecompensasLegals.NOVENA-paragraf-8")}</li>
                  <li>{t("RecompensasLegals.NOVENA-paragraf-9")} </li>
                  <li>{t("RecompensasLegals.NOVENA-paragraf-10")}</li>
                  <li>{t("RecompensasLegals.NOVENA-paragraf-11")}</li>
                  <li>{t("RecompensasLegals.NOVENA-paragraf-12")}</li>
                </ul>
                <br />
                <span>
                  {t("RecompensasLegals.NOVENA-paragraf-13")}
                  <br />{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://popeyes-canaletico.appcore.es"
                  >
                    https://popeyes-canaletico.appcore.es
                  </a>
                  .{" "}
                </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.NOVENA-paragraf-14")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.NOVENA-paragraf-15")}</span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.NOVENA-paragraf-16")}{" "}
                  <a target="_blank" rel="noreferrer" href="https://aepd.es">
                    www.aepd.es
                  </a>
                  {" "}
                </span>
                <br />
                <br />
                <span>
                  {t("RecompensasLegals.NOVENA-paragraf-17")}{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://popeyes.es/politica-de-privacidad"
                  >
                    https://popeyes.es/politica-de-privacidad
                  </a>
                  {" "}
                </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.NOVENA-paragraf-18")} </span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("RecompensasLegals.DÉCIMA")}
                </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.DÉCIMA-paragraf-1")} </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.DÉCIMA-paragraf-2")}</span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("RecompensasLegals.UNDÉCIMA")}
                </span>
                <br />
                <br />
                <span>{t("RecompensasLegals.UNDÉCIMA-paragraf-1")}</span>
                <br />
                <br />
                <span>{t("RecompensasLegals.UNDÉCIMA-paragraf-2")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(LegalsRewards);
