import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {ScreenName} from "../../analytics/analytics.config";
import {analyticsEvents} from "../../analytics/Analytics";
import {Helmet} from "react-helmet";

class Legal extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    analyticsEvents.logScreenView(ScreenName.LEGAL_NOTICE)
  };
  render() {
    const { t } = this.props;
    return (
      <>
      <Helmet>
        <title>Aviso legal - Popeyes Louisiana Kitchen</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center">
        <div className="w-100 title col-md-12 col-ls-12 bg-primary d-flex justify-content-center align-items-center">
            <div>
              <h1 className="text-white title-privacy mt-4">
              {t("Legal.Aviso-legal")}
              </h1>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card text-left mt-4 mb-4 border-0">
              <div className="card-text">
                <h4>{t("Legal.Aviso-legal-subtitle")}</h4>
                <br />
                <h4>
                  <b className="text-primary">{t("Legal.Objeto")}</b>
                </h4>
                <br />
                <span>
                  {t("Legal.Objeto-paragraf-1-part-1")}{" "}
                  <a href="https://popeyes.es" target="_blank" rel="noreferrer">
                    www.popeyes.es
                  </a>{" "}
                  {t("Legal.Objeto-paragraf-1-part-2")}
                </span>
                <br />
                <br />
                <span>{t("Legal.Objeto-paragraf-2")}</span>
                <br />
                <br />
                <span>{t("Legal.Objeto-paragraf-3")}</span>
                <br />
                <br />
                <span>{t("Legal.Objeto-paragraf-4")}</span>
                <br />
                <br />
                <span>{t("Legal.Objeto-paragraf-5")}</span>
                <br />
                <br />
                <br />
                <h4>
                  <b className="text-primary">{t("Legal.Identificación")}</b>
                </h4>
                <br />
                <span>{t("Legal.Identificación-paragraf-1")}</span>
                <br />
                <br />
                <ul>
                  <li>{t("Legal.Identificación-paragraf-2")}</li>
                  <br />
                  <li>{t("Legal.Identificación-paragraf-3")}</li>
                  <br />
                  <li>{t("Legal.Identificación-paragraf-4")}</li>
                  <br />
                  <li>{t("Legal.Identificación-paragraf-5")}</li>
                  <br />
                  <li>{t("Legal.Identificación-paragraf-6")}</li>
                </ul>
                <br />
                <h4>
                  <b className="text-primary">{t("Legal.Comunicaciones")}</b>
                </h4>
                <br />
                <span>{t("Legal.Comunicaciones-paragraf-1")}</span>
                <br />
                <br />
                <ul>
                  <li>
                    <address>
                      Email:{" "}
                      <a href="mailto:atencionalclientepopeyes@popeyesiberia.com">
                        atencionalclientepopeyes@popeyesiberia.com
                      </a>
                    </address>
                  </li>
                  <li>{t("Legal.Comunicaciones-paragraf-2")}</li>
                </ul>
                <br />
                <span>{t("Legal.Comunicaciones-paragraf-3")}</span>
                <br />
                <br />
                <br />
                <h4>
                  <b className="text-primary">
                    {t("Legal.Condiciones-de-acceso")}
                  </b>
                </h4>
                <br />
                <span>{t("Legal.Condiciones-de-acceso-paragraf-1")}</span>
                <br />
                <br />
                <span>{t("Legal.Condiciones-de-acceso-paragraf-2")}</span>
                <br />
                <br />
                <span>{t("Legal.Condiciones-de-acceso-paragraf-3")}</span>
                <br />
                <br />
                <ol>{t("Legal.Condiciones-de-acceso-paragraf-4")}</ol>
                <br />
                <ol>{t("Legal.Condiciones-de-acceso-paragraf-5")}</ol>
                <br />
                <ol>{t("Legal.Condiciones-de-acceso-paragraf-6")}</ol>
                <br />
                <ol>{t("Legal.Condiciones-de-acceso-paragraf-7")}</ol>
                <br />
                <ol>{t("Legal.Condiciones-de-acceso-paragraf-8")}</ol>
                <br />
                <ol>{t("Legal.Condiciones-de-acceso-paragraf-9")}</ol>
                <br />
                <ol>{t("Legal.Condiciones-de-acceso-paragraf-10")}</ol>
                <br />
                <span>{t("Legal.Condiciones-de-acceso-paragraf-11")}</span>
                <br />
                <br />
                <span>{t("Legal.Condiciones-de-acceso-paragraf-12")}</span>
                <br />
                <br />
                <span>{t("Legal.Condiciones-de-acceso-paragraf-13")}</span>
                <br />
                <br />
                <span>{t("Legal.Condiciones-de-acceso-paragraf-14")}</span>
                <br />
                <br />
                <span>{t("Legal.Condiciones-de-acceso-paragraf-15")}</span>
                <br />
                <br />
                <span>{t("Legal.Condiciones-de-acceso-paragraf-16")}</span>
                <br />
                <br />
                <br />
                <h4>
                  <b className="text-primary">
                    {t("Legal.Exclusión-de-garantías")}
                  </b>
                </h4>
                <br />
                <span>{t("Legal.Exclusión-de-garantías-paragraf-1")}</span>
                <br />
                <br />
                <span>{t("Legal.Exclusión-de-garantías-paragraf-2")}</span>
                <br />
                <br />
                <ol>{t("Legal.Exclusión-de-garantías-paragraf-3")}</ol>
                <br />
                <ol>{t("Legal.Exclusión-de-garantías-paragraf-4")}</ol>
                <br />
                <ol>{t("Legal.Exclusión-de-garantías-paragraf-5")}</ol>
                <br />
                <span>{t("Legal.Exclusión-de-garantías-paragraf-6")}</span>
                <br />
                <br />
                <br />
                <h4>
                  <b className="text-primary">{t("Legal.Procedimiento")}</b>
                </h4>
                <br />
                <span>{t("Legal.Procedimiento-paragraf")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default withTranslation()(Legal);
