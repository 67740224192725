import React, { Component } from "react";
import origen from "../../assets/img/calidad/CALIDAD-ORIGEN.jpg";
import elaboracion from "../../assets/img/calidad/CALIDAD-ELABORACION.jpg";
import seguridad from "../../assets/img/calidad/CALIDAD-SEGURIDAD.jpg";
import { withTranslation } from "react-i18next";
import {ScreenName} from "../../analytics/analytics.config";
import {analyticsEvents} from "../../analytics/Analytics";
import {Helmet} from "react-helmet";
import "./Quality.scss";

class Quality extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    analyticsEvents.logScreenView(ScreenName.QUALITY)
  };
  render() {
    const { t } = this.props;
    return (
      <>
      <Helmet>
        <title>Calidad - Popeyes Louisiana Kitchen</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center">
          <div className="title bg-primary d-flex justify-content-center align-items-center w-100 mb-3">
            <div>
              <h1 className="text-white title-h1 mt-4">
                {t("Calidad.Calidad")}
              </h1>
            </div>
          </div>
          <div className="card calidad border-0">
            <div className="card-body origen d-flex align-items-center">
              <div className="col-md-6 col-lg-6 d-flex flex-column justify-content-center align-items-center">
                <div className="col-md-10">
                  <h1 className="quality-font-size text-info text-font-chicken mb-4">
                    {t("Calidad.Origen")}
                  </h1>
                  <p className="sans-bold text-info">
                    {t("Calidad.Origen-subtitle")}
                  </p>
                </div>
                <div className="col-md-10 d-flex text-left mt-2 justify-content-center">
                  <p>
                    {t("Calidad.Origen-paragraf-1-part-1")}{" "}
                    <span className="sans-bold text-primary">
                      {t("Calidad.Origen-paragraf-1-part-2")}
                      </span>
                      <br/>
                    {t("Calidad.Origen-paragraf-1-part-3")}
                    <br />
                    <br />
                    <span className="sans-bold text-primary">
                      {t("Calidad.Origen-paragraf-2")}{" "}
                    </span>
                    {t("Calidad.Origen-paragraf-3")}
                  </p>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-center">
                <div className="col-md-10">
                  <img className="img-fluid" src={origen} alt="Origen" />
                </div>
              </div>
            </div>
          </div>
          <div className="card calidad border-0">
            <div className="card-body elaboracion d-flex align-items-center">
              <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                <div className="col-md-10">
                  <h1 className="card-title quality-font-size text-info text-font-chicken">
                    {t("Calidad.Elaboración")}
                  </h1>
                </div>
                <div className="col-md-10 d-flex text-left mt-4 justify-content-center">
                  <p>
                    {t("Calidad.Elaboración-paragraf-1")} <br />
                    <span className="sans-bold text-primary">
                      {t("Calidad.Elaboración-paragraf-2")}
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-center">
                <div className="col-md-10">
                  <img
                    className="img-fluid"
                    src={elaboracion}
                    alt="Elaboración"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card calidad border-0 mb-4 w-100">
            <div className="card-body seguridad d-flex align-items-center">
              <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                <div className="col-md-10">
                  <h1 className="card-title quality-font-size text-info text-font-chicken">
                    {t("Calidad.Seguridad")}
                  </h1>
                </div>
                <div className="col-md-10 d-flex text-left mt-4 justify-content-center">
                  <p>
                    {t("Calidad.Seguridad-paragraf-1")}
                    <br /> <br />
                    {t("Calidad.Seguridad-paragraf-2")}
                  </p>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-center">
                <div className="col-md-10">
                  <img className="img-fluid" src={seguridad} alt="Seguridad" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default withTranslation()(Quality);
