
export const ScreenName = {
  HOME: "home",
  CATALOG: "catalog",
  ITEM_DETAIL: "item_detail",
  NEWS: "news",
  RESTAURANTS: "restaurants",
  CONTACT: "contact",
  CONTACT_FORM: "public_contact_form",
  CONTACT_ORDER_FORM: "order_contact_form",
  LOYALTY_REWARDS: "loyalty_rewards",
  LOGIN: "login",
  REGISTER: "register",
  MAIL_VALIDATION_POPUP: "email_validation_popup",
  PERSONAL_DATA: "personal_data",
  QUALITY: "quality",
  HISTORY: "history",
  SUSTAINABILITY: "sustainability",
  LEGAL_NOTICE: "legal_notice",
  PRIVACY_POLICY: "privacy_policy",
  COOKIES_POLICY: "cookies_policy",
  NOT_FOUND: "not_found",
  SOCIAL_COMMITMENT: 'social_commitment',
};

export const CustomEventName = {
  SEND_CONTACT_FORM: "ev_send_form",
  REGISTER: "ev_register",
  NEWS_CLICK: "view_news",
  ITEM_CLICK: "view_item",
};
