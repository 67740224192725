import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import QR from "../../../assets/img/QR/QR.png";

class FormularioContacto extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center">
          <div className="w-100 title col-md-12 col-ls-12 bg-primary d-flex justify-content-center align-items-center">
            <div>
              <h1 className="text-white title-privacy mt-4">
                {t("ContactLegals.Contact-form")}
              </h1>
            </div>
          </div>
          <div className="col-md-12 p-3">
            <div className="card text-left mt-4 mb-4 border-0">
              <div className="card-text">
                <span className="sans-bold text-primary">
                  {t("ContactLegals.Basic-information")}
                </span>
                <br />
                <br />
                <span>
                  <b>{t("ContactLegals.Responsible")}</b>{" "}
                  {t("ContactLegals.Responsible-paragraf")}
                </span>
                <br />
                <br />
                <span>
                  <b>{t("ContactLegals.Purpose")}</b>{" "}
                  {t("ContactLegals.Purpose-paragraf")}
                </span>
                <br />
                <br />
                <span>
                  <b>{t("ContactLegals.Legitimation")}</b>
                  {t("ContactLegals.Legitimation-paragraf")}
                </span>
                <br />
                <br />
                <span>
                  <b>{t("ContactLegals.Recipients")}</b>
                  {t("ContactLegals.Recipients-paragraf")}{" "}
                </span>
                <br />
                <br />
                <span>
                  <b>{t("ContactLegals.Rights")}</b>{" "}
                  {t("ContactLegals.Rights-paragraf")}
                </span>
                <br />
                <br />
                <span>
                  <b>{t("ContactLegals.Additional")}</b>{" "}
                  {t("ContactLegals.Additional-paragraf")}
                  <div className="QR-img mt-3 mx-auto">
                        <a
                          href="https://qrco.de/bcQrMj"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img className="img-fluid" src={QR} alt="QR code" />
                        </a>
                      </div>
                </span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("ContactLegals.Additional-information")}
                </span>
                <br />
                <br />
                <span>
                  {t("ContactLegals.Additional-information-paragraf-1-part-1")}{" "}
                  <a href="https://popeyes.es" target="_blank" rel="noreferrer">www.popeyes.es</a>{" "}
                  {t(
                    "ContactLegals.Additional-information-paragraf-1-part-2"
                  )}{" "}
                </span>
                <br />
                <br />
                <span>
                  {t("ContactLegals.Additional-information-paragraf-2")}{" "}
                </span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("ContactLegals.Processing-your-data")}
                </span>
                <br />
                <span>
                  <b>{t("ContactLegals.Identity")}</b>{" "}
                  {t("ContactLegals.Identity-paragraf")}
                </span>
                <br />
                <span>
                  <b>{t("ContactLegals.CIF")}</b>{" "}
                  {t("ContactLegals.CIF-paragraf")}
                </span>
                <br />
                <span>
                  <b>{t("ContactLegals.Address")}</b>{" "}
                  {t("ContactLegals.Address-paragraf")}
                </span>
                <br />
                <span>
                  <b>{t("ContactLegals.Email")}</b>{" "}
                  <a href="mailto:talentopopeyes@popeyesiberia.com" target="_blank" rel="noreferrer">
                    talentopopeyes@popeyesiberia.com
                  </a>
                </span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("ContactLegals.Data-Protection-Officer")}
                </span>
                <br />
                <br />
                <span>
                  <b>{t("ContactLegals.DPO")}</b>{" "}
                  {t("ContactLegals.DPO-paragraf")}
                </span>
                <br />
                <span>
                  <b>{t("ContactLegals.Contact-person")}</b>{" "}
                  {t("ContactLegals.Contact-person-paragraf")}
                </span>
                <br />
                <span>
                  {t("ContactLegals.Contact-form-table")}{" "}
                  <a href="https://popeyes-canaletico.appcore.es/" target="_blank" rel="noreferrer">
                    https://popeyes-canaletico.appcore.es/
                  </a>{" "}
                </span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("ContactLegals.Process-personal-data")}
                </span>
                <br />
                <br />
                <span>
                  {t("ContactLegals.Process-personal-data-paragraf")}{" "}
                  <a href="https://popeyes.es" target="_blank" rel="noreferrer">www.popeyes.es</a>{" "}
                </span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("ContactLegals.Keep-your-data")}
                </span>
                <br />
                <br />
                <span>{t("ContactLegals.Keep-your-data-paragraf-1")}</span>
                <br />
                <br />
                <span>{t("ContactLegals.Keep-your-data-paragraf-2")}</span>
                <br />
                <br />
                <span>{t("ContactLegals.Keep-your-data-paragraf-3")}</span>
                <br />
                <br />
                <span>{t("ContactLegals.Keep-your-data-paragraf-4")}</span>
                <br />
                <br />
                <span>{t("ContactLegals.Keep-your-data-paragraf-5")}</span>
                <br />
                <br />
                <span>{t("ContactLegals.Keep-your-data-paragraf-6")}</span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("ContactLegals.Legitimacy")}
                </span>
                <br />
                <br />
                <span>
                  {t("ContactLegals.Legitimacy-paragraf")}{" "}
                  <a href="https://popeyes.es" target="_blank" rel="noreferrer">www.popeyes.es</a>{" "}
                </span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("ContactLegals.Information-shared")}
                </span>
                <br />
                <br />
                <span>{t("ContactLegals.Information-shared-paragraf")}</span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("ContactLegals.Your-rights")}
                </span>
                <br />
                <br />
                <span>
                  {t("ContactLegals.Your-rights-paragraf-1")}{" "}
                  <a href="https://popeyes-canaletico.appcore.es/" target="_blank" rel="noreferrer">
                    https://popeyes-canaletico.appcore.es/
                  </a>{" "}
                </span>
                <br />
                <br />
                <span>{t("ContactLegals.Your-rights-paragraf-2")}</span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("ContactLegals.Exercise-your-rights")}{" "}
                </span>
                <br />
                <br />
                <span>{t("ContactLegals.Exercise-your-rights-paragraf")}</span>
                <br />
                <span>
                  <b>{t("ContactLegals.DPO")}</b>
                  {t("ContactLegals.DPO-paragraf")}{" "}
                </span>
                <br />
                <span>
                  <b>{t("ContactLegals.Contact-person")}</b>
                  {t("ContactLegals.Contact-person-paragraf")}
                </span>
                <br />
                <span>
                  <b>{t("ContactLegals.Contact-form")}:</b>{" "}
                  <a href="https://popeyes-canaletico.appcore.es/" target="_blank" rel="noreferrer">
                    https://popeyes-canaletico.appcore.es/
                  </a>{" "}
                </span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("ContactLegals.Get-your-data")}
                </span>
                <br />
                <br />
                <span>{t("ContactLegals.Get-your-data-paragraf-1")}</span>
                <br />
                <br />
                <span>{t("ContactLegals.Get-your-data-paragraf-2")}</span>
                <br />
                <br />
                <span>{t("ContactLegals.Get-your-data-paragraf-3")}</span>
                <br />
                <br />
                <span>{t("ContactLegals.Get-your-data-paragraf-4")}</span>
                <br />
                <br />
                <span>{t("ContactLegals.Get-your-data-paragraf-5")}</span>
                <br />
                <br />
                <span>{t("ContactLegals.Get-your-data-paragraf-6")}</span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("ContactLegals.Get-consent")}{" "}
                </span>
                <br />
                <br />
                <span>{t("ContactLegals.Get-consent-paragraf")} </span>
                <br />
                <br />
                <span className="sans-bold text-primary">
                  {t("ContactLegals.Categories")}
                </span>
                <br />
                <br />
                <span>{t("ContactLegals.Categories-paragraf-1")} </span>
                <br />
                <span>{t("ContactLegals.Categories-paragraf-2")} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FormularioContacto);
