import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import {CustomEventName} from "./analytics.config";

export const analyticsEvents = {
    logScreenView,
    logNewsClickEvent,
    logContactFormEvent,
    logRegisterEvent,
    logProductClickEvent,
    setAnalyticsEnabled
}

function setAnalyticsEnabled(enabled) {
  firebase.analytics().setAnalyticsCollectionEnabled(enabled);
}

function logScreenView(screenName) {
    firebase.analytics().setCurrentScreen(screenName);
    firebase.analytics().logEvent(firebase.analytics.EventName.SCREEN_VIEW, {
      firebase_screen: screenName
    });
  }


  function logNewsClickEvent(newsId, newsName, source) {
    firebase.analytics().logEvent(CustomEventName.NEWS_CLICK, {
      "id": newsId,
      "name": newsName,
      "source": source,
    });
  }

  function logProductClickEvent(productId, productName) {
    firebase.analytics().logEvent(CustomEventName.ITEM_CLICK, {
      "id": productId,
      "name": productName,
      "source": "cms"
    });
  }

  function logContactFormEvent(ok) {
    firebase.analytics().logEvent(CustomEventName.SEND_CONTACT_FORM, {
      "form_type": "contacto",
      "send_form": ok ? "ok" : "ko",
    });
  }

  function logRegisterEvent(ok, news) {
    const value = ok ? "ok" : "ko";
    const comm = news ? "true" : "false";

    firebase.analytics().logEvent(CustomEventName.REGISTER, {
      "register_value": value,
      "allows_communication": comm,
      "event_label": [value, comm].join(":"),
    });
  }