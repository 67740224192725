import React, { Component } from "react";
import { Link } from "react-router-dom";
import { authenticationService } from "../../services/authentication.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import { ScreenName } from "../../analytics/analytics.config";
import { analyticsEvents } from "../../analytics/Analytics";
import { Helmet } from "react-helmet";
import "./Login.scss";
import { userService } from "../../services/user.service";
import config from "../../config/config";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      wrongData: false,
      loading: false,
      loginError: false,
      showPassword: false,
      message: "",
      privacyPolicyAccepted: "empty",
      loyaltyChangesAccepted: true,
      name: "",
      news: "",
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    analyticsEvents.logScreenView(ScreenName.LOGIN);
  };

  goToMain = () => {
    window.location.href = config.homeDeliveryUrl;
    // window.location.href = "/";
  };

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { email, password } = this.state;
    this.setState({ loading: true });
    authenticationService.login(email, password).then(
      (response) => {
        const user_id = response.data.id;
        const token = response.data.token;

        authenticationService.loginHD(token, user_id).then(() => {
          userService.getUser(token).then((response) => {
            if (
              response &&
              response.data &&
              response.data.privacyPolicyAccepted &&
              response.data.loyaltyChangesAccepted
            ) {
              this.goToMain();
            } else if (
              (response.data.privacyPolicyAccepted &&
                !response.data.loyaltyChangesAccepted) ||
              !response.data.privacyPolicyAccepted
            ) {
              this.props.handleShowPolicy(response);
            } else {
              this.props.handleShowPolicy(response);
            }
          });
          this.setState({ loading: false });
        });
      },
      (errorMessage) => {
        if (
          errorMessage === "Wrong email or password" ||
          errorMessage === "Correo electrónico o contraseña incorrecta"
        ) {
          this.setState({ loading: false });
          this.setState({ wrongData: true });
        } else {
          this.setState({
            loading: false,
          });
          const message = errorMessage;
          const error = true;
          const success = false;
          this.props.notificationHandler(success, error, message);
        }
      }
    );
  };

  render() {
    const { t } = this.props;
    const { loading, wrongData } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Login - Popeyes Louisiana Kitchen</title>
        </Helmet>
        <div className="container-fluid login">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-ls-12 d-flex align-items-center">
              <div className="card card-rounded border-0 w-100 login-card">
                <div className="login-body d-flex justify-content-center">
                  {/* Login form */}
                  <div className="col-md-6 col-lg-6 col-ls-12 d-flex flex-column justify-content-center">
                    <div className="text-center">
                      <h4 className="mt-3 sans-bold">
                        {t("Login.Accede-perfil")}
                      </h4>
                      <br />
                      {wrongData && (
                        <p className="text-danger text-sm-center">
                          {t("Login.Wrong-data")}
                        </p>
                      )}
                    </div>

                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group space">
                        <label>{t("Login.Email")}</label>
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          onChange={(event) =>
                            this.setState({ email: event.target.value })
                          }
                        />
                      </div>
                      <div className="form-group space">
                        <label>{t("Login.Contraseña")} *</label>
                        <div className="input-group">
                          <input
                            required
                            className="form-control"
                            type={
                              !this.state.showPassword ? "password" : "text"
                            }
                            name="password"
                            value={this.state.password}
                            onChange={(event) =>
                              this.setState({
                                password: event.target.value,
                              })
                            }
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-primary"
                              type="button"
                              onClick={() =>
                                this.setState({
                                  showPassword: !this.state.showPassword,
                                })
                              }
                            >
                              {!this.state.showPassword ? (
                                <FontAwesomeIcon icon={faEye} />
                              ) : (
                                <FontAwesomeIcon icon={faEyeSlash} />
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="space">
                        <Link
                          className="text-primary text-decoration-none"
                          to="/forgot-password"
                        >
                          {t("Login.Olvidado-contraseña")}
                        </Link>
                      </div>
                      <div className="mt-4 mb-5 d-flex justify-content-center">
                        <button
                          type="submit"
                          className="w-50 btn btn-primary rounded-pill"
                        >
                          {t("Login.Iniciar-sesión")}{" "}
                          {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* Login form end */}

                  {/* Register */}
                  <div className="register-card col-md-6 col-lg-6 col-ls-12 d-flex flex-column justify-content-center align-items-center text-center">
                    <div className="mt-auto p-2">
                      <span>
                        <h3 className="sans-bold">
                          {t("Login.REGISTRAR-part-1")}
                        </h3>

                        <h1 className="text-primary text-font-chicken mt-2">
                          {t("Login.REGISTRAR-part-2")}
                        </h1>
                      </span>
                    </div>
                    <div className="mb-5 mt-auto w-100 register-button-login">
                      <Link
                        className="w-50 btn btn-primary rounded-pill text-decoration-none"
                        to="/register"
                      >
                        {t("Login.Regístrate")}
                      </Link>
                    </div>
                  </div>
                  {/* End Register */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Login);
