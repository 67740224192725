import React, { Component } from "react";
import { authenticationService } from "../../services/authentication.service";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import SuccessRegister from "./successRegister/SuccessRegister";
import Privacy from "../Privacy/Privacy";
import GeneralCondition from "../GeneralCodition/GeneralCondition";
import { withTranslation } from "react-i18next";
import close from "../../assets/img/buttons/TOCLOSE.svg";
import { ScreenName } from "../../analytics/analytics.config";
import { analyticsEvents } from "../../analytics/Analytics";
import {Helmet} from "react-helmet";
import "./Register.scss";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      password: "",
      news: false,
      politicas: false,
      condiciones: false,
      message: "",
      success: false,
      error: false,
      loading: false,
      successRegister: false,
      showPassword: false,
      weakPassword: false,
      modalShowPoliticas: false,
      modalShowLegal: false,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    analyticsEvents.logScreenView(ScreenName.REGISTER);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { email, password, firstName, lastName, phone, news } = this.state;
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{5,})"
    );
    this.setState({ loading: true });
    if (!strongRegex.test(password)) {
      this.setState({ weakPassword: true, loading: false });
      return;
    }

    if (this.state.politicas && this.state.condiciones) {
      authenticationService
        .register(email, password, firstName, lastName, phone, news)
        .then(
          () => {
            let message = "";
            if (window.localStorage.getItem("i18nextLng") === "es") {
              message = "Registro exitoso";
            } else if (window.localStorage.getItem("i18nextLng") === "en") {
              message = "Registration successfully";
            } else if (window.localStorage.getItem("i18nextLng") === "cat") {
              message = "Registre amb èxit";
            }
            this.setState({
              loading: false,
              successRegister: true,
            });
            const error = false;
            const success = true;
            this.props.notificationHandler(success, error, message);
            analyticsEvents.logRegisterEvent(true, news);
          },
          (errorMessage) => {
            this.setState({
              loading: false,
            });
            const message = errorMessage;
            const error = true;
            const success = false;
            this.props.notificationHandler(success, error, message);
            analyticsEvents.logRegisterEvent(false, news);
          }
        );
    }
  };

  politicasPrivacidad = () => (
    <Modal
      className="text-center"
      centered
      size="lg"
      show={this.state.modalShowPoliticas}
      onHide={() => this.setState({ modalShowPoliticas: false })}
    >
      <Modal.Body>
        <div className="d-flex flex-column justify-content-center">
          <button
            className="text-left border-0 bg-transparent shadow-none"
            onClick={() => this.setState({ modalShowPoliticas: false })}
          >
            <img className="close-size" src={close} alt="Close" />
          </button>
          <div className="mt-2">
            <Privacy />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={() => this.setState({ modalShowPoliticas: false })}
        >
          {window.localStorage.getItem("i18nextLng") === "es" && "Cerrar"}
          {window.localStorage.getItem("i18nextLng") === "en" && "Close"}
          {window.localStorage.getItem("i18nextLng") === "cat" && "Tanca"}
        </button>
      </Modal.Footer>
    </Modal>
  );

  legalCondicion = () => (
    <Modal
      className="text-center"
      centered
      size="lg"
      show={this.state.modalShowLegal}
      onHide={() => this.setState({ modalShowLegal: false })}
    >
      <Modal.Body>
        <div className="d-flex flex-column justify-content-center">
          <button
            className="text-left border-0 bg-transparent shadow-none"
            onClick={() => this.setState({ modalShowLegal: false })}
          >
            <img className="close-size" src={close} alt="Close" />
          </button>
          <div className="mt-2">
            <GeneralCondition />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={() => this.setState({ modalShowLegal: false })}
        >
          {window.localStorage.getItem("i18nextLng") === "es" && "Cerrar"}
          {window.localStorage.getItem("i18nextLng") === "en" && "Close"}
          {window.localStorage.getItem("i18nextLng") === "cat" && "Tanca"}
        </button>
      </Modal.Footer>
    </Modal>
  );
  

  render() {
    const { t } = this.props;
    const { loading } = this.state;
    const {
      firstName,
      lastName,
      email,
      phone,
      password,
      politicas,
      condiciones,
    } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Register - Popeyes Louisiana Kitchen</title>
        </Helmet>
        {!this.state.successRegister ? (
          <React.Fragment>
            <div className="container-fluid">
              <div className="row align-items-center justify-content-center mb-3">
                <div className="title col-lg-12 col-ls-12 bg-primary d-flex justify-content-center align-items-center">
                  <div>
                    <h1 className="text-white title-register mt-4">
                      {t("Register.Crear-perfil")}
                    </h1>
                  </div>
                </div>
                <div className="col-md-12 col-ls-12 mt-3 d-flex justify-content-center align-items-center">
                  <div className="card border-0 register-info-card align-self-center">
                    <div className="card-body">
                      <span className="sans-bold">
                        {t("Register.Basic-information")}
                      </span>
                      <br />
                      <br />
                      <span>
                        <b>{t("Register.Responsible")}:</b>{" "}
                        {t("Register.Responsible-paragraf")}
                      </span>
                      <br />
                      <br />
                      <span>
                        <b>{t("Register.Purpose")}:</b>{" "}
                        {t("Register.Purpose-paragraf")}
                      </span>
                      <br />
                      <br />
                      <span>
                        <b>{t("Register.Legitimation")}:</b>{" "}
                        {t("Register.Legitimation-paragraf")}
                      </span>
                      <br />
                      <br />
                      <span>
                        <b>{t("Register.Recipients")}:</b>{" "}
                        {t("Register.Recipients-paragraf")}
                      </span>
                      <br />
                      <br />
                      <span>
                        <b>{t("Register.Rights")}:</b>{" "}
                        {t("Register.Rights-paragraf")}
                      </span>
                      <br />
                      <br />
                      <span>
                        <b>{t("Register.Additional")}:</b>{" "}
                        {t("Register.Additional-paragraf")}{" "}
                        <a
                          href="https://qrco.de/bcQrMj"
                          rel="noreferrer"
                          target="_blank"
                        >
                          enlace
                        </a>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="card border-0 d-flex justify-content-center">
                    <div className="card-body d-flex justify-content-center">
                      <div className="col-md-6">
                        <form
                          className="needs-validation"
                          onSubmit={this.handleSubmit}
                          noValidate
                        >
                          <div className="form-group">
                            <label className="text-secondary">
                              {t("Form.Nombre")} *
                            </label>
                            <input
                              required
                              className="form-control"
                              type="text"
                              name="firstName"
                              value={this.state.firstName}
                              onChange={(event) =>
                                this.setState({ firstName: event.target.value })
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label className="text-secondary">
                              {t("Form.Apellido")} *
                            </label>
                            <input
                              required
                              className="form-control"
                              type="text"
                              name="LastName"
                              value={this.state.lastName}
                              onChange={(event) =>
                                this.setState({ lastName: event.target.value })
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label className="text-secondary">
                              {t("Form.Teléfono")} *
                            </label>
                            <input
                              required
                              className="form-control"
                              type="number"
                              name="phone"
                              value={this.state.phone}
                              onChange={(event) =>
                                this.setState({ phone: event.target.value })
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label className="text-secondary">
                              {t("Form.Email")} *
                            </label>
                            <input
                              required
                              className="form-control"
                              type="email"
                              name="email"
                              value={this.state.email}
                              onChange={(event) =>
                                this.setState({ email: event.target.value })
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label className="text-secondary">
                              {t("Form.Contraseña")} *
                            </label>
                            <div className="input-group">
                              <input
                                required
                                className="form-control"
                                type={
                                  !this.state.showPassword ? "password" : "text"
                                }
                                name="password"
                                value={this.state.password}
                                onChange={(event) =>
                                  this.setState({
                                    password: event.target.value,
                                  })
                                }
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-outline-primary"
                                  type="button"
                                  onClick={() =>
                                    this.setState({
                                      showPassword: !this.state.showPassword,
                                    })
                                  }
                                >
                                  {!this.state.showPassword ? (
                                    <FontAwesomeIcon icon={faEye} />
                                  ) : (
                                    <FontAwesomeIcon icon={faEyeSlash} />
                                  )}
                                </button>
                              </div>
                            </div>
                            <span
                              className={
                                this.state.weakPassword
                                  ? "text-danger"
                                  : "text-primary"
                              }
                            >
                              {t("Form.Minimum")} *
                            </span>
                          </div>
                          <div className="form-group d-flex align-items-center border-top">
                            <div className="round mt-3 mr-2">
                              <input
                                type="checkbox"
                                id="checkbox"
                                name="news"
                                checked={this.state.news}
                                onChange={() =>
                                  this.setState({ news: !this.state.news })
                                }
                              />
                              <label htmlFor="checkbox"></label>
                            </div>
                            <span className="ml-3 mt-2 text-secondary">
                              {t("Form.Recibir-comunicaciones")}
                            </span>
                          </div>
                          <div className="form-group d-flex align-items-center border-top">
                            <div className="round mt-3 mr-2">
                              <input
                                required
                                type="checkbox"
                                id="checkbox-politicas"
                                name="politicas"
                                checked={this.state.politicas}
                                onChange={() =>
                                  this.setState({
                                    politicas: !this.state.politicas,
                                  })
                                }
                              />
                              <label htmlFor="checkbox-politicas"></label>
                            </div>
                            <span
                              onClick={() =>
                                this.setState({ modalShowPoliticas: true })
                              }
                              role="button"
                              className="ml-3 mt-2 text-secondary"
                            >
                              {t("Form.Políticas-de-privacidad")} *
                            </span>
                            {this.politicasPrivacidad()}
                          </div>
                          <div className="form-group d-flex align-items-center border-top">
                            <div className="round mt-3 mr-2">
                              <input
                                required
                                type="checkbox"
                                id="checkbox-condiciones"
                                name="condiciones"
                                checked={this.state.condiciones}
                                onChange={() =>
                                  this.setState({
                                    condiciones: !this.state.condiciones,
                                  })
                                }
                              />
                              <label htmlFor="checkbox-condiciones"></label>
                            </div>
                            <span
                              onClick={() =>
                                this.setState({ modalShowLegal: true })
                              }
                              role="button"
                              className="ml-3 mt-2 text-secondary"
                            >
                              {t("Form.Condiciones-generales")} *
                            </span>
                            {this.legalCondicion()}
                          </div>

                          <div className="form-group d-flex justify-content-center mt-5">
                            {firstName &&
                            lastName &&
                            email &&
                            phone &&
                            password &&
                            politicas &&
                            condiciones ? (
                              <button className="register-button btn btn-primary rounded-pill">
                                {t("Register.Finalizar-registro")}{" "}
                                {this.state.loading && (
                                  <FontAwesomeIcon icon={faSpinner} spin />
                                )}
                              </button>
                            ) : (
                              <button
                                className="register-button btn btn-secondary rounded-pill"
                                disabled
                              >
                                {t("Register.Finalizar-registro")}{" "}
                                {loading && (
                                  <FontAwesomeIcon icon={faSpinner} spin />
                                )}
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <SuccessRegister
            notificationHandler={this.props.notificationHandler}
            email={this.state.email}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(Register);
