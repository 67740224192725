import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { ScreenName } from "../../analytics/analytics.config";
import { analyticsEvents } from "../../analytics/Analytics";
import { Helmet } from "react-helmet";
import lineBottom from "../../assets/img/historia/line.svg";
import lineTop from "../../assets/img/historia/line.svg";
import firstImg from "../../assets/img/historia/1.png";
import secondImg from "../../assets/img/historia/2.jpeg";
import thirdImg from "../../assets/img/historia/3.png";
import marinado from "../../assets/img/historia/marinado.jpg";
import historia1 from "../../assets/img/historia/historia1-1024x608-1.jpg";
import burger from "../../assets/img/historia/teh-soul-2.jpg";
import chicken from "../../assets/img/historia/Popeyes_Poppy-Love-that-Chicken_102419.svg";
import star from "../../assets/img/historia/Star.svg";
import trumpet from "../../assets/img/historia/Trumpet@2x.svg";
import bowl from "../../assets/img/historia/Bowl@2x.svg";
import firstVid from "../../assets/videos/historia/VIDEO1-POLLO-CRUJIENTE.mov";
import secondVid from "../../assets/videos/historia/VIDEO 2-MARINADO.mov";
import thirdVid from "../../assets/videos/historia/VIDEO 3-REBOZADO.mov";
import "./History.scss";

class History extends Component {
  state = {
    windowWidth: undefined,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    analyticsEvents.logScreenView(ScreenName.HISTORY);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () =>
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <title>Sobre Nosotros - Popeyes Louisiana Kitchen</title>
        </Helmet>
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="title bg-primary d-flex justify-content-center align-items-center w-100">
              <div>
                <h1 className="text-white primary-title mt-4">
                  {t("Historia.Historia")}
                </h1>
              </div>
            </div>

            <div className="img-with-zigzag w-100 h-30">
              {this.state.windowWidth <= 1024 ? (
                <img
                  className="w-100 img-with-zigzag"
                  src={firstImg}
                  alt="img1"
                />
              ) : (
                <video
                  className="w-100"
                  src={firstVid}
                  alt="burgerVid"
                  autoPlay={true}
                  muted={true}
                  loop={true}
                  playsInline={true}
                />
              )}

              <div className="video-overlay">
                <div className="w-100 column">
                  <div className="row justify-content-center w-100">
                    <h2 className="chicken-font-title text-white text-font-chicken mr-3">
                      {t("Historia.Crispy")}
                    </h2>
                    <h1 className="primary-title text-white">
                      {t("Historia.Outside")}
                    </h1>
                  </div>

                  <div className="row justify-content-center w-100">
                    <h2 className="chicken-font-title text-white text-font-chicken mx-3">
                      {t("Historia.Juicy")}
                    </h2>
                    <h1 className="primary-title text-white">
                      {t("Historia.Inside")}
                    </h1>
                  </div>
                </div>

                <div className="w-100 column justify-content-center">
                  <div className="row justify-content-center w-100">
                    <h3 className="text-secondary-on-media text-white">
                      {t("Historia.Calidad")}
                    </h3>
                    <img
                      className="mb-4 icon-on-media"
                      src={trumpet}
                      alt="trumpet"
                    />
                  </div>
                </div>
              </div>

              <img className="zigzag-bottom" src={lineBottom} alt="zigzag1" />
            </div>

            <div className="column align-items-center justify-content-center w-100 m-5 content-container">
              <div className="row align-items-center justify-content-center w-100">
                <h1 className="primary-title text-primary sans-bold text-center">
                  {t("Historia.Authentic")}
                </h1>
                <h2 className="chicken-font-title text-success text-font-chicken mx-3">
                  {t("Historia.Louisiana")}
                </h2>
              </div>

              <div className="row align-items-center justify-content-between mb-4 mt-5 mx-2">
                <img
                  className="circle-img rounded-circle"
                  src={historia1}
                  alt="historail"
                />
                <img
                  className="circle-img rounded-circle"
                  src={marinado}
                  alt="marinado"
                />
                <img
                  className="circle-img rounded-circle"
                  src={burger}
                  alt="burger"
                />
              </div>

              <div className="">
                <p>
                  <span className="years">{t("Historia.In-1972")}</span>
                  {t("Historia.Description-1")}
                </p>
                <p>
                  <span className="years">{t("Historia.In-1980")}</span>
                  {t("Historia.Description-2")}
                </p>
                <p>
                  <span className="years">{t("Historia.In-2019")}</span>
                  {t("Historia.Description-3")}
                </p>
              </div>
            </div>

            <div className="img-with-zigzag w-100 h-30">
              {this.state.windowWidth <= 1024 ? (
                <img
                  className="w-100 img-with-zigzag"
                  src={secondImg}
                  alt="img2"
                />
              ) : (
                <video
                  className="w-100"
                  src={secondVid}
                  alt="burgerVid"
                  autoPlay={true}
                  muted={true}
                  loop={true}
                  playsInline={true}
                />
              )}

              <div className="video-overlay">
                {window.localStorage.getItem("i18nextLng") === "en" ? (
                  <div className="w-100 column">

                    <div className="w-100 row justify-content-center">
                      <h2 className="chicken-font-title text-font-chicken text-white mx-3">
                        {t("Historia.100-percent")}
                      </h2>
                      <h1 className="primary-title text-white mr-2">
                        {t("Historia.Spanish")}
                      </h1>
                      <h1 className="primary-title text-white">
                        {t("Historia.Chicken")}
                      </h1>
                    </div>
                  </div>
                ) : (
                  <div className="w-100 column">
                    <div className="w-100 row justify-content-left">
                      <h1 className="primary-title text-white">
                        {t("Historia.Chicken")}
                      </h1>
                      <h2 className="chicken-font-title text-font-chicken text-white mx-3">
                        {t("Historia.100-percent")}
                      </h2>
                      <h1 className="primary-title text-white">
                        {t("Historia.Spanish")}
                      </h1>
                    </div>

                  </div>
                )}

                <div className="w-100 column justify-content-center">
                  <div className="row justify-content-center w-100">
                    <h3 className="text-secondary-on-media text-white">
                      {t("Historia.At-Popeyes")}
                    </h3>
                    <img className="mb-4 icon-on-media" src={bowl} alt="bowl" />
                  </div>
                </div>
              </div>

              <img className="zigzag-top" src={lineTop} alt="zigzag2" />
              <img className="zigzag-bottom" src={lineBottom} alt="zigzag3" />
            </div>

            <div className="row justify-content-around w-100 m-5 content-container">
              <div className="column stary-list align-content-lg-start">
                <div className="row stary-list-title">
                  <h1 className="primary-title-3 text-primary sans-bold">
                    {t("Historia.POPEYES-100")}
                  </h1>
                  <h1 className="primary-title-3 text-success text-font-chicken m-2">
                    <b>{t("Historia.Quality")}</b>
                  </h1>
                </div>
                <div className="column align-items-normal">
                  <div className="row mb-3">
                    <img className="mr-2 star" src={star} alt="star1" />
                    <div className="text-wrap  w-75">
                      <b>{t("Historia.Fresh-Chicken")}</b>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <img className="mr-2 star" src={star} alt="star1" />
                    <div className="text-wrap  w-75">
                      <b>{t("Historia.Free-Of-Preservatives")}</b>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <img className="mr-2 star" src={star} alt="star1" />
                    <div className="text-wrap  w-75">
                      <b>
                        {t("Historia.Marinated")} {t("Historia.12-hours")}{" "}
                        {t("Historia.Spices")}
                      </b>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <img className="mr-2 star" src={star} alt="star1" />
                    <div className="text-wrap  w-75">
                      <b>{t("Historia.Cooked")}</b>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <img className="mr-2 star" src={star} alt="star1" />
                    <div className="text-wrap  w-75">
                      <b>{t("Historia.Hand-Battered")}</b>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <img className="mr-2 star" src={star} alt="star1" />
                    <div className="text-wrap w-75">
                      <b>{t("Historia.Cooked-slow")}</b>
                    </div>
                  </div>
                </div>
              </div>

              <div className="chicken-wrapper">
                <img className="w-100  h-100" src={chicken} alt="chicken" />
              </div>
            </div>

            <div className="img-with-zigzag w-100 h-30 last-video-margin">
              {this.state.windowWidth <= 1024 ? (
                <img
                  className="w-100 img-with-zigzag"
                  src={thirdImg}
                  alt="img3"
                />
              ) : (
                <video
                  className="w-100"
                  src={thirdVid}
                  alt="burgerVid"
                  autoPlay={true}
                  muted={true}
                  loop={true}
                  playsInline={true}
                />
              )}

              <div className="video-overlay">
                <div className="colum justify-content-center">
                  <div className="row justify-content-center text-on-media-middle mt-5">
                    <h2 className="chicken-font-title text-white text-font-chicken mr-3 mt-2">
                      {t("Historia.Hand-battered-title")}
                    </h2>
                  </div>
                  <div className="row justify-content-center text-on-media-middle ">
                    <h1 className="primary-title text-white">
                      {t("Historia.In-Restaurants")}
                    </h1>
                  </div>
                </div>
              </div>
              <img className="zigzag-top" src={lineTop} alt="zigzag4" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(History);
