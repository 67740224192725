import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import config from "../../../config/config";
import LocationMarker from "./LocationMarker";
import light from "./MapStyle";
import "./GoogleMap.scss";

class GoogleMapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationByTitleClick: [],
      position: {
        lat: null,
        lng: null,
      },
      center: {
        lat: 40.3265,
        lng: -3.8756,
      },
      lala: false,
      zoom: 9,
      currentRestaurant: this.props.currentRestaurant,
      filteredResults: null,
    };
  }

  componentDidUpdate = () => {
    if (
      this.state.currentRestaurant.length !== 0 &&
      this.props.currentRestaurant.length === 0
    ) {
      this.setState({ position: { lat: null, lng: null }, zoom: 17 });
    }
    if (
      this.props.lat &&
      this.props.lat !== this.state.center.lat &&
      this.props.lng !== this.state.center.lng
    ) {
      this.setState({ center: { lat: this.props.lat, lng: this.props.lng }});
    }
    if (
      this.props.locationByTitleClick.length !== 0 &&
      this.props.locationByTitleClick !== this.state.locationByTitleClick &&
      this.props.locationByTitleClick.latitude === this.props.lat &&
      this.props.locationByTitleClick.longitude === this.props.lng
    ) {
      this.setState({
        locationByTitleClick: this.props.locationByTitleClick,
        center: {
          lat: this.props.locationByTitleClick.latitude,
          lng: this.props.locationByTitleClick.longitude,
        },
        position: {
          lat: this.props.locationByTitleClick.latitude,
          lng: this.props.locationByTitleClick.longitude,
        },
        zoom: 17
      });
    }
    if (
      this.props.locationByTitleClick.length !== 0 &&
      this.props.locationByTitleClick !== this.state.locationByTitleClick &&
      this.props.locationByTitleClick.latitude !== this.state.center.lat &&
      this.props.locationByTitleClick.longitude !== this.state.center.lng
    ) {
      this.setState({
        locationByTitleClick: this.props.locationByTitleClick,
        center: {
          lat: this.props.locationByTitleClick.latitude,
          lng: this.props.locationByTitleClick.longitude,
        },
        position: {
          lat: this.props.locationByTitleClick.latitude,
          lng: this.props.locationByTitleClick.longitude,
        },
        zoom: 17
      });
    }
  };

  showCurrentRestaurantWithIndex = (id, lat, lng, idx) => {
    this.props.showCurrentRestaurant(id, idx);
    this.setState({ position: { lat: lat, lng: lng }, center: { lat: lat, lng: lng }, zoom: 17 });
  };

  render() {
    return (
      <div className="map justify-content-center d-flex">
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.mapKey }}
          center={this.state.center}
          zoom={this.state.zoom}
          options={{ styles: light }}
        >
          {this.props.currentRestaurant === this.props.filteredResults &&
            this.props.restaurants.map((item, idx) => (
              <LocationMarker
                key={idx}
                lat={item.latitude}
                lng={item.longitude}
                position={this.state.position}
                onClick={() =>
                  this.showCurrentRestaurantWithIndex(
                    item.id,
                    item.latitude,
                    item.longitude,
                    idx
                  )
                }
              />
            ))}
          {this.props.currentRestaurant.length !== 0 &&
            this.props.filteredResults.map((item, idx) => (
              <LocationMarker
                key={idx}
                lat={item.latitude}
                lng={item.longitude}
                position={this.state.position}
                onClick={() =>
                  this.showCurrentRestaurantWithIndex(
                    item.id,
                    item.latitude,
                    item.longitude,
                    idx
                  )
                }
              />
            ))}
          {this.props.filteredResults &&
            this.props.filteredResults.length !== 0 &&
            this.props.filteredResults.map((item, idx) => (
              <LocationMarker
                key={idx}
                lat={item.latitude}
                lng={item.longitude}
                position={this.state.position}
                onClick={() =>
                  this.showCurrentRestaurantWithIndex(
                    item.id,
                    item.latitude,
                    item.longitude,
                    idx
                  )
                }
              />
            ))}
          {this.props.filteredResults.length === 0 &&
            this.props.currentRestaurant.length === 0 &&
            this.props.restaurants.map((item, idx) => (
              <LocationMarker
                key={idx}
                lat={item.latitude}
                lng={item.longitude}
                position={this.state.position}
                onClick={() =>
                  this.showCurrentRestaurantWithIndex(
                    item.id,
                    item.latitude,
                    item.longitude,
                    idx
                  )
                }
              />
            ))}
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMapComponent;
