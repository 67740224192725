let config = null;

if (process.env.REACT_APP_ENV === "prod") {
    config = {
        apiUrl: "https://api.airtouchpop.com",
        apiUrlHD: "https://pedir.popeyes.es",
        key: "3RtSwmF8KAelm98PaNJJYrRpP7iGONJJuOIlXef9w29Psb3Ue6Lzquu9TrKY39i6",
        mapKey: "AIzaSyDRnUSOJBfmanne2HrACIoecnNWn8k-upU",
        language: window.localStorage.getItem("i18nextLng"),
        storageName: "Popeyes CMS",
        homeDeliveryUrl: "https://pedir.popeyes.es",
        gtmID: "GTM-T7JVSLK",
        cookieDomain: "popeyes.es",
        functionalityCookie: "POP_Cookie_Functionality",
        analyticsCookie: "POP_Cookie_Browser_Analytics",
        thirdPartyCookie: "POP_Cookie_Third_Party",
        firebaseConfig: {
            apiKey: "AIzaSyAwMQDZPnIEdTl_hiNUN-x6LJu2dfm7YP4",
            authDomain: "popeyes-57aed.firebaseapp.com",
            databaseURL: "https://popeyes-57aed.firebaseio.com",
            projectId: "popeyes-57aed",
            storageBucket: "popeyes-57aed.appspot.com",
            messagingSenderId: "1037624320450",
            appId: "1:1037624320450:web:f6f9a238c5f5e8e30ff65b",
            measurementId: "G-DZ6LVRL7QK"
          }
    };
} else {
    console.log("{config.js}[](29) ", process.env.REACT_APP_ENV)
    config = {
        apiUrl: "https://popeyes-dev-api.airtouchmedia.net",
        apiUrlHD: "https://popeyes.airtouchmedia.com",
        key: "3RtSwmF8KAelm98PaNJJYrRpP7iGONJJuOIlXef9w29Psb3Ue6Lzquu9TrKY39i6",
        mapKey: "AIzaSyDY3bp-vLKzA3GYD0DO6jRbSbf6nXh40EU",
        language: window.localStorage.getItem("i18nextLng"),
        storageName: "Popeyes CMS",
        homeDeliveryUrl: "https://popeyes.airtouchmedia.com",
        gtmID: "GTM-T7JVSLK",
        cookieDomain: "airtouchmedia.com",
        functionalityCookie: "POP_Cookie_Functionality",
        analyticsCookie: "POP_Cookie_Browser_Analytics",
        thirdPartyCookie: "POP_Cookie_Third_Party",
        firebaseConfig: {
            apiKey: "AIzaSyB7BP5Z8AtnCcNARvh7xzT8G7JMgrUC-YU",
            authDomain: "popeyes-es-dev.firebaseapp.com",
            databaseURL: "https://popeyes-es-dev.firebaseio.com",
            projectId: "popeyes-es-dev",
            storageBucket: "popeyes-es-dev.appspot.com",
            messagingSenderId: "782263225849",
            appId: "1:782263225849:web:fe5322c75fa74a8c52c128",
            measurementId: "G-PFF9VN2LZ2",
          }
    };
}

export default config;
