export const cookiesHelpers = {
  setCookie,
  getCookie,
  checkCookie,
};

function setCookie(name, value, domain) {
  document.cookie =
    name +
    "=" +
    value +
    "; expires=Thu, 30 Dec 2038 12:00:00 UTC; path=/; domain=" +
    domain;
}

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function checkCookie(name) {
  return document.cookie
    .split(";")
    .some((item) => item.trim().startsWith(name));
}
