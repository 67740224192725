import React, { Component } from "react";
import mobile               from "../../assets/img/mobile/Mobile_APP.png";
import android              from "../../assets/img/mobile/android.png";
import apple from "../../assets/img/mobile/apple.png";
import { withTranslation } from "react-i18next";
import "./MobileBanner.scss";

class MobileBanner extends Component {
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div className="card border-0 text-center download_app">
          <div className="d-flex justify-content-center align-items-center">
            <div className="col-md-12 col-lg-12 col-ls-12 d-flex mobile mt-3">
              <div className="col-md-12 col-lg-7 col-ls-12 d-flex align-items-center justify-content-center">
                <div className="card border-0 card-mobile mb-auto">
                  <div className="card-title">
                    <h1 className="sans-bold text-font-chicken title-app text-info">
                      {t("APP.Descárgate")}
                    </h1>
                  </div>
                  <div className="d-flex justify-content-between paragraf-width">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="rounded-number d-flex justify-content-center align-items-center mb-3">
                        <span className="text-font-chicken text-margin text-white">
                          1
                        </span>
                      </div>
                      <span className="burnt-orange text-font-chicken sans-bold paragraf-text-size">
                        {t("APP.Enterate")}
                      </span>
                      <span className="text-font-chicken text-primary sans-bold paragraf-text-size">
                        {t("APP.Novedades")}
                      </span>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="rounded-number d-flex justify-content-center align-items-center mb-3">
                        <span className="text-font-chicken text-margin text-white">
                          2
                        </span>
                      </div>
                      <span className="burnt-orange text-font-chicken sans-bold paragraf-text-size">
                        {t("APP.Pide")}
                      </span>
                      <span className="text-font-chicken text-primary sans-bold paragraf-text-size">
                        {t("APP.Domicilio")}
                      </span>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="rounded-number d-flex justify-content-center align-items-center mb-3">
                        <span className="text-font-chicken text-margin text-white">
                          3
                        </span>
                      </div>
                      <span className="burnt-orange text-font-chicken sans-bold paragraf-text-size">
                        {t("APP.Descubre")}
                      </span>
                      <span className="text-font-chicken text-primary sans-bold paragraf-text-size">
                        {t("APP.Exclusivas")}
                      </span>
                    </div>
                  </div>
                  <div className="mobile-buttons d-flex">
                    <a
                      className="mobile_ios_button"
                      href="https://apps.apple.com/us/app/popeyes-espa%C3%B1a/id1489613084?l=es&ls=1"
                    >
                      <img src={apple} alt="Popeyes IOS App" />
                    </a>
                    <a
                      className="mobile_android_button"
                      href="https://play.google.com/store/apps/details?id=es.popeyes.android"
                    >
                      <img src={android} alt="Popeyes Android App" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-5 col-ls-10 align-items-bottom">
                <img className="img-fluid mobile-image" src={mobile} alt="Mobile app" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(MobileBanner);
