import config from "../config/config";
import { authenticationService } from "./authentication.service";

export const orderService = {
    getOngoingOrder
};

function getOngoingOrder() {
  const requestOptions = {
    method: "GET",
    headers: authenticationService.prepareHeader(),
    credentials: "include"
  };

  return fetch(
    `${config.apiUrlHD}/api/v1/users/:${authenticationService.currentUserValue.id}/orders/latest_ongoing_summary`,
    requestOptions
  ).then(authenticationService.handleResponse);
}