import React, { Component } from "react";
import { restaurantsService } from "../../../services/restaurants.services";
import { withTranslation } from "react-i18next";
import "./RestauranteContact.scss";

class RestauranteContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurants: [],
      filteredRestaurants: [],
      provinces: [],
      province: "",
      restaurant: "",
    };
  }
  componentDidMount = () => {
    restaurantsService.getProvinces().then((response) => {
      restaurantsService.getRestaurants().then((res) => {
        this.setState({ restaurants: res.data });

        const sortedResponse = [...response.data];
        let provincesWithRestaurants = [];
        sortedResponse.sort(function (first, second) {
          if (first.name < second.name) {
            return -1;
          }
          if (first.name > second.name) {
            return 1;
          }
          return 0;
        });

        res.data.map((item) => {
          sortedResponse.filter((val) => {
            if (val.geocode !== item.province.geocode) {
              return false;
            }
            return provincesWithRestaurants.push(val);
          });
          return true;
        });

        // Uniq item sort
        const uniq = Array.from(
          new Set(provincesWithRestaurants.map((item) => item.id))
        ).map((id) => {
          return provincesWithRestaurants.find((item) => item.id === id);
        });

        return this.setState({ provinces: uniq });
      });
    });
  };

  componentDidUpdate = () => {
    const { province } = this.state;
    if (province) {
      const filteredRestaurants =
        this.state.restaurants.length !== 0 &&
        this.state.restaurants.filter((restaurant) => {
          if (restaurant.province.geocode !== province) {
            return false;
          }
          return true;
        });
      if (
        this.state.filteredRestaurants.length !== filteredRestaurants.length
      ) {
        this.setState({ filteredRestaurants: filteredRestaurants });
      }
    }
  };

  showRestaurants = () => {
    if (this.state.filteredRestaurants.length !== 0) {
      return this.state.filteredRestaurants.map((val, idx) => (
        <option key={idx} value={val.name}>
          {val.name}
        </option>
      ));
    } else {
      return this.state.restaurants.map((val, idx) => (
        <option key={idx} value={val.name}>
          {val.name}
        </option>
      ));
    }
  };

  render() {
    const { t } = this.props;
    const { province } = this.state;
    return (
      <div className="container-fluid restaurant-contact">
        <div className="row align-items-center justify-content-center">
          <div className="title col-lg-12 col-ls-12 bg-primary d-flex justify-content-center align-items-center">
            <div>
              <h1 className="text-white title-h1 mt-4">
                {t("RestauranteContact.Trabaja")}
              </h1>
            </div>
          </div>
          <div className="col-ls-12 col-lg-12">
            <div className="card border-0">
              <div className="card-title text-center mt-5">
                <h4 className="mb-4 text-info sans-bold">
                  {t("RestauranteContact.Empleo")}
                </h4>
                <span className="mb-4 necesitamos">
                  {t("RestauranteContact.Selecciona")}
                </span>
              </div>
            </div>
          </div>
          <div className="card-body d-flex justify-content-center">
            <div className="col-md-6">
              <div className="form-group">
                <label className="text-secondary">
                  {t("RestauranteContact.Provincia")} *
                </label>
                <select
                  required
                  onChange={(event) =>
                    this.setState({ province: event.target.value })
                  }
                  className="mt-1 custom-select"
                  defaultValue="DEFAULT"
                >
                  <option disabled value="DEFAULT">
                    ------------
                  </option>
                  {this.state.provinces &&
                    this.state.provinces.map((val, idx) => (
                      <option key={idx} value={val.geocode}>
                        {val.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group">
                <label className="text-secondary">
                  {t("RestauranteContact.Restaurante")}
                </label>
                <select
                  onChange={(event) =>
                    this.setState({ restaurant: event.target.value })
                  }
                  className="mt-1 custom-select"
                  defaultValue="DEFAULT"
                >
                  <option disabled value="DEFAULT">
                    ------------
                  </option>

                  {/*  */}
                  {this.state.filteredRestaurants.length !== 0 &&
                    this.state.filteredRestaurants.map((val, idx) => (
                      <option key={idx} value={val.name}>
                        {val.name}
                      </option>
                    ))}
                  {this.state.filteredRestaurants.length === 0 &&
                    this.state.restaurants.map((val, idx) => (
                      <option key={idx} value={val.name}>
                        {val.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="d-flex justify-content-center">
                {province ? (
                  <a
                    href="https://empleo.popeyes.es/jobs/popeyes-trabaja-con-nosotros-en-restaurante"
                    type="button"
                    rel="noreferrer"
                    target="_blank"
                    className="w-50 btn btn-primary rounded-pill"
                  >
                    {t("RestauranteContact.Siguiente")}
                  </a>
                ) : (
                  <a
                    href="https://empleo.popeyes.es/jobs/popeyes-trabaja-con-nosotros-en-restaurante"
                    type="button"
                    rel="noreferrer"
                    target="_blank"
                    className="w-50 btn btn-secondary rounded-pill"
                    disabled
                  >
                    {t("RestauranteContact.Siguiente")}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(RestauranteContact);
