import config from "../config/config";
import { authenticationService } from "./authentication.service";

export const recompensasService = {
  getRecompensas,
  getVouchers
};

function getRecompensas() {
  const requestOptions = {
    method: "GET",
    headers: authenticationService.prepareHeader(),
    credentials: "include"
  };

  return fetch(
    `${config.apiUrlHD}/api/v1/loyalty/rewards`,
    requestOptions
  ).then(authenticationService.handleResponse);
}

function getVouchers() {
  const requestOptions = {
    method: "GET",
    headers: authenticationService.prepareHeader(),
    credentials: "include"
  };

  return fetch(
    `${config.apiUrlHD}/api/v1/users/:${authenticationService.currentUserValue.id}/vouchers`,
    requestOptions
  ).then(authenticationService.handleResponse);
}