import React, { Component } from "react";
import { Link } from "react-router-dom";
import { authenticationService } from "../../../services/authentication.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import "./ChangePassword.scss";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmNewPassword: "",
      message: "",
      success: false,
      error: false,
      loading: false,
      showPassword: false,
      weakPassword: false,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { newPassword, confirmNewPassword } = this.state;
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{5,})"
    );
    this.setState({ loading: true });

    if (newPassword !== confirmNewPassword) {
      let message = "";
      if (window.localStorage.getItem("i18nextLng") === "es") {
        message = "Las contraseñas no coinciden";
      } else if (window.localStorage.getItem("i18nextLng") === "en") {
        message = "Passwords don't match";
      } else if (window.localStorage.getItem("i18nextLng") === "cat") {
        message = "Les contrasenyes no coincideixen";
      }
      this.setState({
        loading: false,
      });
      const error = true;
      const success = false;
      this.props.notificationHandler(success, error, message);
    } else if (!strongRegex.test(newPassword)) {
      this.setState({ weakPassword: true, loading: false });
      return;
    } else {
      authenticationService
        .changePassword(newPassword)
        .then(() => {
          let message = "";
          if (window.localStorage.getItem("i18nextLng") === "es") {
            message = "¡Contraseña cambiada con éxito!";
          } else if (window.localStorage.getItem("i18nextLng") === "en") {
            message = "Password changed successfully!";
          } else if (window.localStorage.getItem("i18nextLng") === "cat") {
            message = "La contrasenya s'ha canviat correctament";
          }
          this.setState({
            newPassword: "",
            confirmNewPassword: "",
            loading: false,
          });
          const error = false;
          const success = true;
          this.props.notificationHandler(success, error, message);
        },
        (errorMessage) => {
          this.setState({
            loading: false,
          });
          const message = errorMessage
          const error = true;
          const success = false;
          this.props.notificationHandler(success, error, message);
        }
        );
    }
  };

  render() {
    const { t } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="title bg-primary d-flex justify-content-center align-items-center w-100">
              <div>
                <h1 className="text-white title-h1 mt-4">
                  {t("Profile.Datos-personales")}
                </h1>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card border-0">
                <div className="card-body change-password d-flex justify-content-center">
                  <div className="col-md-6">
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <label className="text-secondary">
                          {t("Form.Contraseña")}
                        </label>
                        <div className="input-group">
                          <input
                            required
                            className="form-control"
                            type={
                              !this.state.showPassword ? "password" : "text"
                            }
                            name="newPassword"
                            value={this.state.newPassword}
                            onChange={(event) =>
                              this.setState({ newPassword: event.target.value })
                            }
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-primary"
                              type="button"
                              onClick={() =>
                                this.setState({
                                  showPassword: !this.state.showPassword,
                                })
                              }
                            >
                              {!this.state.showPassword ? (
                                <FontAwesomeIcon icon={faEye} />
                              ) : (
                                <FontAwesomeIcon icon={faEyeSlash} />
                              )}
                            </button>
                          </div>
                        </div>
                        <span
                          className={
                            this.state.weakPassword
                              ? "text-danger"
                              : "text-primary"
                          }
                        >
                          {t("Form.Minimum")} *
                        </span>
                      </div>
                      <div className="form-group">
                        <label className="text-secondary">
                          {t("Form.Repetir-contraseña")}
                        </label>
                        <div className="input-group">
                          <input
                            required
                            className="form-control"
                            type={
                              !this.state.showPassword ? "password" : "text"
                            }
                            name="confirmNewPassword"
                            value={this.state.confirmNewPassword}
                            onChange={(event) =>
                              this.setState({
                                confirmNewPassword: event.target.value,
                              })
                            }
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-primary"
                              type="button"
                              onClick={() =>
                                this.setState({
                                  showPassword: !this.state.showPassword,
                                })
                              }
                            >
                              {!this.state.showPassword ? (
                                <FontAwesomeIcon icon={faEye} />
                              ) : (
                                <FontAwesomeIcon icon={faEyeSlash} />
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="form-group d-flex justify-content-center mt-5">
                        <Link
                          to="/personal-data"
                          className="w-50 btn btn-primary rounded-pill text-center mr-3"
                        >
                          {t("Profile.Volver")}
                        </Link>

                        <button
                          type="submit"
                          className="w-50 btn btn-primary rounded-pill ml-3"
                        >
                          {t("Profile.Guardar")}{" "}
                          {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ChangePassword);
