import React from "react";
import orangeIcon from "../../../assets/img/markers/orange_popeyes.png";
import purpleIcon from "../../../assets/img/markers/purple_popeyes.png";
import "./GoogleMap.scss";

const LocationMarker = ({ lat, lng, onClick, position }) => {

  return (
    <div className="location-marker" onClick={onClick}>
      {lat === position.lat && lng === position.lng && (
        <img
          src={purpleIcon}
          alt="Markers popeyes"
          className="location-icons-selected"
        />
      )}
      {lat !== position.lat && lng !== position.lng && (
        <img src={orangeIcon} alt="Markers popeyes" className="location-icons" />
      )}
    </div>
  );
};

export default LocationMarker;
