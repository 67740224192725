import React from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { analyticsEvents } from "../../analytics/Analytics";
import { ScreenName } from "../../analytics/analytics.config";
import Banner from "../../assets/img/social/BANNER.jpg";
import Apollo from "../../assets/img/social/APOLLO.png";
import Voluntary from "../../assets/img/social/VOLUNTARY.jpg";
import Family from "../../assets/img/social/FAMILIA.JPG";
import "./SocialCommitment.scss";

class SocialCommitment extends React.Component {
  state = { width: 0 };
  componentDidMount() {
    window.scroll(0, 0);
    analyticsEvents.logScreenView(ScreenName.SOCIAL_COMMITMENT);
    this.setState({ width: window.innerWidth });
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <title>Compromiso social - Popeyes Louisiana Kitchen</title>
        </Helmet>
        <div className="container-fluid">
          <div className="row">
            <div className="title bg-primary d-flex justify-content-center align-items-center w-100">
              <div>
                <h1 className="text-white title-h1 mt-4">
                  {t("SocialCommitment.Title")}
                </h1>
              </div>
            </div>

            <div className="w-100">
              <img className="img-fluid w-100" src={Banner} alt="Banner" />
            </div>

            <div className="card border-0 w-100 text-center">
              <div className="card-body header-and-footer">
                <h1 className="text-info text-font-chicken commitment-title">
                  {t("SocialCommitment.MasApollo")}
                </h1>
                <div className="mt-4 mb-4">
                  <span>{t("SocialCommitment.Description.Part1")}</span>{" "}
                  <span className="sans-bold text-primary">
                    {t("SocialCommitment.Description.Part2")}
                  </span>{" "}
                  <span>{t("SocialCommitment.Description.Part3")}</span>{" "}
                  <span className="sans-bold text-primary">
                    {t("SocialCommitment.Description.Part4")}
                  </span>{" "}
                  <span>{t("SocialCommitment.Description.Part5")}</span>
                </div>
              </div>
            </div>

            <div className="card border-0 mt-2">
              <div className="card-body group-image-left d-flex align-items-center">
                <div className="col-md-6 d-flex flex-column justify-content-center align-items-center mb-3">
                  <div className="col-md-10">
                    <h3 className="sans-bold text-info text-left">
                      {t("SocialCommitment.Group1.Title")}
                    </h3>
                    <div className="d-flex text-left mt-4 justify-content-center flex-column">
                      <div>
                        <span>{t("SocialCommitment.Group1.Part1")}</span>
                        <br />
                        <br />
                        <span>{t("SocialCommitment.Group1.Part2")}</span>
                        <span className="sans-bold">
                          {t("SocialCommitment.Group1.Part3")}
                        </span>
                        <span>{t("SocialCommitment.Group1.Part4")}</span>{" "}
                        <span className="sans-bold">
                          {t("SocialCommitment.Group1.Part5")}
                        </span>{" "}
                        <span>{t("SocialCommitment.Group1.Part6")}</span>{" "}
                        <span className="sans-bold">
                          {t("SocialCommitment.Group1.Part7")}
                        </span>{" "}
                        <span>{t("SocialCommitment.Group1.Part8")}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex justify-content-center">
                  <div className="col-md-10">
                    <img className="img-fluid" src={Apollo} alt="Apollo" />
                  </div>
                </div>
              </div>
            </div>

            <div className="card border-0">
              <div className="card-body group-image-right d-flex align-items-center">
                <div className="col-md-6 d-flex flex-column justify-content-center align-items-center mb-3">
                  <div className="col-md-10">
                    <h3 className="sans-bold text-info text-left">
                      {t("SocialCommitment.Group2.Title")}
                    </h3>
                    <div className="d-flex text-left mt-3 justify-content-center flex-column">
                      <div>
                        <span>{t("SocialCommitment.Group2.Part1")}</span>
                        <br />
                        <br />
                        <span>{t("SocialCommitment.Group2.Part2")}</span>{" "}
                        <span className="sans-bold">
                          {t("SocialCommitment.Group2.Part3")}
                        </span>{" "}
                        <span>{t("SocialCommitment.Group2.Part4")}</span>{" "}
                        <span className="sans-bold">
                          {t("SocialCommitment.Group2.Part5")}
                        </span>{" "}
                        <span>{t("SocialCommitment.Group2.Part6")}</span>{" "}
                        <span className="sans-bold">
                          {t("SocialCommitment.Group2.Part7")}
                        </span>{" "}
                        <span>{t("SocialCommitment.Group2.Part8")}</span>
                        <br />
                        <br />
                        <span>{t("SocialCommitment.Group2.Part9")}</span>{" "}
                        <span className="sans-bold">
                          {t("SocialCommitment.Group2.Part10")}
                        </span>{" "}
                        <span>{t("SocialCommitment.Group2.Part11")}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex justify-content-center">
                  <div className="col-md-10">
                    <img
                      className="img-fluid"
                      src={Voluntary}
                      alt="Voluntary"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card border-0 mt-2">
              <div className="card-body group-image-left d-flex align-items-center">
                <div className="col-md-6 d-flex flex-column justify-content-center align-items-center mb-3">
                  <div className="col-md-10">
                    <h3 className="sans-bold text-info text-left">
                      {t("SocialCommitment.Group3.Title")}
                    </h3>
                    <div className="d-flex text-left mt-4 justify-content-center flex-column">
                      <div>
                        <span>{t("SocialCommitment.Group3.Part1")}</span>{" "}
                        <span className="sans-bold">
                          {t("SocialCommitment.Group3.Part2")}
                        </span>{" "}
                        <span>{t("SocialCommitment.Group3.Part3")}</span>{" "}
                        <span className="sans-bold">
                          {t("SocialCommitment.Group3.Part4")}
                        </span>
                        <br />
                        <br />
                        <span>{t("SocialCommitment.Group3.Part5")}</span>{" "}
                        <span className="sans-bold">
                          {t("SocialCommitment.Group3.Part6")}
                        </span>{" "}
                        <span>{t("SocialCommitment.Group3.Part7")}</span>{" "}
                        <span className="sans-bold">
                          {t("SocialCommitment.Group3.Part8")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex justify-content-center">
                  <div className="col-md-10">
                    <img className="img-fluid" src={Family} alt="Family" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(SocialCommitment);
