import React, { Component } from "react";
import GoogleMapComponent from "./GoogleMapComponent/GoogleMapComponent";
import { restaurantsService } from "../../services/restaurants.services";
import { authenticationService } from "../../services/authentication.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import config from "../../config/config";
import {ScreenName} from "../../analytics/analytics.config";
import {analyticsEvents} from "../../analytics/Analytics";
import {Helmet} from "react-helmet";
// Images
import auto from "../../assets/img/restaurant/AUTO.png";
import delivery from "../../assets/img/restaurant/DELIVERY.png";
import quiosco from "../../assets/img/restaurant/QUIOSCO.png";
import recarga from "../../assets/img/restaurant/RECARGA.png";
import terraza from "../../assets/img/restaurant/TERRAZA.png";
import wifi from "../../assets/img/restaurant/WIFI.png";
import zonas from "../../assets/img/restaurant/ZONAS INFANTILES.png";
import chicken from "../../assets/img/icons/chicken.png";

import "./Restaurants.scss";

class Restaurants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurants: [],
      provinces: [],
      services: [],
      filteredResults: [],
      selectedServices: [],
      currentRestaurant: [],
      locationByTitleClick: [],
      lat: "",
      lng: "",
      filter: false,
      province: "",
      myIdx: "",
      myLanguage: "",
      notFound: false,
      focusRestaurant: null,
      focusPrevIndex: "",
      focusCurrentIndex: "",
      myRef: React.createRef([]),
    };
    this.showCurrentRestaurant = this.showCurrentRestaurant.bind(this);
  }

  componentDidMount = () => {
    window.scroll(0, 0);
    analyticsEvents.logScreenView(ScreenName.RESTAURANTS)
    restaurantsService.getProvinces().then((response) => {
      restaurantsService.getRestaurants().then((res) => {
        this.setState({ restaurants: res.data });

        const sortedResponse = [...response.data];
        let provincesWithRestaurants = [];
        sortedResponse.sort(function (first, second) {
          if (first.name < second.name) {
            return -1;
          }
          if (first.name > second.name) {
            return 1;
          }
          return 0;
        });

        res.data.map((item) => {
          sortedResponse.filter((val) => {
            if (val.geocode !== item.province.geocode) {
              return false;
            }
            return provincesWithRestaurants.push(val);
          });
          return true;
        });


        // Uniq item sort
        const uniq = Array.from(
          new Set(provincesWithRestaurants.map((item) => item.id))
        ).map((id) => {
          return provincesWithRestaurants.find((item) => item.id === id);
        });

        return this.setState({ provinces: uniq });
      });
    });

    restaurantsService.getServices().then((response) => {

      this.setState({ services: response.data });
    });
    this.setState({ myLanguage: window.localStorage.getItem("i18nextLng") });

  };

  componentDidUpdate = () => {
    let newRef = this.state.myRef;
    newRef.current = [...Array(this.state.restaurants.length).keys()].map(
      (_, i) => {
        return newRef.current[i] ?? React.createRef();
      }
    );
    if (this.state.myRef !== newRef) {
      this.setState({ myRef: newRef });
    }

    const newLanguage = window.localStorage.getItem("i18nextLng");
    if (newLanguage !== this.state.myLanguage) {
      config.language = newLanguage;
      restaurantsService.getServices().then((response) => {
        this.setState({ services: response.data, myLanguage: newLanguage });
      });
    }

    const services = this.state.services && this.state.services.filter((val) => {
      if(val.name === "WIFI" || val.name === "Recarga" || val.name === "Quiosco" || val.name === "Refill" || val.name === "Kiosk"){
        return false
      }
      return true;
    })
    if(this.state.services.length !== services.length){

      this.setState({services: services})
    }
  };

  filterByCheckbox = (id, prevID) => {
    const newSelectedCheckboxArray = [...this.state.selectedServices];
    if (newSelectedCheckboxArray.length === 0) {
      newSelectedCheckboxArray.push({ id: id });
      prevID = id;
    }
    if (newSelectedCheckboxArray.map((item) => item.id).indexOf(id) === -1) {
      newSelectedCheckboxArray.push({ id: id });
      prevID = id;
    }
    if (
      newSelectedCheckboxArray.map((item) => item.id).indexOf(id) !== -1 &&
      prevID !== id
    ) {
      const val = newSelectedCheckboxArray.map((item) => item.id).indexOf(id);
      newSelectedCheckboxArray.splice(val, 1);
      prevID = id;
    }

    const arrayOrdonated = []
      .concat(newSelectedCheckboxArray)
      .sort((first, second) => (first.id > second.id ? 1 : -1));

    return this.setState({ selectedServices: arrayOrdonated });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { province, selectedServices } = this.state;
    const restaurants = this.state.restaurants.filter((restaurant) => {
      if (restaurant.province.geocode !== province) {
        return false;
      }

      if (
        restaurant.services.length !== selectedServices.length &&
        selectedServices.length > 0
      ) {
        for (var i = 0; i < selectedServices.length; i++) {
          if (
            restaurant.services
              .map((item) => item.id)
              .indexOf(selectedServices[i].id) === -1
          ) {
            return false;
          }
        }
      }

      if (restaurant.services.length === selectedServices.length) {
        for (var l = 0; l < restaurant.services.length; l++) {
          if (restaurant.services[l].id !== selectedServices[l].id) {
            return false;
          }
        }
      }

      return true;
    });
    this.state.currentRestaurant.splice(0, 1);
    const newCurrent = this.state.currentRestaurant;
    if (province && restaurants.length === 0) {
      this.setState({
        notFound: true,
      });
    }

    if (this.state.focusCurrentIndex !== "") {
      const myRef = this.state.myRef;
      myRef.current[
        this.state.focusCurrentIndex
      ].current.style.backgroundColor = "white";
      this.setState({ focusCurrentIndex: "", myIdx: "" });
    }

    this.setState({
      filteredResults: restaurants,
      currentRestaurant: newCurrent,
      myIdx: "",
    });
  };

  // Show days when click on map pin

  handleShowDays = (idx) => {
    let prevIdx = this.state.myIdx;
    if (idx !== prevIdx) {
      this.setState({ myIdx: idx });
    }
    if (prevIdx === idx) {
      this.setState({ myIdx: "" });
    }
  };

  // Focus on map pin
  // Highlights restaurant on pin click

  focusByPin = (index) => {
    let myRef = this.state.myRef;

    if (this.state.filteredResults.length > 1) {
      myRef.current[index].current.scrollIntoView({
        behavior: "smooth",
      });

      if (this.state.focusPrevIndex === 0) {
        myRef.current[this.state.focusPrevIndex].current.style.backgroundColor =
          "white";
        this.setState({ focusPrevIndex: index });
      }

      if (index !== this.state.focusPrevIndex) {
        myRef.current[index].current.style.backgroundColor = "#91198715";
        this.setState({ focusPrevIndex: index, focusCurrentIndex: index });
      }
      if (this.state.focusPrevIndex && index !== this.state.focusPrevIndex) {
        myRef.current[this.state.focusPrevIndex].current.style.backgroundColor =
          "white";
        this.setState({ focusPrevIndex: index });
      }
    }
  };

  showCurrentRestaurant = (id, idx) => {
    const restaurants = this.state.restaurants.filter((restaurant) => {
      if (restaurant.id !== id) {
        return false;
      }
      return true;
    });
    if (
      this.state.filteredResults.length === 0 ||
      this.state.currentRestaurant.length === this.state.filteredResults.length
    ) {
      this.setState({
        filteredResults: restaurants,
      });
    }

    this.setState({
      currentRestaurant: restaurants,
    });

    this.focusByPin(idx);

    this.handleShowDays(idx);
  };

  showLocation = (val) => {
    this.setState({ locationByTitleClick: val });
  };

  render() {
    const { t } = this.props;
    return (
      <>
      <Helmet>
        <title>Restaurantes - Popeyes Louisiana Kitchen</title>
      </Helmet>
      <div className="container-fluid mb-4">
        <div className="row justify-content-center">
          <div className="title bg-primary d-flex justify-content-center align-items-center w-100">
            <div>
              <h1 className="text-white title-h1 mt-4">
                {t("Restaurant.Restaurant")}
              </h1>
            </div>
          </div>
          <div className="col-md-12 mt-auto d-flex restaurants">
            <div className="col-lg-5 col-ls-12 col-md-12">
              <div className="card border-0 d-flex">
                <div className="card-body justify-content-center">
                  <div className="font-size-buscar">
                    {t("Restaurant.Selecciona-restaurante")}
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <select
                        onChange={(event) =>
                          this.setState({ province: event.target.value })
                        }
                        className="mt-4 custom-select"
                      >
                        <option defaultValue>
                          {t("Restaurant.Provincia")}
                        </option>
                        {this.state.provinces &&
                          this.state.provinces.map((val, idx) => (
                            <option key={idx} value={val.geocode}>
                              {val.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <span>
                      {t("Restaurant.restaurantes-disponen")}

                      </span>
                    </div>
                    <div className="form-group d-flex flex-wrap">
                      {this.state.services.map((val, idx) => (
                        <div key={idx} className="form-check mt-2">
                          <div className="custom-control custom-checkbox checkbox-align">
                            <input
                              onChange={() => {
                                console.log("{Restaurants.js}[](336) ", )
                                this.filterByCheckbox(val.id)}}
                              className="custom-control-input"
                              type="checkbox"
                              value={val.id}
                              id={idx}
                            />
                            <label
                              key={idx}
                              className="custom-control-label mr-1"
                              htmlFor={idx}
                            >
                              {val.name}
                              {val.name === "WIFI" && (
                                <img
                                  className="image-size-filters"
                                  src={wifi}
                                  alt="WIFI"
                                />
                              )}
                              {val.name === "Recarga" ||
                              val.name === "Refill" ? (
                                <img
                                  className="image-size-filters"
                                  src={recarga}
                                  alt="Recarga"
                                />
                              ) : null}
                              {val.name === "Auto" && (
                                <img
                                  className="image-size-filters"
                                  src={auto}
                                  alt="Auto"
                                />
                              )}
                              {val.name === "A domicilio" ||
                              val.name === "Home Delivery" ? (
                                <img
                                  className="image-size-filters"
                                  src={delivery}
                                  alt="A domicilio"
                                />
                              ) : null}
                              {val.name === "Terraza" ||
                              val.name === "Terrace" ? (
                                <img
                                  className="image-size-filters"
                                  src={terraza}
                                  alt="Terraza"
                                />
                              ) : null}
                              {val.name === "Quiosco" ||
                              val.name === "Kiosk" ? (
                                <img
                                  className="image-size-filters"
                                  src={quiosco}
                                  alt="Quiosco"
                                />
                              ) : null}
                              {val.name === "Zonas infantiles" ||
                              val.name === "Kid Zone" ? (
                                <img
                                  className="image-size-filters"
                                  src={zonas}
                                  alt="Zonas infantiles"
                                />
                              ) : null}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className="mt-4 mb-4 btn btn-primary rounded-pill w-50"
                      >
                        {t("Restaurant.Buscar")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {this.state.filteredResults.length !== 0 ? (
                <div className="restaurants-overflow">
                  {this.state.filteredResults.map((val, idx) => (
                    <div
                      key={idx}
                      className="card border-0"
                      ref={this.state.myRef.current[idx]}
                    >
                      <div className="card-body text-left">
                        <h4
                          role="button"
                          onClick={() => this.showLocation(val)}
                          className="sans-bold"
                        >
                          {val.name}
                        </h4>
                        <p>
                          {val.address}, {val.city}, {val.postalCode}
                        </p>
                        <p>
                          {t("Restaurant.Abierto")} {val.schedule.monday}
                        </p>
                        <div className="d-flex justify-content-between align-items-center restaurant-icons mb-1">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={
                              "https://www.google.com/maps/dir/?api=1&destination=" +
                              val.latitude +
                              "," +
                              val.longitude
                            }
                            alt="Google maps"
                          >
                            {t("Restaurant.Como-Ilegar")}
                          </a>
                          <a
                            href={
                              !authenticationService.currentUserValue
                                ? "/login"
                                : config.apiUrlHD + "/addresses/"
                            }
                            className="btn btn-info rounded-pill restaurant-button"
                          >
                            <img
                              className="chicken"
                              src={chicken}
                              alt="Hacer pedido"
                            />{" "}
                            <span className="chicken-font-style">
                              {t("Header.Hacer-pedido")}
                            </span>
                          </a>
                        </div>
                        <div className="d-flex justify-content-between border-bottom align-items-center restaurant-icons">
                          <div className="row icons-row">
                            {val.services.map((item, idx) => (
                              <div className="d-flex flex-wrap" key={idx}>
                                {item.name === "WIFI" && (
                                  <img
                                    className="image-size"
                                    src={wifi}
                                    alt="WIFI"
                                  />
                                )}
                                {item.name === "Recarga" && (
                                  <img
                                    className="image-size"
                                    src={recarga}
                                    alt="Recarga"
                                  />
                                )}
                                {item.name === "Auto" && (
                                  <img
                                    className="image-size"
                                    src={auto}
                                    alt="Auto"
                                  />
                                )}
                                {item.name === "A domicilio" && (
                                  <img
                                    className="image-size"
                                    src={delivery}
                                    alt="A domicilio"
                                  />
                                )}
                                {item.name === "Terraza" && (
                                  <img
                                    className="image-size"
                                    src={terraza}
                                    alt="Terraza"
                                  />
                                )}
                                {item.name === "Quiosco" && (
                                  <img
                                    className="image-size"
                                    src={quiosco}
                                    alt="Quiosco"
                                  />
                                )}
                                {item.name === "Zonas infantiles" && (
                                  <img
                                    className="image-size"
                                    src={zonas}
                                    alt="Zonas infantiles"
                                  />
                                )}
                              </div>
                            ))}
                          </div>
                          <div className="buttons d-flex mb-2 align-items-center mt-1">
                            <div
                              onClick={() => this.handleShowDays(idx)}
                              role="button"
                              className="horario"
                            >
                              {idx !== this.state.myIdx ? (
                                <span className="text-secondary">
                                  {t("Restaurant.Horario")}
                                  <FontAwesomeIcon
                                    className="ml-4"
                                    icon={faChevronDown}
                                  />
                                </span>
                              ) : (
                                <span className="text-secondary">
                                  {t("Restaurant.Horario")}
                                  <FontAwesomeIcon
                                    className="ml-4"
                                    icon={faChevronUp}
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        {idx === this.state.myIdx && (
                          <div
                            key={idx}
                            className="d-flex flex-column justify-content-between mt-3 text-secondary"
                          >
                            <div className="d-flex justify-content-between">
                              <p>{t("Restaurant.Lunes")}</p>
                              <p className="text-secondary">
                                {val.schedule.monday}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>{t("Restaurant.Martes")}</p>
                              <p className="text-secondary">
                                {val.schedule.tuesday}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>{t("Restaurant.Miércoles")}</p>
                              <p className="text-secondary">
                                {val.schedule.wednesday}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>{t("Restaurant.Jueves")}</p>
                              <p className="text-secondary">
                                {val.schedule.thursday}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>{t("Restaurant.Viernes")}</p>
                              <p className="text-secondary">
                                {val.schedule.friday}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>{t("Restaurant.Sábado")}</p>
                              <p className="text-secondary">
                                {val.schedule.saturday}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>{t("Restaurant.Domingo")}</p>
                              <p className="text-secondary">
                                {val.schedule.sunday}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p>{t("Restaurant.Festivo")}</p>
                              <p className="text-secondary">
                                {val.schedule.holiday}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <React.Fragment>
                  {this.state.notFound && (
                    <div className="card border-0">
                      <div className="card-body text-center">
                        <h4 className="sans-bold text-secondary">
                          {t("Restaurant.Restaurant-not-found")}
                        </h4>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
            <GoogleMapComponent
              showCurrentRestaurant={this.showCurrentRestaurant}
              restaurants={this.state.restaurants}
              filteredResults={this.state.filteredResults}
              currentRestaurant={this.state.currentRestaurant}
              lat={
                this.state.filteredResults.length !== 0 &&
                this.state.filteredResults[0].latitude
              }
              lng={
                this.state.filteredResults.length !== 0 &&
                this.state.filteredResults[0].longitude
              }
              locationByTitleClick={this.state.locationByTitleClick}
            />
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default withTranslation()(Restaurants);
