import config from "../config/config";
import { authenticationService } from "./authentication.service";

export const newsService = {
  getNewsHome,
  getNewsNovedades
};

function getNewsHome() {
  const requestOptions = {
    method: "GET",
    headers: authenticationService.prepareHeader(),
  };

  return fetch(
    `${config.apiUrl}/api/v1/news?language=${config.language}&key=${config.key}&tags=WEB,HOME`,
    requestOptions
  ).then(authenticationService.handleResponse);
}

function getNewsNovedades() {
  const requestOptions = {
    method: "GET",
    headers: authenticationService.prepareHeader(),
  };

  return fetch(
    `${config.apiUrl}/api/v1/news?language=${config.language}&key=${config.key}&tags=WEB,NEWS`,
    requestOptions
  ).then(authenticationService.handleResponse);
}
