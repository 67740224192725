import React, { Component } from "react";
import { Link } from "react-router-dom";
import SuccessForgotPassword from "./SuccessForgotPassword/SuccessForgotPassword";
import { authenticationService } from "../../services/authentication.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import "./ForgotPassword.scss";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false,
      wrongData: false,
      successForgotPassword: false,
      message: "",
      success: false,
      error: false,
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const email = this.state.email;
    this.setState({ loading: true });
    authenticationService
      .forgotPassword(email)
      .then(() => {
        let message = "";
          if (window.localStorage.getItem("i18nextLng") === "es") {
            message = "Enviar correo electrónico con éxito";
          } else if (window.localStorage.getItem("i18nextLng") === "en") {
            message = "Email send with success";
          } else if (window.localStorage.getItem("i18nextLng") === "cat") {
            message = "Enviament de correu electrònic amb èxit";
          }
        this.setState({
          successForgotPassword: true,
          loading: false
        });
        const error = false;
        const success = true;
        this.props.notificationHandler(success, error, message);
      },
      (errorMessage) => {
        this.setState({
          loading: false,
        });
        const message = errorMessage
        const error = true;
        const success = false;
        this.props.notificationHandler(success, error, message);
      }
      );
  };
  render() {
    const { t } = this.props;
    const { loading, wrongData } = this.state;
    return (
      <React.Fragment>
        {!this.state.successForgotPassword ? (
          <React.Fragment>
            <div className="container-fluid">
              <div className="row align-items-center justify-content-center">
                  <div className="card w-100 justify-content-center d-flex flex-column">
                    <div className="card-body forgot-bg forgot d-flex justify-content-center flex-column">
                    <div className="d-flex flex-column justify-content-center text-center">
                      <h1 className="text-info text-font-chicken title-forgot">
                        {t("Forgot.Vamos-a-ayudarte")}
                      </h1>
                      <br />
                      <div className="col-md-5 align-self-center">

                      <p>{t("Forgot.Vamos-a-ayudarte-paragraf")}</p>
                      </div>
                    </div>
                    <div className="col-md-5 mt-4 align-self-center">
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          <label>{t("Form.Email")} *</label>
                          <input
                            required
                            className="form-control"
                            type="email"
                            name="email"
                            onChange={(event) =>
                              this.setState({ email: event.target.value })
                            }
                          />
                          {wrongData && (
                            <p className="text-danger text-sm-center mt-3">
                              {t("Forgot.Wrong-data")}
                            </p>
                          )}
                        </div>
                        <div className="d-flex mt-5 justify-content-between">
                          <button
                            type="submit"
                            className="btn forgot-password-button btn-primary rounded-pill"
                          >
                            {t("Forgot.Enviar")}{" "}
                            {loading && (
                              <FontAwesomeIcon icon={faSpinner} spin />
                            )}
                          </button>
                          <Link
                            to="/login"
                            className="forgot-password-button btn btn-primary rounded-pill text-center text-decoration-none"
                          >
                            {t("Forgot.Cancelar")}
                          </Link>
                        </div>
                      </form>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <SuccessForgotPassword notificationHandler={this.props.notificationHandler} email={this.state.email} />
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(ForgotPassword);
