import React, { Component } from "react";
import Cookies from "../Cookies/Cookies";
import { Modal } from "react-bootstrap";
import close from "../../assets/img/buttons/TOCLOSE.svg";
import back from "../../assets/img/carta/BACK.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import config from "../../config/config.js";
import { cookiesHelpers } from "../../helpers/cookies/cookies.helpers";
import { analyticsEvents } from "../../analytics/Analytics";
import getTableCookies from './getTableCookies'
import "./CookiePopup.scss";

class CookiePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCookie: this.props.showCookie ?? true,
      showAdvance: this.props.showAdvance ?? false,
      functionality: true,
      browserAnalysis: false,
      thirdParties: false,
      showTechnical: false,
      showAnalysis: false,
      showParty: false,
      showThirdPartyTable: false,
      closeAll: this.props.closeAll ?? false,
      showCookiePolicy: false,
      showDiv: true,
      tableCookies: getTableCookies(this.props.t),
    };
    this.acceptCustom = this.acceptCustom.bind(this);
  }

  componentDidMount = () => {
    if (cookiesHelpers.checkCookie(config.analyticsCookie)) {
      const newBrowserAnalysis = cookiesHelpers.getCookie(
        config.analyticsCookie
      );
      if (newBrowserAnalysis === "true") {
        this.setState({ browserAnalysis: true });
      } else this.setState({ browserAnalysis: false });
    }
    if (cookiesHelpers.checkCookie(config.thirdPartyCookie)) {
      const newThirdParties = cookiesHelpers.getCookie(config.thirdPartyCookie);
      if (newThirdParties === "true") {
        this.setState({ thirdParties: true });
      } else this.setState({ thirdParties: false });
    }
  };

  acceptAll = () => {
    const { thirdParties } = this.state;
    const domain = config.cookieDomain;
    cookiesHelpers.setCookie(config.functionalityCookie, true, domain);
    cookiesHelpers.setCookie(config.analyticsCookie, true, domain);
    cookiesHelpers.setCookie(config.thirdPartyCookie, thirdParties, domain);
    analyticsEvents.setAnalyticsEnabled(true);
    this.setState({ showCookie: false });
    this.props.handleShowButton();
  };

  acceptCustom = () => {
    const { browserAnalysis, thirdParties } = this.state;
    const domain = config.cookieDomain;
    cookiesHelpers.setCookie(config.functionalityCookie, true, domain);
    cookiesHelpers.setCookie(config.analyticsCookie, browserAnalysis, domain);
    cookiesHelpers.setCookie(config.thirdPartyCookie, thirdParties, domain);
    analyticsEvents.setAnalyticsEnabled(browserAnalysis);
    this.setState({ showCookie: false, showAdvance: false });
    this.props.handleShowButton();
  };

  advanceSetings = () => {
    if (this.state.closeAll) {
      this.setState({
        showCookie: false,
        showAdvance: false,
        showAll: false,
      });
      this.props.closeCookie();
    } else {
      this.setState({
        showCookie: !this.state.showCookie,
        showAdvance: !this.state.showAdvance,
      });
    }
  };

  cookiePolicy = () => {
    return (
      <Modal
        className="text-center"
        centered
        size="md"
        show={this.state.showCookiePolicy}
        onHide={() => this.setState({ showCookiePolicy: false })}
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center">
            <button
              className="text-left border-0 bg-transparent shadow-none"
              onClick={() => this.setState({ showCookiePolicy: false })}
            >
              <img className="close-size" src={back} alt="Back" />
            </button>
            <div className="mt-2">
              <Cookies />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {!this.state.showAdvance &&
          this.state.showCookiePolicy &&
          this.cookiePolicy()}
        {this.state.showCookie && (
          <div className="w-100" data-nosnippet>
            <div className="cookie-body bg-primary text-white d-flex flex-column p-3">
              <div className="p-2 mobile-size-text">
                <h1 data-nosnippet>{t("CookiePopup.Cookie-Policy")}</h1>
                <div className="mt-3 mb-3">
                  <span data-nosnippet>{t("CookiePopup.To-Use")}</span>
                  <br />
                  <span data-nosnippet>{t("CookiePopup.To-Use-2")}</span>
                </div>
              </div>

              <div className="w-100 d-flex cookie-buttons ml-2 mr-2">
                <div className="d-flex cookie-buttons-column">
                  <div
                    role="button"
                    onClick={() => this.advanceSetings()}
                    className="btn margin-handle align-self-center bg-white rounded-pill align-self-end mt-2"
                  >
                    {t("CookiePopup.Set-cookies")}
                  </div>
                  <div
                    role="button"
                    onClick={() => this.acceptAll()}
                    className="btn margin-handle align-self-center bg-white text-primary rounded-pill align-self-end mt-2"
                  >
                    {t("CookiePopup.Accept-All")}
                  </div>
                </div>
                <button
                  className="btn margin-handle text-white mt-2"
                  onClick={() => this.setState({ showCookiePolicy: true })}
                >
                  <span className="border-bottom">
                    {t("CookiePopup.More-information")}
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
        {this.state.showAdvance && (
          <React.Fragment>
            {this.state.showCookiePolicy ? (
              this.cookiePolicy()
            ) : (
              <Modal
                className="text-center"
                centered
                size="lg"
                show={this.state.showAdvance}
                onHide={() => this.advanceSetings()}
              >
                <Modal.Body>
                  <div className="d-flex flex-column justify-content-center">
                    <button
                      className="text-left border-0 bg-transparent shadow-none"
                      onClick={() => this.advanceSetings()}
                    >
                      <img className="close-size" src={close} alt="Close" />
                    </button>
                    <p className="sans-bold text-left mt-3">
                      {t("CookiePopup.Cookie-Policy")}
                    </p>
                    <p className="text-left">{t("CookiePopup.To-Use")}</p>
                    <p className="text-left">{t("CookiePopup.To-Use-2")}</p>
                    <p className="text-left">{t("CookiePopup.Information")}</p>
                    <div role="button" className="text-primary text-left">
                      <span
                        role="button"
                        onClick={() =>
                          this.setState({ showCookiePolicy: true })
                        }
                        className="allergens-border"
                      >
                        {t("CookiePopup.Cookie-Policy")}
                      </span>
                    </div>
                    <p className="mt-3 text-left sans-bold">
                      {t("CookiePopup.Allow")}
                    </p>
                    <div className="d-flex flex-column border-bottom pb-3 pt-3">
                      <div className="d-flex w-100 justify-content-between">
                        <div
                          onClick={() =>
                            this.setState({
                              showTechnical: !this.state.showTechnical,
                            })
                          }
                          role="button"
                        >
                          {!this.state.showTechnical ? (
                            <span className="text-secondary">
                              <FontAwesomeIcon
                                className="mr-2 text-primary"
                                icon={faChevronDown}
                              />

                              {t("CookiePopup.Technical")}
                            </span>
                          ) : (
                            <span className="text-secondary">
                              <FontAwesomeIcon
                                className="mr-2 text-primary"
                                icon={faChevronUp}
                              />
                              {t("CookiePopup.Technical")}
                            </span>
                          )}
                        </div>

                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitchesChecked"
                            defaultChecked
                            disabled
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitchesChecked"
                          ></label>
                        </div>
                      </div>
                    </div>
                    {this.state.showTechnical && (
                      <div className="w-100 text-left mt-2 mb-3">
                        <span>{t("CookiePopup.Technical-Cookie")}</span>
                      </div>
                    )}

                    {/* Analytics checkbox */}
                    {this.state.showDiv && (
                      <React.Fragment>
                        <div className="d-flex flex-column border-bottom pb-3 pt-3">
                          <div className="d-flex w-100 justify-content-between align-items-center">
                            <div
                              onClick={() =>
                                this.setState({
                                  showAnalysis: !this.state.showAnalysis,
                                })
                              }
                              role="button"
                            >
                              {!this.state.showAnalysis ? (
                                <span className="text-secondary d-flex align-items-center text-left">
                                  <FontAwesomeIcon
                                    className="mr-2 text-primary"
                                    icon={faChevronDown}
                                  />
                                  {t("CookiePopup.Browser")}
                                </span>
                              ) : (
                                <span className="text-secondary d-flex align-items-center text-left">
                                  <FontAwesomeIcon
                                    className="mr-2 text-primary"
                                    icon={faChevronUp}
                                  />
                                  {t("CookiePopup.Browser")}
                                </span>
                              )}
                            </div>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customSwitchesChecked1"
                                checked={this.state.browserAnalysis}
                                onChange={() =>
                                  this.setState({
                                    browserAnalysis:
                                      !this.state.browserAnalysis,
                                  })
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customSwitchesChecked1"
                              ></label>
                            </div>
                          </div>
                        </div>

                        {this.state.showAnalysis && (
                          <div className="w-100 text-left mt-2 mb-3">
                            <span>{t("CookiePopup.Analytics-Cookie")}</span>
                          </div>
                        )}
                      </React.Fragment>
                    )}

                    {/* Third party cookie checkbox */}
                    {this.state.showDiv && (
                      <React.Fragment>
                        <div className="d-flex flex-column border-bottom pb-3 pt-3">
                          <div className="d-flex w-100 justify-content-between">
                            <div
                              onClick={() =>
                                this.setState({
                                  showParty: !this.state.showParty,
                                })
                              }
                              role="button"
                            >
                              {!this.state.showParty ? (
                                <span className="text-secondary">
                                  <FontAwesomeIcon
                                    className="mr-2 text-primary"
                                    icon={faChevronDown}
                                  />
                                  {t("CookiePopup.Third-Party")}
                                </span>
                              ) : (
                                <span className="text-secondary">
                                  <FontAwesomeIcon
                                    className="mr-2 text-primary"
                                    icon={faChevronUp}
                                  />
                                  {t("CookiePopup.Third-Party")}
                                </span>
                              )}
                            </div>

                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customSwitchesChecked2"
                                checked={this.state.thirdParties}
                                onChange={() =>
                                  this.setState({
                                    thirdParties: !this.state.thirdParties,
                                  })
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customSwitchesChecked2"
                              ></label>
                            </div>
                          </div>
                        </div>

                        {this.state.showParty && (
                          <div className="w-100 text-left mt-2 mb-3">
                            <span>{t("CookiePopup.Third-Party-Cookie")}</span>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                    {/* End checkboxes */}

                    {/* Third party cookie table */}
                    {this.state.showDiv && (
                      <React.Fragment>
                        <div className="d-flex flex-column pb-3 pt-3">
                          <div className="d-flex w-100">
                            <div
                              onClick={() =>
                                this.setState({
                                  showThirdPartyTable: !this.state.showThirdPartyTable,
                                })
                              }
                              role="button"
                            >
                              {!this.state.showThirdPartyTable ? (
                                <span className="text-secondary">
                                  <FontAwesomeIcon
                                    className="mr-2 text-primary"
                                    icon={faChevronDown}
                                  />
                                  {t("CookiePopup.Cookies-Used")}
                                </span>
                              ) : (
                                <span className="text-secondary">
                                  <FontAwesomeIcon
                                    className="mr-2 text-primary"
                                    icon={faChevronUp}
                                  />
                                  {t("CookiePopup.Cookies-Used")}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        {this.state.showThirdPartyTable && (
                          <table className="cookie-table">
                            <thead className="cookie-table-head">
                              <tr>
                                <th className="align-middle cookie-table-row">{t("CookiePopup.Table.Domain")}</th>
                                <th className="align-middle cookie-table-row">{t("CookiePopup.Table.AS-Name")}</th>
                                <th className="align-middle cookie-table-row">{t("CookiePopup.Table.Name")}</th>
                                <th className="align-middle cookie-table-row">{t("CookiePopup.Table.Type")}</th>
                                <th className="align-middle cookie-table-row cookie-table-row-info">{t("CookiePopup.Table.Info")}</th>
                                <th className="align-middle cookie-table-row">{t("CookiePopup.Table.Expiration")}</th>
                                <th className="align-middle cookie-table-row">{t("CookiePopup.Table.Links")}</th>
                              </tr>
                            </thead>
                            <tbody className="table-bordered">
                              {this.state.tableCookies.map((cookie, index) => (
                                <tr key={index}>
                                  <td className="align-middle cookie-table-row">{cookie.domain}</td>
                                  <td className="align-middle cookie-table-row">{cookie.asName}</td>
                                  <td className="align-middle cookie-table-row">{cookie.name}</td>
                                  <td className="align-middle cookie-table-row">{cookie.type}</td>
                                  <td className="align-middle cookie-table-row cookie-table-row-info">{cookie.info}</td>
                                  <td className="align-middle cookie-table-row">{cookie.expiration}</td>
                                  <td className="align-middle cookie-table-row">
                                    <a href={cookie.url}>
                                      {cookie.links}
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </React.Fragment>
                    )}

                    <div className="w-100 mt-4 mb-4 d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-primary rounded-pill w-50"
                        onClick={this.acceptCustom}
                      >
                        {t("CookiePopup.Save")}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(CookiePopup);
