import React, { Component } from "react";
import residuos from "../../assets/img/sostenabilidad/GESTION-RESIDUOS-2.jpg";
import banner from "../../assets/img/sostenabilidad/BANNER.jpg";
import responsable from "../../assets/img/sostenabilidad/ABASTECIMIENTO-RESPONSABLE-3.jpg";
import edificios from "../../assets/img/sostenabilidad/EDIFICIOS-SOSTENIBLES-1.jpg";
import { withTranslation } from "react-i18next";
import {ScreenName} from "../../analytics/analytics.config";
import {analyticsEvents} from "../../analytics/Analytics";
import {Helmet} from "react-helmet";
import "./Sostenabilidad.scss";

class Sostenibilidad extends Component {
  constructor(props) {
    super(props);
    this.myRefResiduos = React.createRef();
    this.myRefEdificios = React.createRef();
    this.myRefResponsable = React.createRef();
  }
  componentDidMount() {
    window.scroll(0, 0);
    analyticsEvents.logScreenView(ScreenName.SUSTAINABILITY)
  }
  executeScrollEdificios = () => {
    let pos = this.myRefEdificios.current.style.position;
    let top = this.myRefEdificios.current.style.top;
    this.myRefEdificios.current.style.position = "relative";
    this.myRefEdificios.current.style.top = "-130px";

    this.myRefEdificios.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    this.myRefEdificios.current.style.top = top;
    this.myRefEdificios.current.style.position = pos;
  };
  executeScrollResiduos = () => {
    let pos = this.myRefResiduos.current.style.position;
    let top = this.myRefResiduos.current.style.top;
    this.myRefResiduos.current.style.position = "relative";
    this.myRefResiduos.current.style.top = "-130px";
    this.myRefResiduos.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    this.myRefResiduos.current.style.top = top;
    this.myRefResiduos.current.style.position = pos;
  };
  executeScrollResponsable = () => {
    let pos = this.myRefResponsable.current.style.position;
    let top = this.myRefResponsable.current.style.top;
    this.myRefResponsable.current.style.position = "relative";
    this.myRefResponsable.current.style.top = "-130px";
    this.myRefResponsable.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    this.myRefResponsable.current.style.top = top;
    this.myRefResponsable.current.style.position = pos;
  };
  render() {
    const { t } = this.props;
    return (
      <>
      <Helmet>
        <title>Sostenibilidad - Popeyes Louisiana Kitchen</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="title bg-primary d-flex justify-content-center align-items-center w-100">
            <div>
              <h1 className="text-white title-h1 mt-4">
                {t("Sostenibilidad.Sostenibilidad")}
              </h1>
            </div>
          </div>

          <div className="w-100">
            <img
              className="img-fluid w-100"
              src={banner}
              alt="Sostenibilidad"
            />
          </div>
          <div className="card border-0 w-100 text-center">
            <div className="card-body love-card">
              <h1 className="text-info text-font-chicken sos-font-size">
                {t("Sostenibilidad.LOVE")}
              </h1>
              <h4 className="text-info sans-bold mt-2">
                {t("Sostenibilidad.ADN")}
              </h4>
              <div className="mt-4 mb-4">
                {t("Sostenibilidad.ADN-paragraf")}
                <br />
                <span className="text-primary sans-bold">
                  <span role="button" onClick={this.executeScrollEdificios}>
                    {t("Sostenibilidad.ADN-List-1")}
                  </span>
                  {" - "}
                  <span role="button" onClick={this.executeScrollResiduos}>
                    {t("Sostenibilidad.ADN-List-2")}
                  </span>
                  {" - "}
                  <span role="button" onClick={this.executeScrollResponsable}>
                    {t("Sostenibilidad.ADN-List-3")}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div className="card border-0 mt-2" ref={this.myRefEdificios}>
            <div className="card-body residuos d-flex align-items-center">
              <div className="col-md-6 d-flex flex-column justify-content-center align-items-center mb-3">
                <div className="col-md-10">
                  <h3 className="sans-bold text-info">
                    {t("Sostenibilidad.ENVASES")}
                  </h3>

                  <div className="d-flex text-left mt-4 justify-content-center flex-column">
                    {t("Sostenibilidad.ENVASES-List-1")}
                    <br />
                    <br />
                    <ul>
                      <li>
                        <span className="sans-bold text-primary">
                          {t("Sostenibilidad.ENVASES-List-2-part-1")}
                        </span>{" "}
                        {t("Sostenibilidad.ENVASES-List-2-part-2")}
                      </li>
                      <br />
                      <li>
                        <span className="sans-bold text-primary">
                          {t("Sostenibilidad.ENVASES-List-3-part-1")}
                        </span>{" "}
                        {t("Sostenibilidad.ENVASES-List-3-part-2")}
                      </li>
                      <br />
                      <li>
                        <span className="sans-bold text-primary">
                          {t("Sostenibilidad.ENVASES-List-4-part-1")}
                        </span>{" "}
                        {t("Sostenibilidad.ENVASES-List-4-part-2")}
                      </li>
                      <br />
                      <li>{t("Sostenibilidad.ENVASES-paragraf")}</li>
                      <br />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-center">
                <div className="col-md-10">
                  <img className="img-fluid" src={edificios} alt="Edificios" />
                </div>
              </div>
            </div>
          </div>

          <div className="card border-0" ref={this.myRefResiduos}>
            <div className="card-body restaurantes d-flex align-items-center">
              <div className="col-md-6 d-flex flex-column justify-content-center align-items-center mb-3">
                <div className="col-md-10">
                  <h3 className="sans-bold text-info">
                    {t("Sostenibilidad.Nuestros-edificios")}
                  </h3>
                  <div className="d-flex text-left mt-3 justify-content-center flex-column">
                    {t("Sostenibilidad.Nuestros-edificios-paragraf-1")}
                    <br />
                    <br />
                    {t("Sostenibilidad.Nuestros-edificios-paragraf-2")}
                    <br />
                    <br />
                    <ul>
                      <li>
                        <span className="sans-bold text-primary">
                          {t("Sostenibilidad.Nuestros-edificios-list-1-part-1")}
                        </span>{" "}
                        {t("Sostenibilidad.Nuestros-edificios-list-1-part-2")}
                      </li>
                      <br />
                      <li>
                        {t("Sostenibilidad.Nuestros-edificios-list-2-part-1")}{" "}
                        <span className="sans-bold text-primary">
                          {t("Sostenibilidad.Nuestros-edificios-list-2-part-2")}
                        </span>
                      </li>
                      <br />
                      <li>
                        {t("Sostenibilidad.Nuestros-edificios-list-3-part-1")}{" "}
                        <span className="sans-bold text-primary">
                          {t("Sostenibilidad.Nuestros-edificios-list-3-part-2")}
                        </span>{" "}
                        {t("Sostenibilidad.Nuestros-edificios-list-3-part-3")}
                      </li>
                      <br />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-center">
                <div className="col-md-10">
                  <img className="img-fluid" src={residuos} alt="Residuos" />
                </div>
              </div>
            </div>
          </div>

          <div className="card border-0 mb-4" ref={this.myRefResponsable}>
            <div className="card-body residuos d-flex align-items-center">
              <div className="col-md-6 d-flex flex-column justify-content-center align-items-center mb-3">
                <div className="col-md-10">
                  <h3 className="sans-bold text-info">
                    {t("Sostenibilidad.Abastecimiento-responsable")}
                  </h3>

                  <div className="d-flex text-left mt-4 justify-content-center flex-column">
                    {t("Sostenibilidad.Abastecimiento-responsable-paragraf")}
                    <br />
                    <br />
                    <ul>
                    <li>
                        {t("Sostenibilidad.Abastecimiento-responsable-list-1-part-1")}{" "}
                        <span className="sans-bold text-primary">
                          {t("Sostenibilidad.Abastecimiento-responsable-list-1-part-2")}
                        </span>{" "}
                        {t("Sostenibilidad.Abastecimiento-responsable-list-1-part-3")}
                      </li><br/>
                      <li>
                        {t("Sostenibilidad.Abastecimiento-responsable-list-2-part-1")}{" "}
                        <span className="sans-bold text-primary">
                          {t("Sostenibilidad.Abastecimiento-responsable-list-2-part-2")}
                        </span>{" "}
                        {t("Sostenibilidad.Abastecimiento-responsable-list-2-part-3")}
                      </li><br/>
                      <li>
                        {t("Sostenibilidad.Abastecimiento-responsable-list-3-part-1")}{" "}
                        <span className="sans-bold text-primary">
                          {t("Sostenibilidad.Abastecimiento-responsable-list-3-part-2")}
                        </span>{" "}
                        {t("Sostenibilidad.Abastecimiento-responsable-list-3-part-3")}
                      </li><br/>
                      <li>
                        {t("Sostenibilidad.Abastecimiento-responsable-list-4-part-1")}{" "}
                        <span className="sans-bold text-primary">
                          {t("Sostenibilidad.Abastecimiento-responsable-list-4-part-2")}
                        </span>{" "}
                        {t("Sostenibilidad.Abastecimiento-responsable-list-4-part-3")}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-center">
                <div className="col-md-10">
                  <img
                    className="img-fluid"
                    src={responsable}
                    alt="Responsable"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default withTranslation()(Sostenibilidad);
