const getTableCookies = (t) => {
    return [{
        domain: 'Facebook.com',
        asName: 'Facebook',
        name: 'Fr',
        type: 'Commercial',
        info: t("CookiePopup.Cookies.FacebookFr"),
        expiration: t("CookiePopup.Table.Period.90-Days"),
        links: t("CookiePopup.Table.More-Info"),
        url: 'https://www.facebook.com/policies/cookies',
    }, {
        domain: 'Facebook.com',
        asName: 'Facebook',
        name: 'Fbp',
        type: 'Commercial',
        info: t("CookiePopup.Cookies.FacebookFbp"),
        expiration: t("CookiePopup.Table.Period.90-Days"),
        links: t("CookiePopup.Table.More-Info"),
        url: 'https://www.facebook.com/policies/cookies',
    }, {
        domain: 'Facebook.com',
        asName: 'Facebook',
        name: 'Oo',
        type: 'Commercial',
        info: t("CookiePopup.Cookies.FacebookOo"),
        expiration: t("CookiePopup.Table.Period.5-Years"),
        links: t("CookiePopup.Table.More-Info"),
        url: 'https://www.facebook.com/policies/cookies',
    }, {
        domain: 'amazon-adsystem.com',
        asName: 'Exemplo Platfform',
        name: 'ad-idad-privacy',
        type: 'Commercial',
        info: t("CookiePopup.Cookies.Amazon"),
        expiration: t("CookiePopup.Table.Period.9-Months"),
        links: t("CookiePopup.Table.More-Info"),
        url: 'https://www.amazon.com/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=468496',
    }, {
        domain: 'adnxs.com',
        asName: 'Appnexus',
        name: 'uuid2',
        type: 'Commercial',
        info: t("CookiePopup.Cookies.Appnexus-uuid2"),
        expiration: t("CookiePopup.Table.Period.180-Days"),
        links: t("CookiePopup.Table.More-Info"),
        url: 'https://www.appnexus.com/en/company/platform-privacy-policy',
    }, {
        domain: 'adnxs.com',
        asName: 'Appnexus',
        name: 'uuid2 (opt-out)',
        type: 'Commercial',
        info: t("CookiePopup.Cookies.Appnexus-uuid2-opt"),
        expiration: t("CookiePopup.Table.Period.10-Years"),
        links: t("CookiePopup.Table.More-Info"),
        url: 'https://www.appnexus.com/en/company/platform-privacy-policy',
    }, {
        domain: 'adnxs.com',
        asName: 'Appnexus',
        name: 'sess',
        type: 'Commercial',
        info: t("CookiePopup.Cookies.Appnexus-sess"),
        expiration: t("CookiePopup.Table.Period.180-Days"),
        links: t("CookiePopup.Table.More-Info"),
        url: 'https://www.appnexus.com/en/company/platform-privacy-policy',
    }, {
        domain: 'adnxs.com',
        asName: 'Appnexus',
        name: 'icu',
        type: 'Commercial',
        info: t("CookiePopup.Cookies.Appnexus-icu"),
        expiration: t("CookiePopup.Table.Period.180-Days"),
        links: t("CookiePopup.Table.More-Info"),
        url: 'https://www.appnexus.com/en/company/platform-privacy-policy',
    }, {
        domain: 'adnxs.com',
        asName: 'Appnexus',
        name: 'Anj',
        type: 'Commercial',
        info: t("CookiePopup.Cookies.Appnexus-anj"),
        expiration: t("CookiePopup.Table.Period.180-Days"),
        links: t("CookiePopup.Table.More-Info"),
        url: 'https://www.appnexus.com/en/company/platform-privacy-policy',
    }, {
        domain: 'adnxs.com',
        asName: 'Appnexus',
        name: 'Token',
        type: 'Commercial',
        info: t("CookiePopup.Cookies.Appnexus-token"),
        expiration: t("CookiePopup.Table.Period.180-Days"),
        links: t("CookiePopup.Table.More-Info"),
        url: 'https://www.appnexus.com/en/company/platform-privacy-policy',
    }, {
        domain: 'adnxs.com',
        asName: 'Appnexus',
        name: 'Acb',
        type: 'Commercial',
        info: t("CookiePopup.Cookies.Appnexus-acb"),
        expiration: t("CookiePopup.Table.Period.180-Days"),
        links: t("CookiePopup.Table.More-Info"),
        url: 'https://www.appnexus.com/en/company/platform-privacy-policy',
    }, {
        domain: 'google.com/Doubleclick.net',
        asName: 'Google/Doubleclic',
        name: 'DSID,AID, TAID, _gads,_gac_,IDE,RUL',
        type: 'Commercial',
        info: t("CookiePopup.Cookies.Google"),
        expiration: t("CookiePopup.Table.Period.1-Year"),
        links: t("CookiePopup.Table.More-Info"),
        url: 'https://policies.google.com/privacy%20https:/tools.google.com/dlpage/gaoptout?hl=es',
    }]
}

export default getTableCookies;