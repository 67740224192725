import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { menuService } from "../../../services/menu.service";
import config from "../../../config/config";
import {Helmet} from "react-helmet";
import "./Category.scss";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
      myLanguage: "",
    };
  }
  componentDidMount = () => {
    window.scroll(0, 0);
    menuService.getCollections().then((response) => {
      this.setState({ menu: response.data });
    });
  };

  componentDidUpdate = () => {
    const newLanguage = window.localStorage.getItem("i18nextLng");
    if (newLanguage !== this.state.myLanguage) {
      config.language = newLanguage;
      menuService.getCollections().then((response) => {
        this.setState({ menu: response.data, myLanguage: newLanguage });
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
      <Helmet>
        <title>Carta - Popeyes Louisiana Kitchen</title>
      </Helmet>
      <div className="container-fluid category">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="title bg-primary mb-3 d-flex justify-content-center align-items-center w-100">
            <div>
              <h1 className="text-white title-category mt-4">
                {t("Carta.Category")}
              </h1>
            </div>
          </div>

          <div className="row col-lg-9 col-md-9 col-sm-12">
            {this.state.menu.length !== 0 &&
              this.state.menu.map((val, idx) => (
                <div
                  key={idx}
                  className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 mb-3"
                >
                  <div
                    role="button"
                    onClick={() => this.props.handleShowCatalog(val.id)}
                    className="card card-height-category card-rounded"
                  >
                    <div className="d-flex flex-column justify-content-center">
                      <div className="card-img-top p-1">
                        <img
                          className="catalog-category-image"
                          src={val.thumbnail}
                          alt="Categories"
                        />
                      </div>
                      <div className="mt-3 text-center text-primary sans-bold">
                        <p className="text-size">{val.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default withTranslation()(Category);
