import config from "../config/config";
import { authenticationService } from "./authentication.service";

export const userService = {
  getUser,
  updateUser,
  deleteUser,
};

function getUser(token = undefined) {
  let headers = new Headers();
  if (token) {
    if (headers.get("Accept-Language")) {
      headers.set("Accept-Language", `${config.language}`);
    } else {
      headers.append("Accept-Language", `${config.language}`);
    }
    if (headers.get("Content-Type")) {
      headers.set("Content-Type", "application/json");
    } else {
      headers.append("Content-Type", "application/json");
    }
    headers.set("authorization", `Bearer ${token}`);
  } else {
    headers = authenticationService.prepareHeader();
  }
  console.log("{user.service.js}[getUser](27) ");
  const requestOptions = {
    method: "GET",
    headers: headers,
  };
  return fetch(
    `${config.apiUrl}/api/v1/auth/user?key=${config.key}`,
    requestOptions
  ).then(authenticationService.handleResponse);
}

function updateUser(email, firstName, lastName, phone, news) {
  const requestOptions = {
    method: "POST",
    headers: authenticationService.prepareHeader(),
    body: JSON.stringify({ email, firstName, lastName, phone, news }),
  };

  return fetch(
    `${config.apiUrl}/api/v1/auth/user?key=${config.key}`,
    requestOptions
  ).then(authenticationService.handleResponse);
}

function deleteUser() {
  const requestOptions = {
    method: "DELETE",
    headers: authenticationService.prepareHeader(),
  };

  return fetch(
    `${config.apiUrl}/api/v1/auth/user?key=${config.key}`,
    requestOptions
  ).then(authenticationService.handleResponse);
}
