import React, { Component } from "react";
import { Link } from "react-router-dom";
import { authenticationService } from "../../../services/authentication.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import love from "../../../assets/img/contact/LOVE-THAT-CHICKEN.png";
import { withTranslation } from "react-i18next";
import {ScreenName} from "../../../analytics/analytics.config";
import {analyticsEvents} from "../../../analytics/Analytics";

class SuccessRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email,
      message: "",
      success: false,
      error: false,
      loading: false,
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    analyticsEvents.logScreenView(ScreenName.MAIL_VALIDATION_POPUP)
  };

  resendEmailValidation = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    authenticationService
      .resendEmailValidation(this.state.email)
      .then(() => {
        let message = "";
          if (window.localStorage.getItem("i18nextLng") === "es") {
            message = "Reenvío de correo electrónico con éxito";
          } else if (window.localStorage.getItem("i18nextLng") === "en") {
            message = "Email resend successfully";
          } else if (window.localStorage.getItem("i18nextLng") === "cat") {
            message = "El correu electrònic es reenvia correctament";
          }
        this.setState({
          loading: false,
        });
        const error = false;
        const success = true;
        this.props.notificationHandler(success, error, message);
      },
      (errorMessage) => {
        this.setState({
          loading: false,
        });
        const message = errorMessage
        const error = true;
        const success = false;
        this.props.notificationHandler(success, error, message);
      }
      );
  };
  render() {
    const { t } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center mt-3 mb-3">
            <div className="col-md-12">
              <div className="card border-0 d-flex justify-content-center">
                <div className="card-body success-register mb-5 mt-3">
                <div className="d-flex justify-content-center">
                  <img className="img-fluid" src={love} alt="Register" />
                </div>
              <div className="mt-4 justify-content-center text-center mb-5">
                <div className="mb-4">

                <span className="sans-bold text-success register-success-font">
                  {t("Register.Enviarte-un-mail")}
                </span>
                <p className="mt-2">{t("Register.Correo-electrónico")}</p>
                </div>
                <span className="text-primary sans-bold register-success-font">{this.state.email}</span>
                <p className="mt-4">
                  {t("Register.No-ves-correo-electrónico")}
                </p>
                <div className="register-success-buttons-group d-flex justify-content-center align-items-center mt-5">
                  <Link
                    className="register-success-button btn btn-primary rounded-pill text-decoration-none text-center"
                    to="/login"
                  >
                    {t("Register.Página-de-inicio")}
                  </Link>
                  <button
                    onClick={this.resendEmailValidation}
                    className="register-success-button btn btn-primary rounded-pill text-decoration-none text-center"
                  >
                    {t("Register.Reenviar-mail")}{" "}
                    {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                  </button>
                </div>
              </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(SuccessRegister);
