import React, { Component } from "react";
import { Link } from "react-router-dom";
import { authenticationService } from "../../../services/authentication.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import love from "../../../assets/img/contact/LOVE-THAT-CHICKEN.png";

class SuccessForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email,
      message: "",
      success: false,
      error: false,
      loading: false,
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  resendMailChangePassword = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    authenticationService
      .forgotPassword(this.state.email)
      .then(() => {
        let message = "";
        if (window.localStorage.getItem("i18nextLng") === "es") {
          message = "Reenvío de correo electrónico con éxito";
        } else if (window.localStorage.getItem("i18nextLng") === "en") {
          message = "Email resend successfully";
        } else if (window.localStorage.getItem("i18nextLng") === "cat") {
          message = "El correu electrònic es reenvia correctament";
        }
        this.setState({
          loading: false,
        });
        const error = false;
        const success = true;
        this.props.notificationHandler(success, error, message);
      },
      (errorMessage) => {
        this.setState({
          loading: false,
        });
        const message = errorMessage
        const error = true;
        const success = false;
        this.props.notificationHandler(success, error, message);
      }
      )
  };
  render() {
    const { t } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center mt-3 mb-3">
            <div className="col-md-12">
              <div className="card border-0 d-flex justify-content-center">
                <div className="card-body success-forgot-password mb-5 mt-3">
                  <div className="d-flex justify-content-center">
                    <img
                      className="img-fluid"
                      src={love}
                      alt="Forgot password"
                    />
                  </div>
                  <div className="mt-4 justify-content-center text-center mb-5">
                    <div className="mb-4">
                      <span className="sans-bold text-success success-forgot-password-font">
                        {t("Forgot.Correo-electrónico")}
                      </span>
                      <p className="mt-2">
                        {t("Forgot.Correo-electrónico-enviado")}
                      </p>
                    </div>
                    <span className="text-primary sans-bold success-forgot-password-font">
                      {this.state.email}
                    </span>
                    <p className="mt-4">
                      {t("Forgot.No-ves-correo-electrónico")}
                    </p>
                    <div className="success-forgot-password-buttons-group d-flex justify-content-center align-items-center mt-5">
                      <Link
                        className="success-forgot-password-button btn btn-primary rounded-pill text-decoration-none text-center"
                        to="/login"
                      >
                        {t("Forgot.Vovler")}
                      </Link>
                      <button
                        onClick={this.resendMailChangePassword}
                        className="success-forgot-password-button btn btn-primary rounded-pill text-decoration-none text-center"
                      >
                        {t("Forgot.Reenviar")}{" "}
                        {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(SuccessForgotPassword);
