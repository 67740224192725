import React from 'react';
import ReactDOM from 'react-dom';
import RoutePath from './RoutePath';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import config from './config/config';
import "./i18n";
import './index.scss';

const tagManagerArgs = {
  gtmId: config.gtmID,
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <RoutePath />,
  document.getElementById('root')
);

reportWebVitals();
