import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {ScreenName} from "../../analytics/analytics.config";
import {analyticsEvents} from "../../analytics/Analytics";
import {Helmet} from "react-helmet";

class Cookies extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    analyticsEvents.logScreenView(ScreenName.COOKIES_POLICY)
  };
  render() {
    const { t } = this.props;
    return (
      <>
      <Helmet>
        <title>Politica de Cookies - Popeyes Louisiana Kitchen</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center">
          <div className="w-100 title col-md-12 col-ls-12 bg-primary d-flex justify-content-center align-items-center">
            <div>
              <h1 className="text-white title-privacy mt-4">
              {t("Cookies.Politica-de-cookies")}
              </h1>
            </div>
          </div>
          <div className="col-md-12 p-3">
            <div className="card text-left mt-4 mb-4 border-0">
              <div className="card-text">
                <h4 className="text-primary">{t("Cookies.Politica-de-cookies-subtitle")}</h4>
                <br />
                <span>
                  {t("Cookies.Politica-de-cookies-paragraf-1")}{" "}
                  {t("Cookies.Politica-de-cookies-paragraf-2")}
                </span>
                <br />
                <br />
                <span>{t("Cookies.Por-regla-general")}</span>
                <br />
                <br />
                <span className="sans-bold">
                  {t("Cookies.Funcion-de-caducidad")}
                </span>
                <br />
                <br />
                <ul>
                  <li>{t("Cookies.Sesion")}</li>
                  <li>{t("Cookies.Persistencia")}</li>
                </ul>
                <br />
                <span className="sans-bold">
                  {t("Cookies.Funcion-de-procedencia")}
                </span>
                <br />
                <br />
                <ul>
                  <li>{t("Cookies.Origen")}</li>
                  <li>{t("Cookies.Terceros")}</li>
                </ul>
                <br />
                <span>{t("Cookies.Las-finalidades")}</span>
                <br />
                <br />
                <br />
                <h4 className="text-primary sans-bold">
                  {t("Cookies.Analíticas")}
                </h4>
                <br />
                <span>{t("Cookies.Analíticas-paragraf-1")}</span>
                <br /> <br />
                <span>{t("Cookies.Analíticas-paragraf-2")} </span>
                <br />
                <br />
                <br />
                <h4 className="text-primary sans-bold">
                  {t("Cookies.Autenticación")}
                </h4>
                <br />
                <span>{t("Cookies.Autenticación-paragraf-1")}</span>
                <br />
                <br />
                <span>{t("Cookies.Autenticación-paragraf-2")}</span>
                <br />
                <br />
                <span>{t("Cookies.Autenticación-paragraf-3")}</span>
                <br />
                <br />
                <span>{t("Cookies.Autenticación-paragraf-4")}</span>
                <br />
                <br />
                <span>{t("Cookies.Autenticación-paragraf-5")}</span>
                <br />
                <br />
                <span className="sans-bold">Firefox:</span>{" "}
                <a
                  href="https://support.mozilla.org/es/home"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://support.mozilla.org/es/home
                </a>
                <br />
                <br />
                <span className="sans-bold">Internet Explorer:</span>{" "}
                <a
                  href="https://support.microsoft.com/ph/807/es-es"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://support.microsoft.com/ph/807/es-es
                </a>
                <br />
                <br />
                <span className="sans-bold">Safari:</span>{" "}
                <a
                  href=" https://support.apple.com/kb/HT1677?viewlocale=es_ES"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://support.apple.com/kb/HT1677?viewlocale=es_ES
                </a>
                <br />
                <br />
                <span className="sans-bold">Opera:</span>{" "}
                <a
                  href="https://help.opera.com/Windows/8.54/es-ES/index.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://help.opera.com/Windows/8.54/es-ES/index.html
                </a>
                <br />
                <br />
                <h4>
                  <b className="text-primary">
                    {t("Cookies.Información-sobre-ventas")}
                  </b>
                </h4>
                <br />
                <span>{t("Cookies.Información-sobre-ventas-paragraf-1")}</span>
                <br />
                <br />
                <span>{t("Cookies.Información-sobre-ventas-paragraf-2")}</span>
                <br />
                <br />
                <span>{t("Cookies.Información-sobre-ventas-paragraf-3")}</span>
                <br />
                <br />
                <span>{t("Cookies.Información-sobre-ventas-paragraf-4")}</span>
                <br />
                <br />
                <span>{t("Cookies.Información-sobre-ventas-paragraf-5")}</span>
                <br />
                <br />
                <span>{t("Cookies.Información-sobre-ventas-paragraf-6")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default withTranslation()(Cookies);
