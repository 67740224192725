import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { withTranslation } from "react-i18next";
import "./Footer.scss";

class Footer extends Component {
  render() {
    const { t } = this.props;
    return (
      <footer className="footer container-fluid justify-content-between">
        <div className="text-center text-md-left">
          <div className="row footer-height justify-content-between align-items-center">
            <div className="col-md-6 mt-md-1 mt-3">
              <ul className="list-unstyled">
                <li className="mt-2 ml-4">
                  {/*<Link
                  className="footer_link text-decoration-none footer_up_link"
                  to="/calidad"
                  >
                  <span>{t("Footer.Calidad")}</span>
                  </Link>
                  <span className="ml-2 mr-2 text-white">|</span>*/}
                  {/*<Link
                    className="footer_link text-decoration-none footer_up_link"
                    to="/historia"
                  >
                    <span>{t("Footer.Historia")}</span>
                  </Link>
                <span className="ml-2 mr-2 text-white">|</span>*/}
                  <Link
                    className="footer_link text-decoration-none footer_up_link"
                    to="/sostenibilidad"
                  >
                    <span>{t("Footer.Sostenibilidad")}</span>
                  </Link>
                  <span className="ml-2 mr-2 text-white">|</span>
                  <Link
                    className="footer_link text-decoration-none footer_up_link"
                    to="/contact"
                  >
                    <span>{t("Footer.Contacto")}</span>
                  </Link>
                  {/*<span className="ml-2 mr-2 text-white">|</span>*/}
                  {/*<Link*/}
                  {/*  className="footer_link text-decoration-none footer_up_link"*/}
                  {/*  to="/mis-recompensas"*/}
                  {/*>*/}
                  {/*  <span>{t("Footer.Mis-recompensas")}</span>*/}
                  {/*</Link>*/}
                </li>
                <li className="mt-2 ml-4">
                  <Link
                    className="footer_link text-decoration-none"
                    to="/aviso-legal"
                  >
                    <span>{t("Footer.Aviso-legal")}</span>
                  </Link>
                  <span className="ml-2 mr-2 text-primary">|</span>
                  <Link
                    className="footer_link text-decoration-none"
                    to="/politica-de-privacidad"
                  >
                    <span>{t("Footer.Privacidad")}</span>
                  </Link>
                  <span className="ml-2 mr-2 text-primary">|</span>
                  <Link
                    className="footer_link text-decoration-none"
                    to="/cookies"
                  >
                    <span>{t("Footer.Cookies")}</span>
                  </Link>
                  <span className="ml-2 mr-2 text-primary">|</span>
                  <Link
                    className="footer_link text-decoration-none"
                    to="/general-conditions"
                  >
                    <span>{t("Footer.General-Condition")}</span>
                  </Link>
                </li>
                <li className="mt-2 ml-4">
                  <a
                    href="https://popeyes-prod.s3.eu-west-1.amazonaws.com/Carta_alergenos_Ed_00_Octubre_2021.pdf"
                    rel="noreferrer"
                    target="_blank"
                    className="footer_link text-decoration-none"
                  >
                    <span>{t("Footer.Allergens")}</span>
                  </a>
                  <span className="ml-2 mr-2 text-primary">|</span>
                  <a
                    href="https://popeyes-prod.s3.eu-west-1.amazonaws.com/Nutricional_alergenos_Ed_00_Octubre_2021.pdf"
                    rel="noreferrer"
                    target="_blank"
                    className="footer_link text-decoration-none"
                  >
                    <span>{t("Footer.Nutritional")}</span>
                  </a>

                  <span className="ml-2 mr-2 text-primary">|</span>
                  <a
                    href="https://popeyes-prod.s3.eu-west-1.amazonaws.com/BBLL_GAME_-_PLK_vF_12.09.2022_1.pdf"
                    rel="noreferrer"
                    target="_blank"
                    className="footer_link text-decoration-none"
                  >
                    <span>{t("Footer.Legal-basis")}</span>
                  </a>
                  {/* <span className="ml-2 mr-2 text-primary">|</span>
                  <a
                    href="https://popeyes-prod.s3.eu-west-1.amazonaws.com/BBLL_Premios_Pollito_de_Louisiana_POP.pdf"
                    rel="noreferrer"
                    target="_blank"
                    className="footer_link text-decoration-none"
                  >
                    <span>{t("Footer.Foundation")}</span>
                  </a> */}
                </li>
              </ul>
            </div>
            <div className="col-md-6 mt-md-1 d-md-flex pl-md-5 p-4 align-items-center justify-content-end">
              <ul className="list-unstyled m-0 p-0 d-flex social-icons">
                <li className="mr-4 btn btn-primary btn-rounded">
                  <a
                    href="https://www.instagram.com/popeyes_es/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Popeyes instagram"
                  >
                    <span>
                      <FontAwesomeIcon className="mb-1" icon={faInstagram} />
                    </span>
                  </a>
                </li>
                <li className="mr-4 btn btn-primary btn-rounded">
                  <a
                    href="https://www.facebook.com/Popeyes-Espa%C3%B1a-106638957441592/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Popeyes facebook"
                  >
                    <span>
                      <FontAwesomeIcon className="mb-1" icon={faFacebookF} />
                    </span>
                  </a>
                </li>
                <li className="btn btn-primary btn-rounded">
                  <a
                    href="https://twitter.com/popeyes_es"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Popeyes twitter"
                  >
                    <span>
                      <FontAwesomeIcon className="mb-1" icon={faTwitter} />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(Footer);
