import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./GeneralCondition.scss";
import {Helmet} from "react-helmet";

class GeneralCondition extends Component {
  constructor(props) {
    super(props);
    this.myRef3 = React.createRef();
    this.myRef5 = React.createRef();
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  executeScroll3 = () => {
    let pos = this.myRef3.current.style.position;
    let top = this.myRef3.current.style.top;
    this.myRef3.current.style.position = "relative";
    this.myRef3.current.style.top = "-130px";

    this.myRef3.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    this.myRef3.current.style.top = top;
    this.myRef3.current.style.position = pos;
  };

  executeScroll5 = () => {
    let pos = this.myRef5.current.style.position;
    let top = this.myRef5.current.style.top;
    this.myRef5.current.style.position = "relative";
    this.myRef5.current.style.top = "-130px";

    this.myRef5.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    this.myRef5.current.style.top = top;
    this.myRef5.current.style.position = pos;
  };
  render() {
    const { t,i18n } = this.props;
    return (
      <>
      <Helmet>
        <title>Condiciones generales - Popeyes Louisiana Kitchen</title>
      </Helmet>
      <div className="container-fluid general-condition">
        <div className="row align-items-center justify-content-center">
          <div className="w-100 title col-md-12 col-ls-12 bg-primary d-flex justify-content-center align-items-center">
            <div>
              <h1 className="text-white title-privacy mt-4">
                {t("Condition.General-Condition")}
              </h1>
            </div>
          </div>
          <div className="col-md-12 p-3">
            <div className="card text-left mt-4 mb-4 border-0">
              <div className="card-text">
                <p>{t("Condition.Subtitle")}</p>
                <p className="text-primary sans-bold">
                  {t("Condition.Subtitle-1")}
                </p>
                <span>{t("Condition.Subtitle-1-paragraph-1-part-1")}</span>
                <span>
                  {" "}
                  <a href="https://popeyes.es" target="_blank" rel="noreferrer">
                    www.popeyes.es
                  </a>{" "}
                </span>
                <span>{t("Condition.Subtitle-1-paragraph-1-part-2")}</span>
                <br />
                <br />
                <p>{t("Condition.Subtitle-1-paragraph-2")}</p>
                <span>{t("Condition.Subtitle-1-paragraph-3-part-1")}</span>
                <span
                  className="text-primary"
                  role="button"
                  onClick={this.executeScroll3}
                >
                  {t("Condition.Subtitle-1-paragraph-3-part-2")}
                </span>{" "}
                <span>{t("Condition.Subtitle-1-paragraph-3-part-3")}</span>
                <br />
                <br />
                <span>{t("Condition.Subtitle-1-paragraph-4-part-1")}</span>{" "}
                <span>
                  <span
                    className="text-primary"
                    role="button"
                    onClick={this.executeScroll5}
                  >
                    {t("Condition.Subtitle-1-paragraph-4-part-2")}{" "}
                  </span>{" "}
                  ).
                </span>
                <br />
                <br />
                <p>{t("Condition.Subtitle-1-paragraph-5")}</p>
                <p>{t("Condition.Subtitle-1-paragraph-6")}</p>
                <p>{t("Condition.Subtitle-1-paragraph-7")}</p>
                <p>{t("Condition.Subtitle-1-paragraph-8")}</p>
                <p>{t("Condition.Subtitle-1-paragraph-9")}</p>
                <p>{t("Condition.Subtitle-1-paragraph-10")}</p>
                <>
                {i18n.exists("Condition.Subtitle-1-paragraph-10-a")&&
                  <p>
                    {t("Condition.Subtitle-1-paragraph-10-a")}
                    <a href="https://popeyes.es" target="_blank" rel="noreferrer">
                      {t("Condition.Subtitle-1-paragraph-10-a-link")}
                    </a>
                  </p>
                }
                </>
                <p>{t("Condition.Subtitle-1-paragraph-11")}</p>
                <p>{t("Condition.Subtitle-1-paragraph-12")}</p>
                <p>{t("Condition.Subtitle-1-paragraph-13")}</p>
                <ol type="a">
                  <li>{t("Condition.Subtitle-1-paragraph-14")}</li>
                  <li>{t("Condition.Subtitle-1-paragraph-15")}</li>
                  <li>{t("Condition.Subtitle-1-paragraph-16")}</li>
                </ol>
                <p className="text-primary sans-bold">
                  {t("Condition.Subtitle-2")}
                </p>
                <p>{t("Condition.Subtitle-2-paragraph-1")}</p>
                <p>{t("Condition.Subtitle-2-paragraph-2")}</p>
                <p>{t("Condition.Subtitle-2-paragraph-3")}</p>
                <p>{t("Condition.Subtitle-2-paragraph-4")}</p>
                <p>{t("Condition.Subtitle-2-paragraph-5")}</p>
                <p>{t("Condition.Subtitle-2-paragraph-6")}</p>
                <p>{t("Condition.Subtitle-2-paragraph-7")}</p>
                <p>{t("Condition.Subtitle-2-paragraph-8")}</p>
                <div ref={this.myRef3}>
                  <p className="text-primary sans-bold">
                    {t("Condition.Subtitle-3")}
                  </p>
                  <p>{t("Condition.Subtitle-3-paragraph-1")}</p>
                  <p>{t("Condition.Subtitle-3-paragraph-2")}</p>
                </div>
                <p className="text-primary sans-bold">
                  {t("Condition.Subtitle-4")}
                </p>
                <p>{t("Condition.Subtitle-4-paragraph-1")}</p>
                <ol type="a">
                  <li>{t("Condition.Subtitle-4-paragraph-2")}</li>
                  <li>{t("Condition.Subtitle-4-paragraph-3")}</li>
                  <li>{t("Condition.Subtitle-4-paragraph-4")}</li>
                  <li>{t("Condition.Subtitle-4-paragraph-5")}</li>
                </ol>
                <div ref={this.myRef5}>
                  <p className="text-primary sans-bold">
                    {t("Condition.Subtitle-5")}
                  </p>
                  <p>{t("Condition.Subtitle-5-paragraph-1")}</p>
                  <p>{t("Condition.Subtitle-5-paragraph-2")}</p>
                </div>
                <p className="text-primary sans-bold">
                  {t("Condition.Subtitle-6")}
                </p>
                <p>{t("Condition.Subtitle-6-paragraph-1")}</p>
                <p>{t("Condition.Subtitle-6-paragraph-2")}</p>
                <p>{t("Condition.Subtitle-6-paragraph-3")}</p>
                <p>{t("Condition.Subtitle-6-paragraph-4")}</p>
                <p>{t("Condition.Subtitle-6-paragraph-5")}</p>
                <p>{t("Condition.Subtitle-6-paragraph-6")}</p>
                <p className="text-primary sans-bold">
                  {t("Condition.Subtitle-7")}
                </p>
                <span>{t("Condition.Subtitle-7-paragraph-1-part-1")}</span>{" "}
                <span>
                  {" "}
                  <a href="https://popeyes.es" target="_blank" rel="noreferrer">
                    www.popeyes.es
                  </a>{" "}
                </span>
                <span>{t("Condition.Subtitle-7-paragraph-1-part-2")}</span>
                <br />
                <p>{t("Condition.Subtitle-7-paragraph-2")}</p>
                <span>{t("Condition.Subtitle-7-paragraph-3-part-1")}</span>
                <span>
                  {" "}
                  <a href="https://popeyes.es" target="_blank" rel="noreferrer">
                    www.popeyes.es
                  </a>{" "}
                </span>
                <span>{t("Condition.Subtitle-7-paragraph-3-part-2")}</span>
                <p>{t("Condition.Subtitle-7-paragraph-4")}</p>
                <p>{t("Condition.Subtitle-7-paragraph-5")}</p>
                <p>{t("Condition.Subtitle-7-paragraph-6")}</p>
                <p>{t("Condition.Subtitle-7-paragraph-7")}</p>
                <p>{t("Condition.Subtitle-7-paragraph-8")}</p>
                <ul>
                  <li>{t("Condition.Subtitle-7-paragraph-9")}</li>
                  <li>{t("Condition.Subtitle-7-paragraph-10")}</li>
                  <li>{t("Condition.Subtitle-7-paragraph-11")}</li>
                  <li>{t("Condition.Subtitle-7-paragraph-12")}</li>
                  <li>{t("Condition.Subtitle-7-paragraph-13")}</li>
                  <li>{t("Condition.Subtitle-7-paragraph-14")}</li>
                  <li>
                    <span>{t("Condition.Subtitle-7-paragraph-15-part-1")}</span>
                    <span>
                      {" "}
                      <a
                        href="https://popeyes.es"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.popeyes.es
                      </a>{" "}
                    </span>

                    <span>{t("Condition.Subtitle-7-paragraph-15-part-2")}</span>
                  </li>
                </ul>
                <p>{t("Condition.Subtitle-7-paragraph-16")}</p>
                <li>{t("Condition.Subtitle-7-paragraph-17")}</li>
                <ol type="1">
                  <li>{t("Condition.Subtitle-7-paragraph-18")}</li>
                  <li>{t("Condition.Subtitle-7-paragraph-19")}</li>
                  <li>{t("Condition.Subtitle-7-paragraph-20")}</li>
                </ol>
                <p>{t("Condition.Subtitle-7-paragraph-21")}</p>
                <ol type="1">
                  <li>{t("Condition.Subtitle-7-paragraph-22")}</li>
                  <li>{t("Condition.Subtitle-7-paragraph-23")}</li>
                  <li>{t("Condition.Subtitle-7-paragraph-24")}</li>
                  <ol type="i">
                  <li>{t("Condition.Subtitle-7-paragraph-25")}</li>
                  <li>
                    <span>

                    {t("Condition.Subtitle-7-paragraph-26-part-1")}
                    </span>{" "}
                    <span className="sans-bold">

                    {t("Condition.Subtitle-7-paragraph-26-part-2")}
                    </span>{" "}
                    <span>

                    {t("Condition.Subtitle-7-paragraph-26-part-3")}
                    </span>

                  </li>
                  <li>{t("Condition.Subtitle-7-paragraph-27")}</li>
                  </ol>
                  <li>{t("Condition.Subtitle-7-paragraph-28")}</li>
                  <li>{t("Condition.Subtitle-7-paragraph-29")}</li>
                  <li>{t("Condition.Subtitle-7-paragraph-30")}</li>
                  <li>{t("Condition.Subtitle-7-paragraph-31")}</li>
                </ol>
                  <p>{t("Condition.Subtitle-7-paragraph-32")}</p>
                  <ol type="1">
                    <li>{t("Condition.Subtitle-7-paragraph-33")}</li>
                    <li>{t("Condition.Subtitle-7-paragraph-34")}</li>
                    <li>{t("Condition.Subtitle-7-paragraph-35")}</li>
                    <li>{t("Condition.Subtitle-7-paragraph-36")}</li>
                    <li>{t("Condition.Subtitle-7-paragraph-37")}</li>
                    <li>{t("Condition.Subtitle-7-paragraph-38")}</li>
                    <li>{t("Condition.Subtitle-7-paragraph-39")}</li>
                    <li>{t("Condition.Subtitle-7-paragraph-40")}</li>
                    <li>{t("Condition.Subtitle-7-paragraph-41")}</li>
                    <li>{t("Condition.Subtitle-7-paragraph-42")}</li>
                  </ol>
                <br />
                <br />
                <p className="text-primary sans-bold">
                  {t("Condition.Subtitle-8")}
                </p>

                <p>
                <span>{t("Condition.Subtitle-8-paragraph-1-part-1")}</span>{" "}
                <Link to="/politica-de-privacidad">
                <span>{t("Condition.Subtitle-8-paragraph-1-part-2")}</span>{" "}
                </Link>
                <span>{t("Condition.Subtitle-8-paragraph-1-part-3")}</span>{" "}
                <Link to="/cookies">
                <span>{t("Condition.Subtitle-8-paragraph-1-part-4")}</span>{"."}
                </Link>
                  </p>
                <p className="text-primary sans-bold">
                  {t("Condition.Subtitle-9")}
                </p>
                <p>{t("Condition.Subtitle-9-paragraph-1")}</p>
                <p className="text-primary sans-bold">
                  {t("Condition.Subtitle-10")}
                </p>
                <p>{t("Condition.Subtitle-10-paragraph-1")}</p>
                <span>{t("Condition.Subtitle-10-paragraph-2")}</span>{" "}
                <a
                  href="http://bk-emea-prd.s3.amazonaws.com/sites/burgerking.es/files/documents/CODIGO%2520CONDUCTA%2520BK%2520SPAIN.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("Condition.Subtitle-10-paragraph-3")}
                </a>
                <br />
                <br />
                <p className="text-primary sans-bold">
                  {t("Condition.Subtitle-11")}
                </p>
                <p>{t("Condition.Subtitle-11-paragraph-1")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default withTranslation()(GeneralCondition);
