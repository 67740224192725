import React, { Component } from "react";
import { authenticationService } from "../../services/authentication.service";
import { withTranslation } from "react-i18next";
import warning             from "../../assets/img/delete/warning.svg";
import "./Logout.scss";

class Logout extends Component {
  handleLogout = () => {
    authenticationService.logoutHD().then(() => {
      localStorage.removeItem("Popeyes-Token");
      window.location.href = "/";
    });
  };
  backToHome = () => {
    this.props.history.push("/")
  }
  render() {
    const { t } = this.props;
    return (
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-12">
            <div className="card border-0 d-flex justify-content-center">
              <div className="card-body text-center logout">
                <div className="d-flex justify-content-center mb-5 mt-4">
                  <img className="img-fluid" src={warning} alt="Logout" height="138" width ="181"  />
                </div>
                <span className="sans-bold">
                  {t("Header.Cerrar-sesión-pregunta")}
                </span>
                <div className="d-flex justify-content-center mt-5">
                  <button
                    className="btn btn-primary rounded-pill mr-2"
                    onClick={() => this.backToHome()}
                  >
                    {t("Header.Volver")}
                  </button>
                  <button
                    className="btn btn-primary rounded-pill ml-2"
                    onClick={this.handleLogout}
                  >
                    {t("Header.Cerrar-sesión")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Logout);
