import React, { Component } from "react";
import { authenticationService } from "../../services/authentication.service";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import "./PrivacyQuestionPopup.scss";
import GeneralCondition from "../GeneralCodition/GeneralCondition";
import Privacy from "../Privacy/Privacy";

class PrivacyQuestionPopup extends Component {
  constructor(props) {
    super(props);
    console.log(
      "{PrivacyQuestionPopup.js}[constructor](13) this.props",
      this.props
    );
    this.state = {
      showPQP: this.props.showPQP ?? false,
      name: this.props.name ?? "",
      closeAll: this.props.closeAll ?? false,
      showPrivacyPolicy: false,
      showTermsAndConditions: false,
      isPrivacyPolicyAccepted: false,
      isNewsAccepted: this.props.isNewsAccepted,
    };
    this.accept = this.accept.bind(this);
  }

  componentDidMount = () => {};

  accept = () => {
    authenticationService.acceptPrivacyPolicy(this.state.isNewsAccepted);
    this.props.acceptPrivacyPolicy(this.state.isNewsAccepted);
    this.props.acceptPolicyCallback();
    this.setState({ showPQP: false });
  };

  setShowPrivacyPolicy = () => {
    console.log(
      "{PrivacyQuestionPopup.js}[setShowPrivacyPolicy](47) this.state.isPrivacyPolicyAccepted",
      this.state.isPrivacyPolicyAccepted
    );
    this.setState({
      showPrivacyPolicy: true,
    });
  };

  setShowTermsAndConditions = () => {
    this.setState({
      showTermsAndConditions: true,
    });
  };

  modalPrivacy = () => {
    return (
      <Modal
        className="text-center"
        centered
        size="lg"
        show={true}
        onHide={() => {
          this.setState({ showPrivacyPolicy: false });
        }}
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center">
            <div className="mt-2">
              <Privacy />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  modalGeneralCondition = () => {
    return (
      <Modal
        className="text-center"
        centered
        size="lg"
        show={true}
        onHide={() => {
          this.setState({ showTermsAndConditions: false });
        }}
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center">
            <div className="mt-2">
              <GeneralCondition />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  modalPrivacyQuestionContent = () => {
    const { t } = this.props;
    return (
      <Modal
        className="text-center"
        centered
        size="lg"
        show={this.state.showPQP}
        onHide={() => {
          console.log("{PrivacyQuestionPopup.js}[](111) ");
        }}
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center some-space">
            <form>
              <p className="sans-bold text-left">
                {t("PrivacyQuestionPopup.Hello") + this.props.name + "!"}
              </p>
              <div className="form-group d-flex flex-wrap">
                <div
                  key={"isPrivacyPolicyAccepted"}
                  className="form-check mt-2 d-flex"
                >
                  <div className="custom-control custom-checkbox checkbox-align">
                    <input
                      onChange={() => {
                        console.log("{PrivacyQuestionPopup.js}[](134) ");
                        this.setState({
                          isPrivacyPolicyAccepted:
                            !this.state.isPrivacyPolicyAccepted,
                        });
                      }}
                      className="custom-control-input"
                      type="checkbox"
                      value={this.state.isPrivacyPolicyAccepted}
                      id={"isPrivacyPolicyAccepted"}
                    />
                    <label
                      onClick={() => {
                        console.log("{PrivacyQuestionPopup.js}[](136) ");
                      }}
                      key={"isPrivacyPolicyAccepted"}
                      className="custom-control-label mr-1 text-left"
                      htmlFor={"isPrivacyPolicyAccepted"}
                    ></label>
                  </div>
                  <label className={"text-left"}>
                    {t("PrivacyQuestionPopup.Privacy-1")}
                    <span
                      className={"action-text"}
                      onClick={this.setShowPrivacyPolicy}
                    >
                      {t("PrivacyQuestionPopup.Privacy-Link-1")}
                    </span>
                    {t("PrivacyQuestionPopup.Privacy-2")}
                    <span
                      className={"action-text"}
                      onClick={this.setShowTermsAndConditions}
                    >
                      {t("PrivacyQuestionPopup.Privacy-Link-2")}
                    </span>
                    {t("PrivacyQuestionPopup.Privacy-3")}
                  </label>
                </div>

                {!this.props.isNewsAccepted ? (
                  <div
                    key={"isNewsAccepted"}
                    className="line form-check mt-2 d-flex"
                  >
                    <div className="custom-control custom-checkbox checkbox-align   ">
                      <input
                        onChange={() => {
                          console.log("{PrivacyQuestionPopup.js}[](157) ");
                          this.setState({
                            isNewsAccepted: !this.state.isNewsAccepted,
                          });
                        }}
                        className="custom-control-input"
                        type="checkbox"
                        value={this.state.isNewsAccepted}
                        id={"isNewsAccepted"}
                      />
                      <label
                        className="custom-control-label mr-1 text-left"
                        htmlFor={"isNewsAccepted"}
                      ></label>
                    </div>

                    <label className={"text-left"}>
                      {t("PrivacyQuestionPopup.Communications")}
                    </label>
                  </div>
                ) : null}
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary rounded-pill w-50"
                  onClick={this.accept}
                  disabled={!this.state.isPrivacyPolicyAccepted}
                >
                  {t("PrivacyQuestionPopup.Accept")}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  render() {
    return (
      <React.Fragment>
        {this.state.showPQP && this.modalPrivacyQuestionContent()}
        {this.state.showPrivacyPolicy && this.modalPrivacy()}
        {this.state.showTermsAndConditions && this.modalGeneralCondition()}
      </React.Fragment>
    );
  }
}
export default withTranslation()(PrivacyQuestionPopup);
