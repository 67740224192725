import React, { Component } from "react";
import config from "../../config/config";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { authenticationService } from "../../services/authentication.service";
import { recompensasService } from "../../services/recompensas.service";
import MobileBanner from "../MobileBanner/MobileBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import banner from "../../assets/img/recompensas/BANNER.png";
import one from "../../assets/img/recompensas/1.png";
import two from "../../assets/img/recompensas/2.png";
import filled from "../../assets/img/recompensas/GREEN-STAR.png";
import unfilled from "../../assets/img/recompensas/GRAY-STAR.png";
import close from "../../assets/img/buttons/TOCLOSE.svg";
import { withTranslation } from "react-i18next";
import {ScreenName} from "../../analytics/analytics.config";
import {analyticsEvents} from "../../analytics/Analytics";
import {Helmet} from "react-helmet";
import "./Recompensas.scss";

export class Recompensas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rewards: [],
      user: 0,
      vouchers: [],
      showCondiciones: false,
      showPreguntas: false,
      firstRewards: "",
      secondRewards: "",
      thirdRewards: "",
      filledStars: "",
      showModal: false,
      success: false,
    };
    this.starsCalculator = this.starsCalculator.bind(this);
  }

  componentDidMount = () => {
    window.scroll(0, 0);
    if (authenticationService.currentUserValue) {
      recompensasService.getRecompensas().then((response) => {
        this.setState({
          user: response.user.lifetime_points,
          rewards: response.rewards,
          firstRewards: response.rewards[0].image,
          secondRewards: response.rewards[1].image,
          thirdRewards: response.rewards[2].image,
        });
        this.starsCalculator();
      });
      recompensasService.getVouchers().then((response) => {
        this.setState({ vouchers: response.results });
      });
    }
    analyticsEvents.logScreenView(ScreenName.LOYALTY_REWARDS)
  };

  handleShowCondiciones = () => {
    this.setState({ showCondiciones: !this.state.showCondiciones });
  };

  handleShowPreguntas = () => {
    this.setState({ showPreguntas: !this.state.showPreguntas });
  };

  linkSelect = () => {
    return this.props.history.push("/login");
  };

  starsCalculator = () => {
    const points = this.state.user;
    let filledStars = points % 9;
    this.setState({ filledStars: filledStars });
    let unfilledStars = 9 - filledStars;
    var filledStarsParent = document.getElementById("filled"),
      filledStarImage = filled,
      img;

    var unfilledStarsParent = document.getElementById("unfilled"),
      unfilledStarImage = unfilled;

    for (var i = 0; i < filledStars; i++) {
      img = new Image();
      img.src = filledStarImage;
      img.className = "stars";
      filledStarsParent.appendChild(img);
    }

    for (var j = 0; j < unfilledStars; j++) {
      img = new Image();
      img.src = unfilledStarImage;
      img.className = "stars";
      unfilledStarsParent.appendChild(img);
    }
  };

  handleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  copyToClipboard = (code) => {
    navigator.clipboard.writeText(code);
    this.setState({ success: true });
    setTimeout(() => {
      this.setState({ success: false });
    }, 2000);
  };

  Codigos = () => {
    const { t } = this.props;
    return (
      <Modal
        className="text-center"
        centered
        size="md"
        show={this.state.showModal}
        onHide={() => this.handleModal()}
      >
        <Modal.Body>
          <div className="card border-0 d-flex flex-column justify-content-center">
            <button
              className="text-left border-0 bg-transparent shadow-none"
              onClick={() => this.handleModal()}
            >
              <img className="close-size" src={close} alt="Close" />
            </button>

            <div>
              <img className="star-filled" src={filled} alt="Codigos" />
              {this.state.vouchers && this.state.vouchers[0] ? (
                <React.Fragment>
                  <div className="mt-2 mb-2">
                    <span className="sans-bold">
                      {t("Recompensas.TUS-CODIGOS")}
                    </span>
                  </div>
                  {this.state.success && (
                    <div className="clipboard d-flex sans-bold justify-content-center align-items-center align-self-center bg-success text-white mt-2 mb-2">
                      <span>{t("Recompensas.Clipboard")}</span>
                    </div>
                  )}
                  <div className="mis-codigos mt-3">
                    {this.state.vouchers.map((item, idx) => (
                      <div
                        className="d-flex justify-content-between align-items-center"
                        key={idx}
                      >
                        <img
                          className="codigos"
                          src={item.image}
                          alt="Codigos"
                        />

                        <div className="code">
                          <div className="ml-2">{item.code}</div>
                          <div className="text-secondary ml-2">
                            {item.end_datetime.substring(0, 10)}
                          </div>
                        </div>

                        <button
                          onClick={() => this.copyToClipboard(item.code)}
                          className="btn btn-primary rounded-pill ml-2 mr-2"
                        >
                          {t("Recompensas.Copiar")}
                        </button>
                      </div>
                    ))}
                  </div>

                  <p className="mt-4 mb-4">{t("Recompensas.Canjealo")}</p>
                </React.Fragment>
              ) : (
                <div className="d-flex flex-column mt-4 mb-5">
                  <span className="text-danger mb-2">
                    {t("Recompensas.No-codes-1")}
                  </span>
                  <span>{t("Recompensas.No-codes-2")}</span>
                </div>
              )}
            </div>
            <div className="mb-5">
              <a
                className="btn rewards-buttons btn-info rounded-pill ml-2"
                href={config.apiUrlHD + "/addresses/"}
              >
                {t("Recompensas.Hacer-pedido")}
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  render() {
    const firstRewards = this.state.firstRewards;
    const secondRewards = this.state.secondRewards;
    const thirdRewards = this.state.thirdRewards;
    const { t } = this.props;
    return (
      <>
      <Helmet>
        <title>Mis Recompensas - Popeyes Louisiana Kitchen</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="title bg-primary d-flex justify-content-center align-items-center w-100">
            <div>
              <h1 className="text-white title-recompensas mt-4">
                {t("Recompensas.Recompensas")}
              </h1>
            </div>
          </div>
          {!authenticationService.currentUserValue ? (
            <div className="card border-0 w-100">
              <div
                role="button"
                onClick={this.linkSelect}
                className="stretched-link"
              >
                <img className="w-100" src={banner} alt="Mis recompensas" />
              </div>
            </div>
          ) : (
            <div className="card border-0 w-100">
              <div className="card-body card-background">
                <div className="card-title">
                  <h3 className="sans-bold text-info text-center">
                    {t("Recompensas.Estrellas-acumulada")}
                  </h3>
                  <p className="text-center">
                    {t("Recompensas.Tienes")} {this.state.filledStars}{" "}
                    {t("Recompensas.Estrellas")}
                  </p>
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <div className="col-md-12 align-self-center">
                    <div className="row d-flex flex-wrap justify-content-center mt-4">
                      <div className=" parent">
                        <span id="filled" />

                        <span id="unfilled" />
                      </div>
                      <div className="d-flex flex-column">
                        <img
                          className={
                            this.state.filledStars < 3
                              ? "rewards-grayscale"
                              : "rewards"
                          }
                          src={firstRewards}
                          alt="Rewards"
                        />
                        <img
                          className={
                            this.state.filledStars < 6
                              ? "rewards-grayscale"
                              : "rewards"
                          }
                          src={secondRewards}
                          alt="Rewards"
                        />
                        <img
                          className={
                            this.state.filledStars < 9
                              ? "rewards-grayscale"
                              : "rewards"
                          }
                          src={thirdRewards}
                          alt="Rewards"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 align-self-center">
                    <div className="d-flex justify-content-center align-items-center mt-5 mb-5 recompensas-buttons">
                      <a
                        className="btn btn-info rewards-buttons rounded-pill"
                        href={config.apiUrlHD + "/addresses/"}
                      >
                        {t("Recompensas.Hacer-pedido")}
                      </a>
                      <button
                        onClick={() => this.handleModal()}
                        className="btn btn-primary rewards-buttons rounded-pill"
                      >
                        {t("Recompensas.Ver-mis-codigos")}
                      </button>
                    </div>
                    {this.Codigos()}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="card w-100 border-0">
            <div className="card-body">
              <h2 className="text-center text-primary mt-3 mb-4 sans-bold">
                {t("Recompensas.Mis-estrellas")}
              </h2>
              <div className="row d-flex">
                <div className="col-md-6 mt-4">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-center mb-4 one">
                      <img
                        className="numbers align-self-center mb-2"
                        src={one}
                        alt="One"
                      />
                      <h2 className="text-success text-font-chicken ml-3 text-size mt-2">
                        <b>{t("Recompensas.Acumula-estrellas")}</b>
                      </h2>
                    </div>
                    <div className="justify-content-center d-flex">
                      <div className="col-md-7">
                        <p className="text-center">
                          {t("Recompensas.Estrella-Popeyes")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-center mb-3 two">
                      <img
                        className="numbers align-self-center mb-2"
                        src={two}
                        alt="Two"
                      />
                      <div className="col-md-7 col-ls-12 d-flex align-items-center justify-content-center">
                        <h2 className="text-success ml-3 text-size obten-producto">
                          <b className="text-font-chicken">
                            {t("Recompensas.Obten")}
                          </b>
                          <b className="text-primary ml-2">
                            {t("Recompensas.GRATIS")}
                          </b>
                        </h2>
                      </div>
                    </div>
                    <div className="justify-content-center d-flex">
                      <div className="col-md-8">
                        <p className="text-center">
                          {t("Recompensas.Acumula-3-estrellas")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Mis recompensas legals */}

          <div className="card w-100 border-0">
            <div className="card-body card-background">
              <div className="d-flex justify-content-between align-items-center ml-5 mr-5 mt-3">
                <div>{t("Recompensas.Condiciones-generales")}</div>
                <div className="align-items-center mb-2">
                  <div
                    onClick={() => this.handleShowCondiciones()}
                    role="button"
                  >
                    {!this.state.showCondiciones ? (
                      <FontAwesomeIcon
                        className="text-primary"
                        size="lg"
                        icon={faPlus}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="text-primary"
                        size="lg"
                        icon={faMinus}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="border-dark border-bottom mt-4 mb-2 ml-4 mr-4"></div>
              {this.state.showCondiciones && (
                <React.Fragment>
                  <div className="ml-5 mr-5">
                    <p className="text-primary mb-3 sans-bold">
                    {t("RecompensasLegals.Condiciones-Legales")}
                      
                    </p>
                    <p>
                    {t("RecompensasLegals.Fidelización-denominado")}{" "}
                    <b>{t("RecompensasLegals.MIS-RECOMPENSAS")}</b>.{" "}
                    {t("RecompensasLegals.Empresa-responsable")}
                    </p>
                    <Link
                      className="text-primary"
                      to="/condiciones-recompensas"
                    >
                      {t("Contact.More-information")}
                    </Link>
                  </div>
                  <div className="border-dark border-bottom mt-4 ml-4 mr-4"></div>
                </React.Fragment>
              )}
            </div>
          </div>

          <MobileBanner />
        </div>
      </div>
      </>
    );
  }
}

export default withTranslation()(Recompensas);
