import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { orderService } from "../../services/order.service";
import config from "../../config/config";
import { withTranslation } from "react-i18next";
import "./OngoingOrderBar.scss";

class OngoingOrderBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: [],
    };
  }

  componentDidMount = () => {
    orderService.getOngoingOrder().then((response) => {
      this.setState({ order: response.order });
      if(response.order && response.order.length !== 0){
        this.props.handleOrderBar();
      }
    });
  };


  render() {
    const {t} = this.props
    return (
      <React.Fragment>
        {this.state.order && this.state.order.length !== 0 && (
          <div className="col-lg-12 bg-success order-bar d-flex justify-content-between align-items-center mb-1">
            <span className="ml-4 text-white sans-bold">{t("OrderBar.En-camino")}</span>
            <a
              className="text-decoration-none text-dark"
              href={config.apiUrlHD+`/orders/${this.state.order.id}/tracking`}
            >
                <span className="text-white">{t("OrderBar.Ver-pedido")}</span>
              <FontAwesomeIcon className="ml-3 mr-4" icon={faChevronRight} />
            </a>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(OngoingOrderBar);
