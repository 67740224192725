import React, { Component } from "react";
import { authenticationService } from "../../services/authentication.service";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import "./LoyaltyPopup.scss";
import loyalty from "../../assets/img/loyalty/loyalty.png";

class LoyaltyPopup extends Component {
  constructor(props) {
    super(props);
    console.log(
      "{LoyaltyPopup.js}[constructor](13) this.props.show",
      this.props
    );
    this.state = {
      showModal: this.props.showModal ?? false,
      closeAll: this.props.closeAll ?? false,
      news: this.props.news,
      isNewsAccepted: false,
    };
    this.accept = this.accept.bind(this);
  }
  componentDidMount = () => {};
  accept = () => {
    authenticationService
      .acceptLoyaltyChanges(this.state.isNewsAccepted)
      .then(() => {
        this.props.acceptLoyaltyCallback();
      });
    this.setState({ showModal: false });
  };
  modalPrivacyQuestionContent = () => {
    const { t } = this.props;
    return (
      <Modal
        className="text-center"
        centered
        size="lg"
        show={this.state.showModal}
        onHide={() => {}}
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center some-space">
            <form>
              <p className="sans-bold text-center">
                <img className="w-25" src={loyalty} alt="Aviso importnae " />
              </p>
              <p className="sans-bold text-center">
                {t("LoyaltyPopup.Important")}
              </p>
              <div className=" d-flex flex-wrap">
                <span className={"text-left"}>{t("LoyaltyPopup.Content")}</span>
              </div>
              {!this.props.news && (
                <div key={"isNewsAccepted"} className="line mt-3 d-flex justify-content-start">
                  <div className="custom-control custom-switch   ">
                    <input
                      onChange={() => {
                        this.setState({
                          isNewsAccepted: !this.state.isNewsAccepted,
                        });
                      }}
                      className="custom-control-input"
                      type="checkbox"
                      value={this.state.isNewsAccepted}
                      id={"isNewsAccepted"}
                    />
                    <label
                      className="custom-control-label mr-1 text-left"
                      htmlFor={"isNewsAccepted"}
                    ></label>
                  </div>
                  <label className={"text-left"}>
                    {t("LoyaltyPopup.Communications")}
                  </label>
                </div>
              )}
              <div className="d-flex mt-5 justify-content-center">
                <button
                  className="btn btn-primary rounded-pill w-50"
                  onClick={this.accept}
                >
                  {t("LoyaltyPopup.Accept")}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  render() {
    return (
      <React.Fragment>
        {this.state.showModal && this.modalPrivacyQuestionContent()}
      </React.Fragment>
    );
  }
}
export default withTranslation()(LoyaltyPopup);
