import config from "../config/config";
import { authenticationService } from "./authentication.service";

export const popupService = {
  getPopup,
};

function getPopup() {
  const requestOptions = {
    method: "GET",
    headers: authenticationService.prepareHeader(),
  };

  return fetch(
    `${config.apiUrl}/api/v1/popups?language=${config.language}&key=${config.key}&tags=WEB`,
    requestOptions
  ).then(authenticationService.handleResponse);
}
