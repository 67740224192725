import React             from "react";
import {Helmet}                          from "react-helmet";
import {useTranslation, withTranslation} from "react-i18next";

import popoyes             from "../../assets/img/svgs/popoyetitle.svg";


import "./alergens.scss";


const  Alergens  =()=>{
const {t} = useTranslation()
    return (
      <>
        <Helmet>
          <title>{t("Allergen.Allergen") + ' ' + t("Allergen.Nutritional")}</title>
        </Helmet>
        <div className="container-fluid alergens">
          <div className="row align-items-center justify-content-center">

        <div  className="row height">
          <div  className="column">
            <div  className="row space">
              <img className="img-fluid" src={popoyes} alt="Logout" height="138" width ="181"  />

            </div>
            <div  className="row">
              <div  className="button ">
                      <a  className="text  "  href="https://popeyes-prod.s3.eu-west-1.amazonaws.com/Carta_alergenos_Ed_00_Octubre_2021.pdf">
                        {t("Allergen.Allergen").toUpperCase()}
                              </a>
              </div>
              <div  className="button">
                 <a className="text" href="https://popeyes-prod.s3.eu-west-1.amazonaws.com/Nutricional_alergenos_Ed_00_Octubre_2021.pdf">
                 {t("Allergen.Nutritional").toUpperCase()}
                </a>
              </div>
            </div>
          </div>
        </div>

          </div>
        </div>
      </>
    );
}

export default withTranslation()(Alergens);
