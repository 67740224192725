import React, { Component } from "react";
import Category from "./Category/Category";
import Carta from "./Catalog/Carta";
import {Helmet} from "react-helmet";

class CatalogShowcase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categorySelected: "",
    };
  }

  handleShowCatalog = (id) => {
    this.setState({ categorySelected: id });
    this.props.handleShowCategory();
  };

  render() {
    return (
      <>
      <Helmet>
        <title>Carta - Popeyes Louisiana Kitchen</title>
      </Helmet>
      <div>
        {this.props.showCategory ? (
          <Category handleShowCatalog={this.handleShowCatalog} />
        ) : (
          <Carta
            orderMenuHeight={this.props.orderMenuHeight}
            categorySelected={this.state.categorySelected}
          />
        )}
      </div>
      </>
    );
  }
}

export default CatalogShowcase;
