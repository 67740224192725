import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {ScreenName} from "../../analytics/analytics.config";
import {analyticsEvents} from "../../analytics/Analytics";
import {Helmet} from "react-helmet";
import "./Privacy.scss";

class Privacy extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    analyticsEvents.logScreenView(ScreenName.PRIVACY_POLICY)
  };
  render() {
    const { t,i18n } = this.props;
    return (
      <>
      <Helmet>
        <title>Politica de Privacidad - Popeyes Louisiana Kitchen</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center">
          <div className="w-100 title col-md-12 col-ls-12 bg-primary d-flex justify-content-center align-items-center">
            <div>
              <h1 className="text-white title-privacy mt-4">
                {t("Privacidad.Privacidad")}
              </h1>
            </div>
          </div>
          <div className="col-md-12 p-3">
            <div className="card text-left mt-4 mb-4 border-0">
              <div className="card-text">
                <span>
                  {t("Privacidad.Privacidad-subtitle-paragraf-part-1")}{" "}
                  <a
                    href="https://app.vlex.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Privacidad.Regulamento-UE")}
                  </a>{" "}
                  {t("Privacidad.Privacidad-subtitle-paragraf-part-2")}{" "}
                  <a
                    href="https://www.boe.es/buscar/act.php?id=BOE-A-2018-16673"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Privacidad.Ley-Orgánica")}
                  </a>{" "}
                  {t("Privacidad.Privacidad-subtitle-paragraf-part-3")}
                </span>
                <br />
                <br />
                <br />
                <h4 className="text-primary sans-bold">
                  {t("Privacidad.Protección-de-datos")}
                </h4>
                <br />
                <span>
                  {t("Privacidad.Protección-de-datos-paragraf-1-part-1")}{" "}
                  <a
                    href="https://www.popeyes.es"
                    target="_blank"
                    rel="noreferrer"
                  >
                     {t("Privacidad.Protección-de-datos-paragraf-1-part-1-link")}
                  </a>
                  {" "}
                  {t("Privacidad.Protección-de-datos-paragraf-1-part-2")}
                </span>
                <br />
                <br />
                <span>
                  {t("Privacidad.Protección-de-datos-paragraf-2-part-1")}{" "}
                  <a
                    href="https://app.vlex.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Privacidad.Regulamento-UE")}
                  </a>{" "}
                  {t("Privacidad.Protección-de-datos-paragraf-2-part-2")}{" "}
                  <a
                    href="https://www.boe.es/buscar/act.php?id=BOE-A-2018-16673"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Privacidad.Ley-Orgánica")}
                  </a>{" "}
                  {t("Privacidad.Protección-de-datos-paragraf-2-part-3")}{" "}
                  <a
                    href="https://app.vlex.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Privacidad.Ley")}
                  </a>{" "}
                  {t("Privacidad.Protección-de-datos-paragraf-2-part-4")}
                </span>
                <br />
                <br />
                <span>{t("Privacidad.Protección-de-datos-paragraf-3")}</span>
                <br />
                <br />
                <br />
                <h4>
                  <b className="text-primary">
                    {t("Privacidad.Responsable-del-tratamiento")}
                  </b>
                </h4>
                <br />
                <span>
                  {t("Privacidad.Responsable-del-tratamiento-paragraf-1")}{" "}
                </span>
                <br />
                <br />
                <span>
                  {t("Privacidad.Responsable-del-tratamiento-paragraf-2")}
                </span>
                <br />
                <br />
                <h4 className="text-primary sans-bold">
                  {t("Privacidad.Delegado-proteccion")}
                </h4>
                <br />
                <span>{t("Privacidad.DPD")}</span>
                <br />
                <span>{t("Privacidad.Datos-de-contacto")}</span>
                <br />
                <span>
                  {t("Privacidad.Medio-de-contacto")}{" "}
                  <a
                    href="https://popeyes-canaletico.appcore.es/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://popeyes-canaletico.appcore.es/
                  </a>
                </span>
                <br/>
                <br/>
                <h4>
                  <b className="text-primary">
                    {t("Privacidad.Duración-del-almacenamiento")}
                  </b>
                </h4>
                <br />
                <span>
                  {t("Privacidad.Duración-del-almacenamiento-paragraf-1")}
                </span>
                <br />
                <br />
                <span>
                  {t("Privacidad.Duración-del-almacenamiento-paragraf-2")}
                </span>
                <br />
                <br />
                <span>
                  {t("Privacidad.Duración-del-almacenamiento-paragraf-3")}
                </span>
                <br />
                <br />
                <span>
                  {t("Privacidad.Duración-del-almacenamiento-paragraf-4")}
                </span>
                <br />
                <br />
                <span>
                  {t("Privacidad.Duración-del-almacenamiento-paragraf-5")}
                </span>
                <br />
                <br />
                <span>
                  {t("Privacidad.Duración-del-almacenamiento-paragraf-6")}
                </span>
                <br />
                <br />
                <span>
                  {t("Privacidad.Duración-del-almacenamiento-paragraf-7")}
                </span>
                <br />
                <br />
                <br />
                <h4>
                  <b className="text-primary">
                    {t("Privacidad.Finalidades-del-tratamiento")}
                  </b>
                </h4>
                <br />
                <span>
                  {t("Privacidad.Finalidades-del-tratamiento-paragraf-1")}
                </span>
                <br />
                <br />
                <span>
                  {t(
                    "Privacidad.Finalidades-del-tratamiento-paragraf-2-part-1"
                  )}{" "}
                  <a
                    href="https://www.popeyes.es"
                    target="_blank"
                    rel="noreferrer"
                  >
                     {t(
                       "Privacidad.Finalidades-del-tratamiento-paragraf-2-part-1-link"
                     )}
                  </a>{" "}
                  {t(
                    "Privacidad.Finalidades-del-tratamiento-paragraf-2-part-2"
                  )}
                </span>
                <br />
                <br />
                <span>
                  {t("Privacidad.Finalidades-del-tratamiento-paragraf-3")}
                </span>
                <br />
                <br />
                <span>
                  {t("Privacidad.Finalidades-del-tratamiento-paragraf-4")}
                </span>
                <br />
                <br />
                <br />
                <h4>
                  <b className="text-primary">{t("Privacidad.Legitimación")}</b>
                </h4>
                <br />
                <span>{t("Privacidad.Legitimación-paragraf-1")}</span>
                <br />
                <br />
                <ul>
                  <li>{t("Privacidad.Legitimación-paragraf-2")}</li>
                  <br />
                  <li>{t("Privacidad.Legitimación-paragraf-3")}</li>
                  <br />
                  <li>{t("Privacidad.Legitimación-paragraf-4")}</li>
                  <br />
                  <li>{t("Privacidad.Legitimación-paragraf-5")}</li>
                  <br />
                  <li>{t("Privacidad.Legitimación-paragraf-6")}</li>
                </ul>
                <br />
                <span>{t("Privacidad.Legitimación-paragraf-7")}</span>
                <br />
                <br />
                <span>{t("Privacidad.Legitimación-paragraf-8")}</span>
                <br />
                <br />
                <span>{t("Privacidad.Legitimación-paragraf-9")}</span>
                <br />
                <br />
                <br />
                <h4>
                  <b className="text-primary">{t("Privacidad.Comunicación")}</b>
                </h4>
                <br />
                <span>{t("Privacidad.Comunicación-paragraf-1")}</span>
                <br />
                <br />
                <span>{t("Privacidad.Comunicación-paragraf-2")}</span>
                <br />
                <br />
                <span>{t("Privacidad.Comunicación-paragraf-2A")}</span>
                <br />
                <br />

                {i18n.exists("Privacidad.Comunicación-Google-Analytics_pre")&&
                  <>
                    <span className="sans-bold">{t("Privacidad.Comunicación-Google-Analytics_pre")}</span>
                    <br />
                    <br />
                  </>
                }
                {i18n.exists("Privacidad.Comunicación-GA-paragraf-1_pre")&&
                  <>
                    <span>{t("Privacidad.Comunicación-GA-paragraf-1_pre")}</span>
                    <br />
                    <br />
                  </>
                }
                {i18n.exists("Privacidad.Comunicación-GA-paragraf-1-part-1_pre")&&
                  <>
                    <span>{t("Privacidad.Comunicación-GA-paragraf-1-part-1_pre")}</span>
                    <br />
                    <br />
                  </>
                }
                <span className="sans-bold">{t("Privacidad.Comunicación-Google-Analytics")}</span>
                <br />
                <br />

                <span>{t("Privacidad.Comunicación-GA-paragraf-1")}</span>
                <br />
                <br />
                {i18n.exists("Privacidad.Comunicación-GA-paragraf-1-part-1")&&
                  <>
                    <span>{t("Privacidad.Comunicación-GA-paragraf-1-part-1")}</span>
                    <br />
                    <br />
                  </>
                }
                <span>{t("Privacidad.Comunicación-GA-paragraf-2")}</span>
                <br />
                <br />
                <span>{t("Privacidad.Comunicación-GA-paragraf-3")}</span>
                <br />
                <br />
                <span>
                  {t("Privacidad.Comunicación-GA-paragraf-4")}
                  <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noreferrer">
                    www.google.com/policies/privacy/
                  </a>
                </span>
                <br />
                <br />
                <span className="sans-bold">{t("Privacidad.Comunicación-Profiling-Options")}</span>
                <br />
                <br />
                <span >{t("Privacidad.Comunicación-PO-paragraf-1")}</span>
                <br />
                <br />
                <span className="sans-bold">{t("Privacidad.Comunicación-paragraf-3")}</span>
                <br />
                <br />
                <span>
                  {t("Privacidad.Comunicación-paragraf-8")}{" "}
                </span>
                <br />
                <br />
                <span>{t("Privacidad.DPD")}</span>
                <br />
                <span>{t("Privacidad.Datos-de-contacto")}</span>
                <br />
                <span>
                  {t("Privacidad.Medio-de-contacto")}{" "}
                  <a
                    href="https://popeyes-canaletico.appcore.es/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://popeyes-canaletico.appcore.es/
                  </a>
                </span>
                <br />
                <br />
                <span>{t("Privacidad.Comunicación-paragraf-10")}</span>
                <br />
                <br />
                <ul>
                  <li>{t("Privacidad.Comunicación-paragraf-11")}</li>
                  <br />
                  <li>{t("Privacidad.Comunicación-paragraf-12")}</li>
                  <br />
                  <li>{t("Privacidad.Comunicación-paragraf-13")}</li>
                  <br />
                  <li>{t("Privacidad.Comunicación-paragraf-14")}</li>
                  <br />
                  <li>{t("Privacidad.Comunicación-paragraf-15")}</li>
                  <br />
                  <li>{t("Privacidad.Comunicación-paragraf-16")}</li>
                  <br />
                  <li>{t("Privacidad.Comunicación-paragraf-17")}</li>
                </ul>
                <br />
                <span>{t("Privacidad.Comunicación-paragraf-18")}</span>
                <br />
                <br />
                <span>{t("Privacidad.Comunicación-paragraf-19")}</span>
                <br />
                <br />
                <span>
                  {t("Privacidad.Comunicación-paragraf-20")}{" "}
                  <a
                    href="https://www.agpd.es"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.agpd.es
                  </a>{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default withTranslation()(Privacy);
