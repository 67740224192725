import React, { Component } from "react";
import config from "../../config/config";
import { Link, NavLink } from "react-router-dom";
import { authenticationService } from "../../services/authentication.service";
import { userService } from "../../services/user.service";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import chicken from "../../assets/img/icons/chicken.png";
import userIcon from "../../assets/img/icons/profile-user.svg";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import "./NavBar.scss";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCollapsedMenu: false,
      dropdownShow: false,
      open: false,
      name: "",
      myLng: window.localStorage.getItem("i18nextLng"),
    };
  }

  componentDidMount = () => {
    authenticationService.currentUserValue &&
      userService.getUser().then((response) => {
        this.setState({ name: response.data.firstName });
      });
  };

  handleModal = () => {
    this.setState({ open: !this.state.open });
  };

  toggleMenu = () => {
    this.setState({
      showCollapsedMenu: !this.state.showCollapsedMenu,
    });
  };

  closeMenu = () => {
    this.setState({
      showCollapsedMenu: false,
    });
  };

  loginRoute = () => {
    <Link to="/login" />;
  };

  chnageLanguage = (lng) => {
    this.setState({ myLng: lng });
    i18n.changeLanguage(lng);
  };

  render() {
    const { t } = this.props;
    const show = this.state.showCollapsedMenu ? "show" : "";
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="d-flex justify-content-center">
            <button
              onClick={this.toggleMenu}
              className="navbar-toggler border-0 mt-3"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={show}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <a className="navbar-brand mt-2" href="/">
              <i aria-hidden="true"></i>
            </a>
          </div>
          <ul className="navbar-nav">
            {authenticationService.currentUserValue ? (
              <React.Fragment>
                <Dropdown className="d-lg-none">
                  <Dropdown.Toggle
                    className="bg-transparent border-0 shadow-none dropdown-size"
                    id="dropdown"
                    drop="start"
                  >
                    <img
                      className="user-size"
                      src={userIcon}
                      alt="User profile"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu">
                    <div className="d-flex justify-content-center">
                      <h4 className="text-font-chicken mt-2 mb-2 text-info">
                        {t("Header.Hola")} {this.state.name}!
                      </h4>
                    </div>
                    <Dropdown.Item
                      className="mt-3 d-flex justify-content-between"
                      href={config.apiUrlHD + "/payment-methods/"}
                    >
                      <span className="dropdown-text-size">
                        {t("Header.Metodos")}
                      </span>
                      <div>
                        <FontAwesomeIcon
                          className="text-primary"
                          icon={faChevronRight}
                        />
                      </div>
                    </Dropdown.Item>
                    <div className="border-bottom mt-3"></div>
                    <Dropdown.Item
                      className="mt-3 d-flex justify-content-between"
                      href={config.apiUrlHD + "/addresses/"}
                    >
                      <span className="dropdown-text-size">
                        {t("Header.Direcciones")}
                      </span>
                      <div>
                        <FontAwesomeIcon
                          className="text-primary"
                          icon={faChevronRight}
                        />
                      </div>
                    </Dropdown.Item>
                    <div className="border-bottom mt-3"></div>
                    <Dropdown.Item
                      className="mt-3 d-flex justify-content-between"
                      href="/personal-data"
                    >
                      <span className="dropdown-text-size">
                        {t("Header.Información-personal")}
                      </span>
                      <span>
                        <FontAwesomeIcon
                          className="text-primary"
                          icon={faChevronRight}
                        />
                      </span>
                    </Dropdown.Item>
                    <div className="border-bottom mt-3"></div>
                    {/*<Dropdown.Item*/}
                    {/*  className="mt-3 d-flex justify-content-between"*/}
                    {/*  href="/mis-recompensas"*/}
                    {/*>*/}
                    {/*  <span className="dropdown-text-size">*/}
                    {/*    {t("Header.Mis-recompensas-dropdown")}*/}
                    {/*  </span>*/}
                    {/*  <div>*/}
                    {/*    <FontAwesomeIcon*/}
                    {/*      className="text-primary"*/}
                    {/*      icon={faChevronRight}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</Dropdown.Item>*/}

                    <div className="border-bottom mt-3"></div>
                    <Dropdown.Item
                      className="mt-3 d-flex justify-content-between"
                      href={config.apiUrlHD + "/orders/"}
                    >
                      <span className="dropdown-text-size">
                        {t("Header.Historico")}
                      </span>
                      <div>
                        <FontAwesomeIcon
                          className="text-primary"
                          icon={faChevronRight}
                        />
                      </div>
                    </Dropdown.Item>
                    <div className="border-bottom mt-3"></div>
                    <Dropdown.Item
                      className="mt-3 mb-3 d-flex justify-content-between"
                      href="/logout"
                    >
                      <span className="dropdown-text-size">
                        {t("Header.Cerrar-sesión")}
                      </span>
                      <div>
                        <FontAwesomeIcon
                          className="text-primary"
                          icon={faChevronRight}
                        />
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Link
                  to="/login"
                  className="d-lg-none btn btn-xs btn-outline-primary rounded-pill"
                >
                  <span className="font_size">
                    {t("Header.Iniciar-sesión")}
                  </span>
                </Link>
              </React.Fragment>
            )}
          </ul>

          <div
            className={"collapse navbar-collapse " + show}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav bg-white ml-auto mt-3 d-flex justify-content-center">
              <li className="nav-item" onClick={this.closeMenu}>
                {!authenticationService.currentUserValue ? (
                  <NavLink
                    className="pop_nav_link text-decoration-none mr-4"
                    onClick={this.props.handleShowCategory}
                    to="/carta"
                  >
                    <span>{t("Header.Carta")}</span>
                  </NavLink>
                ) : (
                  <a
                    className="pop_nav_link text-decoration-none mr-4"
                    href={config.homeDeliveryUrl}
                  >
                    <span>{t("Header.Carta")}</span>
                  </a>
                )}
              </li>
              <li className="nav-item" onClick={this.closeMenu}>
                <NavLink
                  className="pop_nav_link text-decoration-none mr-4"
                  to="/novedades"
                >
                  {t("Header.Novedades")}
                </NavLink>
              </li>
              <li className="nav-item" onClick={this.closeMenu}>
                <NavLink
                  className="pop_nav_link text-decoration-none mr-4"
                  to="/historia"
                >
                  {t("Header.Historia")}
                </NavLink>
              </li>
              <li className="nav-item" onClick={this.closeMenu}>
                <NavLink
                  className="pop_nav_link text-decoration-none mr-4"
                  to="/restaurantes"
                >
                  {t("Header.Restaurantes")}
                </NavLink>
              </li>
              <li className="nav-item" onClick={this.closeMenu}>
                <NavLink
                  className="pop_nav_link text-decoration-none"
                  to="/social-commitment"
                >
                  {t("Header.Social-Commitment")}
                </NavLink>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto mt-1">
              <li className="nav-item d-lg-none">
                <button
                  onClick={() => this.chnageLanguage("es")}
                  className="pop_nav_link_language text-primary border-0 bg-transparent"
                >
                  <span
                    className={
                      this.state.myLng === "es" ? "lng_underline" : null
                    }
                  >
                    ES
                  </span>
                </button>
                <button
                  onClick={() => this.chnageLanguage("en")}
                  className="pop_nav_link_language text-primary border-0 bg-transparent"
                >
                  <span
                    className={
                      this.state.myLng === "en" ? "lng_underline" : null
                    }
                  >
                    EN
                  </span>
                </button>
                <button
                  onClick={() => this.chnageLanguage("cat")}
                  className="pop_nav_link_language text-primary border-0 bg-transparent"
                >
                  <span
                    className={
                      this.state.myLng === "cat" ? "lng_underline" : null
                    }
                  >
                    CAT
                  </span>
                </button>
              </li>
            </ul>
          </div>
          {authenticationService.currentUserValue ? (
            <React.Fragment>
              <a
                href={
                  !authenticationService.currentUserValue
                    ? "/login"
                    : config.apiUrlHD + "/addresses/"
                }
                className="navbar-buttons d-none d-lg-block d-lg-none btn btn-xs btn-info rounded-pill mt-3"
              >
                <img className="chicken" src={chicken} alt="Hacer pedido" />{" "}
                <span className="chicken-font-style">
                  {t("Header.Hacer-pedido")}
                </span>
              </a>
              <Dropdown className="d-none d-lg-block mt-3">
                <Dropdown.Toggle
                  className="bg-transparent border-0 shadow-none mr-3"
                  id="dropdown"
                >
                  <img
                    className="user-size"
                    src={userIcon}
                    alt="User profile"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu">
                  <div className="d-flex justify-content-center">
                    <h4 className="text-font-chicken mt-2 mb-2 text-info">
                      {t("Header.Hola")} {this.state.name}!
                    </h4>
                  </div>
                  <Dropdown.Item
                    className="mt-3 d-flex justify-content-between"
                    href={config.apiUrlHD + "/payment-methods/"}
                  >
                    <span className="dropdown-text-size">
                      {t("Header.Metodos")}
                    </span>
                    <div>
                      <FontAwesomeIcon
                        className="text-primary"
                        icon={faChevronRight}
                      />
                    </div>
                  </Dropdown.Item>
                  <div className="border-bottom mt-3"></div>
                  <Dropdown.Item
                    className="mt-3 d-flex justify-content-between"
                    href={config.apiUrlHD + "/addresses/"}
                  >
                    <span className="dropdown-text-size">
                      {t("Header.Direcciones")}
                    </span>
                    <div>
                      <FontAwesomeIcon
                        className="text-primary"
                        icon={faChevronRight}
                      />
                    </div>
                  </Dropdown.Item>
                  <div className="border-bottom mt-3"></div>
                  <Dropdown.Item
                    className="mt-3 d-flex justify-content-between"
                    href="/personal-data"
                  >
                    <span className="dropdown-text-size">
                      {t("Header.Información-personal")}
                    </span>
                    <span>
                      <FontAwesomeIcon
                        className="text-primary"
                        icon={faChevronRight}
                      />
                    </span>
                  </Dropdown.Item>
                  <div className="border-bottom mt-3"></div>
                  {/*<Dropdown.Item*/}
                  {/*  className="mt-3 d-flex justify-content-between"*/}
                  {/*  href="/mis-recompensas"*/}
                  {/*>*/}
                  {/*  <span className="dropdown-text-size">*/}
                  {/*    {t("Header.Mis-recompensas-dropdown")}*/}
                  {/*  </span>*/}
                  {/*  <div>*/}
                  {/*    <FontAwesomeIcon*/}
                  {/*      className="text-primary"*/}
                  {/*      icon={faChevronRight}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</Dropdown.Item>*/}
                  {/*<div className="border-bottom mt-3"></div>*/}
                  <Dropdown.Item
                    className="mt-3 d-flex justify-content-between"
                    href={config.apiUrlHD + "/orders/"}
                  >
                    <span className="dropdown-text-size">
                      {t("Header.Historico")}
                    </span>
                    <div>
                      <FontAwesomeIcon
                        className="text-primary"
                        icon={faChevronRight}
                      />
                    </div>
                  </Dropdown.Item>
                  <div className="border-bottom mt-3"></div>
                  <Dropdown.Item
                    className="mt-3 mb-3 d-flex justify-content-between"
                    href="/logout"
                  >
                    <span className="dropdown-text-size">
                      {t("Header.Cerrar-sesión")}
                    </span>
                    <div>
                      <FontAwesomeIcon
                        className="text-primary"
                        icon={faChevronRight}
                      />
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <a
                href={
                  !authenticationService.currentUserValue
                    ? "/login"
                    : config.apiUrlHD + "/addresses/"
                }
                className="navbar-buttons d-none d-lg-block d-lg-none btn btn-xs btn-info rounded-pill mt-3"
              >
                <img className="chicken" src={chicken} alt="Hacer pedido" />{" "}
                <span className="chicken-font-style">
                  {t("Header.Hacer-pedido")}
                </span>
              </a>
              <Link
                to="/login"
                className="navbar-buttons d-none d-lg-block btn btn-xs btn-outline-primary rounded-pill ml-3 mr-4  mt-3"
              >
                <span className="font_size">{t("Header.Iniciar-sesión")}</span>
              </Link>
            </React.Fragment>
          )}
          <div className="d-none d-lg-block d-lg-none translation">
            <button
              onClick={() => this.chnageLanguage("es")}
              className="pop_nav_link_language text-primary border-0 bg-transparent"
            >
              <span
                className={this.state.myLng === "es" ? "lng_underline" : null}
              >
                ES
              </span>
            </button>
            <button
              onClick={() => this.chnageLanguage("en")}
              className="pop_nav_link_language text-primary border-0 bg-transparent"
            >
              <span
                className={this.state.myLng === "en" ? "lng_underline" : null}
              >
                EN
              </span>
            </button>
            <button
              onClick={() => this.chnageLanguage("cat")}
              className="pop_nav_link_language text-primary border-0 bg-transparent"
            >
              <span
                className={this.state.myLng === "cat" ? "lng_underline" : null}
              >
                CAT
              </span>
            </button>
          </div>
        </nav>

        <div className="col-ls-12 hacer-pedido justify-content-center d-flex">
          <a
            href={
              !authenticationService.currentUserValue
                ? "/login"
                : config.apiUrlHD + "/addresses/"
            }
            className="d-lg-none btn btn-info rounded-pill hacer-pedido-button mb-2 mt-2"
          >
            <img className="chicken" src={chicken} alt="Hacer pedido" />{" "}
            <span className="chicken-font-style">
              {t("Header.Hacer-pedido")}
            </span>
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(NavBar);
