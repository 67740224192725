import config from "../config/config";
import { authenticationService } from "./authentication.service";

export const menuService = {
  getProductDetails,
  getCollections
};



function getCollections() {
  const requestOptions = {
    method: "GET",
    headers: authenticationService.prepareHeader(),
  };

  return fetch(
    `${config.apiUrl}/api/v1/sections/collections?language=${config.language}&key=${config.key}`,
    requestOptions
  ).then(authenticationService.handleResponse);
}

function getProductDetails(id) {
  const requestOptions = {
    method: "GET",
    headers: authenticationService.prepareHeader(),
  };

  return fetch(
    `${config.apiUrl}/api/v1/products/${id}?language=${config.language}&key=${config.key}`,
    requestOptions
  ).then(authenticationService.handleResponse);
}