import React, { Component } from "react";
import { Link } from "react-router-dom";
import MobileBanner from "../MobileBanner/MobileBanner";
import { withTranslation } from "react-i18next";
import {ScreenName} from "../../analytics/analytics.config";
import {analyticsEvents} from "../../analytics/Analytics";
import "./NotFound.scss";

class NotFound extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    analyticsEvents.logScreenView(ScreenName.NOT_FOUND)
  };
  render() {
    const {t} = this.props;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="not_found">
            <div className="card border-0">
              <div className="card-body not_found_body d-flex text-center justify-content-center align-items-center">
                <div className="col-md-6">
                  <div className="card-title text-primary mt-5">
                    <h1 className="not_found_404 sans-bold">
                      404
                    </h1>
                    <h1 className="sans-bold">
                      {t("404.Not-found")}
                    </h1>
                  </div>
                  <div className="mt-5">
                    <h4 className="mb-4 sans-bold">
                      {t("404.Lost")}
                    </h4>
                    <Link to="/" className="btn btn-primary rounded-pill">
                    {t("404.Home")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-ls-12 w-100">

          <MobileBanner />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(NotFound);
