import config from "../config/config";
import { authenticationService } from "./authentication.service";

export const contactService = {
  orderIncident,
  contactIncident,
  getOrderList
};

function contactIncident(first_name, last_name, email, phone, source, comments, reason, accepted_news){
  const requestOptions = {
    method: "POST",
    headers: authenticationService.prepareHeader(),
    body: JSON.stringify({ first_name, last_name, email, phone, source, comments, reason, accepted_news }),
  };

  return fetch(
    `${config.apiUrlHD}/api/popeyes/v1/incidents`,
    requestOptions
  ).then(authenticationService.handleResponse);

}

function orderIncident(first_name, last_name, email, phone, source, comments, reason, order_id, accepted_news){
  const requestOptions = {
    method: "POST",
    headers: authenticationService.prepareHeader(),
    body: JSON.stringify({ first_name, last_name, email, phone, source, comments, reason, order_id, accepted_news }),
    credentials: "include"
  };

  return fetch(
    `${config.apiUrlHD}/api/popeyes/v1/order-incidents`,
    requestOptions
  ).then(authenticationService.handleResponse);
}

function getOrderList(){
  const requestOptions = {
    method: "GET",
    headers: authenticationService.prepareHeader(),
    credentials: "include"
  };

  return fetch(
    `${config.apiUrlHD}/api/v1/users/:${authenticationService.currentUserValue.id}/orders?page=1&key=${config.key}`,
    requestOptions
  ).then(authenticationService.handleResponse);
}