import React, { Component } from "react";
import ControlledCarousel from "../Carousel/Carousel";
import {ScreenName} from "../../analytics/analytics.config";
import {analyticsEvents} from "../../analytics/Analytics";
import {Helmet} from "react-helmet";

// Download App Banner
import MobileBanner from "../MobileBanner/MobileBanner";

// Services
import { newsService } from "../../services/news.service";

import "./Novedades.scss";

class Novedades extends Component {
  constructor(props) {
    super(props);

    this.state = {
      carousel: [],
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.getNewsNovedades();
    analyticsEvents.logScreenView(ScreenName.NEWS)
  };

  getNewsNovedades = () => {
    newsService.getNewsNovedades().then((response) => {
      this.setState({ carousel: response.data });
    });
  };

  render() {
    const { carousel } = this.state;
    return (
      <>
      <Helmet>
        <title>Novedades - Popeyes Louisiana Kitchen</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center">
          <div className="principal_banner">
            {this.state.carousel[0] && <ControlledCarousel item={carousel} source={"news"} />}
          </div>
          <MobileBanner />
        </div>
      </div>
      </>
    );
  }
}

export default Novedades;
