import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Privacy from "../../Privacy/Privacy";
import GeneralCondition from "../../GeneralCodition/GeneralCondition";
import { contactService } from "../../../services/contact.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import love from "../../../assets/img/contact/LOVE-THAT-CHICKEN.png";
import close from "../../../assets/img/buttons/TOCLOSE.svg";
import { withTranslation } from "react-i18next";
import { authenticationService } from "../../../services/authentication.service";
import { userService } from "../../../services/user.service";
import QR from "../../../assets/img/QR/QR.png";
import {ScreenName} from "../../../analytics/analytics.config";
import {analyticsEvents} from "../../../analytics/Analytics";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      comments: "",
      reason: "",
      modalShowSuccess: false,
      modalShowPoliticas: false,
      modalShowLegal: false,
      news: false,
      politicas: false,
      condiciones: false,
      loading: false,
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
    if (authenticationService.currentUserValue) {
      this.personalDataPrefill();
    }
    analyticsEvents.logScreenView(ScreenName.CONTACT_FORM)
  }

  personalDataPrefill = () => {
    userService.getUser().then((response) => {
      this.setState({
        email: response.data.email,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        phone: response.data.phone,
      });
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { firstName, lastName, email, phone, comments, reason, news } =
      this.state;
    this.setState({ loading: true });
    if (this.state.politicas && this.state.condiciones) {
      const accepted_news = news;
      const source = "Web";
      const first_name = firstName;
      const last_name = lastName;
      contactService
        .contactIncident(
          first_name,
          last_name,
          email,
          phone,
          source,
          comments,
          reason,
          accepted_news
        )
        .then(
          (response) => {
            this.setState({ loading: false, modalShowSuccess: true });
            analyticsEvents.logContactFormEvent(true)
          },
          (errorMessage) => {
            this.setState({
              loading: false,
            });
            const message = errorMessage;
            const error = true;
            const success = false;
            this.props.notificationHandler(success, error, message);
            analyticsEvents.logContactFormEvent(false)
          }
        );
    }
  };

  politicasPrivacidad = () => (
    <Modal
      className="text-center"
      centered
      size="lg"
      show={this.state.modalShowPoliticas}
      onHide={() => this.setState({ modalShowPoliticas: false })}
    >
      <Modal.Body>
        <div className="d-flex flex-column justify-content-center">
          <button
            className="text-left border-0 bg-transparent shadow-none"
            onClick={() => this.setState({ modalShowPoliticas: false })}
          >
            <img className="close-size" src={close} alt="Close" />
          </button>
          <div className="mt-2">
            <Privacy />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={() => this.setState({ modalShowPoliticas: false })}
        >
          {window.localStorage.getItem("i18nextLng") === "es" && "Cerrar"}
          {window.localStorage.getItem("i18nextLng") === "en" && "Close"}
          {window.localStorage.getItem("i18nextLng") === "cat" && "Tanca"}
        </button>
      </Modal.Footer>
    </Modal>
  );

  legalCondicion = () => (
    <Modal
      className="text-center"
      centered
      size="lg"
      show={this.state.modalShowLegal}
      onHide={() => this.setState({ modalShowLegal: false })}
    >
      <Modal.Body>
        <div className="d-flex flex-column justify-content-center">
          <button
            className="text-left border-0 bg-transparent shadow-none"
            onClick={() => this.setState({ modalShowLegal: false })}
          >
            <img className="close-size" src={close} alt="Close" />
          </button>
          <div className="mt-2">
            <GeneralCondition />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={() => this.setState({ modalShowLegal: false })}
        >
          {window.localStorage.getItem("i18nextLng") === "es" && "Cerrar"}
          {window.localStorage.getItem("i18nextLng") === "en" && "Close"}
          {window.localStorage.getItem("i18nextLng") === "cat" && "Tanca"}
        </button>
      </Modal.Footer>
    </Modal>
  );

  closeAndRedirect = () => {
    this.setState({modalShowSuccess: false});
    return this.props.history.push("/");
  }

  render() {
    const { t } = this.props;
    const {
      firstName,
      lastName,
      email,
      phone,
      comments,
      reason,
      politicas,
      condiciones,
    } = this.state;
    return (
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center">
          <div className="title col-lg-12 col-ls-12 bg-primary d-flex justify-content-center align-items-center">
            <div>
              <h1 className="text-white title-h1 mt-4">
                {t("Contact.Contáctanos")}
              </h1>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card border-0">
              <div className="card-title text-center mt-5">
                <h4 className="mb-4 text-info sans-bold">
                  {t("Contact.Datos-de-contacto")}
                </h4>
                <span className="mb-4 necesitamos">
                  {t("Contact.Necesitamos")}
                </span>
              </div>

              <div className="col-md-12 col-ls-12 mt-3 d-flex justify-content-center align-items-center">
                <div className="card border-0 contact-info-card align-self-center">
                  <div className="card-body">
                    <span className="sans-bold">
                      {t("ContactLegals.Basic-information")}
                    </span>
                    <br />
                    <br />
                    <span>
                      <b>{t("ContactLegals.Responsible")}:</b>{" "}
                      {t("ContactLegals.Responsible-paragraf")}
                    </span>
                    <br />
                    <br />
                    <span>
                      <b>{t("ContactLegals.Purpose")}:</b>{" "}
                      {t("ContactLegals.Purpose-paragraf")}
                    </span>
                    <br />
                    <br />
                    <span>
                      <b>{t("ContactLegals.Legitimation")}:</b>{" "}
                      {t("ContactLegals.Legitimation-paragraf")}
                    </span>
                    <br />
                    <br />
                    <span>
                      <b>{t("ContactLegals.Recipients")}:</b>{" "}
                      {t("ContactLegals.Recipients-paragraf")}
                    </span>
                    <br />
                    <br />
                    <span>
                      <b>{t("ContactLegals.Rights")}:</b>{" "}
                      {t("ContactLegals.Rights-paragraf")}
                    </span>
                    <br />
                    <br />
                    <span>
                      <b>{t("ContactLegals.Additional")}:</b>{" "}
                      {t("ContactLegals.Additional-paragraf")}.{" "}
                      <div className="QR-img mt-3 mx-auto">
                        <a
                          href="https://qrco.de/bcQrMj"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img className="img-fluid" src={QR} alt="QR code" />
                        </a>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-body d-flex justify-content-center">
                <div className="col-md-6">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label className="text-secondary">
                        {t("Form.Nombre")} *
                      </label>
                      <input
                        required
                        className="form-control"
                        type="text"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={(event) =>
                          this.setState({ firstName: event.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label className="text-secondary">
                        {t("Form.Apellido")} *
                      </label>
                      <input
                        required
                        className="form-control"
                        type="text"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={(event) =>
                          this.setState({ lastName: event.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label className="text-secondary">
                        {t("Form.Email")} *
                      </label>
                      <input
                        required
                        className="form-control"
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={(event) =>
                          this.setState({ email: event.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label className="text-secondary">
                        {t("Form.Teléfono")} *
                      </label>
                      <input
                        required
                        className="form-control"
                        type="number"
                        name="phone"
                        value={this.state.phone}
                        onChange={(event) =>
                          this.setState({ phone: event.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label className="text-secondary">
                        {t("Contact.Selecciona-el-motivo")} *
                      </label>
                      <select
                        required
                        className="form-control"
                        onChange={(event) =>
                          this.setState({ reason: event.target.value })
                        }
                        defaultValue="DEFAULT"
                      >
                        <option disabled value="DEFAULT">
                          ------------
                        </option>
                        <option value="SERVICE">{t("Contact.Service")}</option>
                        <option value="INCIDENT">
                          {t("Contact.Incident")}
                        </option>
                        <option value="FOOD_QUALITY">
                          {t("Contact.Food")}
                        </option>
                        <option value="PROMOTIONS">
                          {t("Contact.Promotions")}
                        </option>
                        <option value="NUTRITIONAL_INFO">
                          {t("Contact.Nutrition")}
                        </option>
                        <option value="ENQUIRIES">
                          {t("Contact.Enquiries")}
                        </option>
                        <option value="RESTAURANT">
                          {t("Contact.Restaurant")}
                        </option>
                        <option value="OTHER">{t("Contact.Other")}</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="text-secondary">
                        {t("Contact.Comentarios")} *
                      </label>
                      <textarea
                        required
                        className="form-control"
                        type="textarea"
                        rows="4"
                        name="comments"
                        value={this.state.comments}
                        onChange={(event) =>
                          this.setState({ comments: event.target.value })
                        }
                      />
                    </div>

                    <div className="form-group d-flex align-items-center border-top">
                      <div className="round mt-3 mr-2">
                        <input
                          type="checkbox"
                          id="checkbox"
                          name="news"
                          checked={this.state.news}
                          onChange={() =>
                            this.setState({ news: !this.state.news })
                          }
                        />
                        <label htmlFor="checkbox"></label>
                      </div>
                      <span className="ml-3 mt-2 text-secondary">
                        {t("Form.Recibir-comunicaciones")}
                      </span>
                    </div>
                    <div className="form-group d-flex align-items-center  border-top">
                      <div className="round mt-3 mr-2">
                        <input
                          required
                          type="checkbox"
                          id="checkbox-politicas"
                          name="politicas"
                          checked={this.state.politicas}
                          onChange={() =>
                            this.setState({
                              politicas: !this.state.politicas,
                            })
                          }
                        />
                        <label htmlFor="checkbox-politicas"></label>
                      </div>
                      <span
                        onClick={() =>
                          this.setState({ modalShowPoliticas: true })
                        }
                        role="button"
                        className="ml-3 mt-2 text-secondary"
                      >
                        {t("Form.Políticas-de-privacidad")} *
                      </span>
                      {this.politicasPrivacidad()}
                    </div>
                    <div className="form-group d-flex align-items-center border-top">
                      <div className="round mt-3 mr-2">
                        <input
                          required
                          type="checkbox"
                          id="checkbox-condiciones"
                          name="condiciones"
                          checked={this.state.condiciones}
                          onChange={() =>
                            this.setState({
                              condiciones: !this.state.condiciones,
                            })
                          }
                        />
                        <label htmlFor="checkbox-condiciones"></label>
                      </div>
                      <span
                        onClick={() => this.setState({ modalShowLegal: true })}
                        role="button"
                        className="ml-3 mt-2 text-secondary"
                      >
                        {t("Form.Condiciones-generales")} *
                      </span>
                      {this.legalCondicion()}
                    </div>

                    <div className="form-group d-flex justify-content-center mt-5">
                      {firstName &&
                      lastName &&
                      email &&
                      phone &&
                      comments &&
                      reason &&
                      politicas &&
                      condiciones ? (
                        <button className="w-50 btn btn-primary rounded-pill">
                          {t("Contact.Enviar")}{" "}
                          {this.state.loading && (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          )}
                        </button>
                      ) : (
                        <button
                          className="w-50 btn btn-secondary rounded-pill"
                          disabled
                        >
                          {t("Contact.Enviar")}{" "}
                          {this.state.loading && (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          )}
                        </button>
                      )}
                      <Modal
                        className="text-center"
                        centered
                        size="md"
                        show={this.state.modalShowSuccess}
                        onHide={() =>
                          this.closeAndRedirect()
                        }
                      >
                        <Modal.Body>
                          <div className="d-flex flex-column justify-content-center">
                            <button
                              className="text-left border-0 bg-transparent shadow-none"
                              onClick={() =>
                                this.closeAndRedirect()
                              }
                            >
                              <img
                                className="close-size"
                                src={close}
                                alt="Close"
                              />
                            </button>
                            <div>
                              <img
                                className="love-size"
                                src={love}
                                alt="Love that chicken"
                              />
                            </div>
                            <h4 className="text-success gracias sans-bold mt-3">
                              {t("Contact.Gracias")}
                            </h4>
                            <p className="mt-3 mb-5 sans-bold">
                              {t("Contact.Nuestros-servicios")}
                            </p>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ContactForm);
