import { BehaviorSubject } from "rxjs";
import config from "../config/config";

let tokenStorageKey = "Popeyes-Token";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem(tokenStorageKey))
);

export const authenticationService = {
  handleResponse,
  authHeader,
  register,
  login,
  loginHD,
  autoLogin,
  forgotPassword,
  resendEmailValidation,
  changePassword,
  logoutHD,
  prepareHeader,
  acceptLoyaltyChanges,
  acceptPrivacyPolicy,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function prepareHeader() {
  let headers = authHeader();
  if (headers.get("Accept-Language")) {
    headers.set("Accept-Language", `${config.language}`);
  } else {
    headers.append("Accept-Language", `${config.language}`);
  }
  if (headers.get("Content-Type")) {
    headers.set("Content-Type", "application/json");
  } else {
    headers.append("Content-Type", "application/json");
  }
  return headers;
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (
        [401, 403].indexOf(response.status) !== -1 &&
        authenticationService.currentUserValue
      ) {
        autoLogin();
      } else {
        const error = (data && data.error.Message) || response.statusText;
        return Promise.reject(error);
      }
    }
    return data;
  });
}

function authHeader() {
  const headers = new Headers();
  const currentUser = currentUserSubject.value;

  if (currentUser && currentUser.token) {
    headers.set("authorization", `Bearer ${currentUser.token}`);
  } else if (currentUser && currentUser.data && currentUser.data.token) {
    headers.set("authorization", `Bearer ${currentUser.data.token}`);
  }
  return headers;
}

function register(email, password, firstName, lastName, phone, news) {
  const requestOptions = {
    method: "POST",
    headers: prepareHeader(),
    body: JSON.stringify({ email, password, firstName, lastName, phone, news }),
  };

  return fetch(
    `${config.apiUrl}/api/v1/auth/register?key=${config.key}`,
    requestOptions
  ).then(handleResponse);
}

function forgotPassword(email) {
  const requestOptions = {
    method: "POST",
    headers: prepareHeader(),
    body: JSON.stringify({ email }),
  };

  return fetch(
    `${config.apiUrl}/api/v1/auth/forgot-password?key=${config.key}`,
    requestOptions
  ).then(handleResponse);
}

function resendEmailValidation(email) {
  const requestOptions = {
    method: "POST",
    headers: prepareHeader(),
    body: JSON.stringify({ email }),
  };

  return fetch(
    `${config.apiUrl}/api/v1/auth/resend-email?key=${config.key}`,
    requestOptions
  ).then(handleResponse);
}

function changePassword(password) {
  const requestOptions = {
    method: "POST",
    headers: prepareHeader(),
    body: JSON.stringify({ password }),
  };

  return fetch(
    `${config.apiUrl}/api/v1/auth/change-password?key=${config.key}`,
    requestOptions
  ).then(handleResponse);
}

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: prepareHeader(),
    body: JSON.stringify({ email, password }),
  };

  return fetch(
    `${config.apiUrl}/api/v1/auth/login?key=${config.key}`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      const userData = {
        id: user.data.id,
        email: user.data.email,
        token: user.data.token,
        refreshToken: user.data.refreshToken,
      };
      localStorage.setItem(tokenStorageKey, JSON.stringify(userData));
      currentUserSubject.next(user);
      return user;
    });
}

function loginHD(token, user_id) {
  const requestOptions = {
    method: "POST",
    headers: prepareHeader(),
    credentials: "include",
    body: JSON.stringify({
      credentials: { token, user_id },
    }),
  };
  return fetch(`${config.apiUrlHD}/api/v1/auth`, requestOptions).then(
    (response) => {
      handleResponse(response);
    }
  );
}

function logoutHD() {
  const requestOptions = {
    method: "POST",
    headers: prepareHeader(),
    credentials: "include",
  };
  return fetch(`${config.apiUrlHD}/api/v1/logout`, requestOptions);
}

function autoLogin() {
  const currentUser = currentUserSubject.value;
  const email = currentUser.email;
  const refreshToken = currentUser.refreshToken;
  const requestOptions = {
    method: "POST",
    headers: prepareHeader(),
    body: JSON.stringify({
      email,
      refreshToken,
    }),
  };

  return fetch(
    `${config.apiUrl}/api/v1/auth/autologin?key=${config.key}`,
    requestOptions
  )
    .then(handleResponse)
    .then(
      (user) => {
        const userData = {
          id: currentUser.id,
          email: currentUser.email,
          refreshToken: currentUser.refreshToken,
          token: user.data.token,
        };

        loginHD(user.data.token, currentUser.id).then(() => {
          localStorage.setItem(tokenStorageKey, JSON.stringify(userData));
          currentUserSubject.next(user);
          window.location.reload();
        });

        return user;
      },
      (error) => {
        currentUserSubject.next(null);
        localStorage.removeItem(tokenStorageKey);
        window.location.href = "/";
      }
    );
}
///{{base_url}}/api/v1/auth/acceptloyaltychanges?key={{key}}
function acceptLoyaltyChanges(news = null) {
  const requestOptions = {
    method: "POST",
    headers: prepareHeader(),
    body: JSON.stringify({ news }),
  };
  return fetch(
    `${config.apiUrl}/api/v1/auth/acceptloyaltychanges?key=${config.key}`,
    requestOptions
  );
}

///api/v1/auth/acceptprivacypolicy
function acceptPrivacyPolicy(news) {
  const requestOptions = {
    method: "POST",
    headers: prepareHeader(),
    body: JSON.stringify({ news }),
  };
  return fetch(
    `${config.apiUrl}/api/v1/auth/acceptprivacypolicy?key=${config.key}`,
    requestOptions
  );
}
