import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import close from "../../assets/img/buttons/TOCLOSE.svg";
import image from "../../assets/img/contact/CONTACTANOS-IMAGE.jpg";
import { authenticationService } from "../../services/authentication.service";
import { withTranslation } from "react-i18next";
import FormularioContacto from "./ContactLegals/FormularioContacto";
import {ScreenName} from "../../analytics/analytics.config";
import {analyticsEvents} from "../../analytics/Analytics";
import {Helmet} from "react-helmet";
import "./Contact.scss";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContact: false,
    };
  }
  componentDidMount = () => {
    window.scroll(0, 0);
    analyticsEvents.logScreenView(ScreenName.CONTACT)
  };

  popupContact = () => {
    return (
      <Modal
        className="text-center"
        centered
        size="lg"
        show={this.state.showContact}
        onHide={() => this.setState({ showContact: false })}
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center">
            <button
              className="text-left border-0 bg-transparent shadow-none"
              onClick={() => this.setState({ showContact: false })}
            >
              <img className="close-size" src={close} alt="Close" />
            </button>
            <div className="mt-2">
              <FormularioContacto />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  render() {
    const { t } = this.props;
    return (
      <>
      <Helmet>
        <title>Contacto - Popeyes Louisiana Kitchen</title>
      </Helmet>
      <div className="container-fluid">
        {this.popupContact()}
        <div className="row">
          <div className="col-ls-12">
            <div className="title bg-primary d-flex justify-content-center align-items-center w-100">
              <div>
                <h1 className="text-white title-h1 mt-4">
                  {t("Contact.Contáctanos")}
                </h1>
              </div>
            </div>

            <div className="card border-0">
              <div className="contact-body d-flex justify-content-center">
                <div className="col-md-12 col-ls-12 col-lg-5">
                  <h4 className="card-title text-info sans-bold mb-4 ml-3 mt-5">
                    {t("Contact.Nuestro-equipo")}
                  </h4>
                  <div className="text-left mb-4">
                    <div className="col-ls-12 col-lg-12 col-md-12">
                      <span>{t("Contact.Nuestro-equipo-paragraf")}</span>
                    </div>
                  </div>
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <Link
                      to="/restaurant-contact"
                      className="btn btn-primary rounded-pill w-50 mb-4 ml-4"
                    >
                      {t("Contact.Restaurante")}
                    </Link>
                    <a
                      href="https://empleo.popeyes.es/jobs/popeyes-trabaja-con-nosotros-en-oficina"
                      rel="noreferrer"
                      target="_blank"
                      className="btn btn-primary rounded-pill w-50 mb-4 ml-4"
                    >
                      {t("Contact.Oficina")}
                    </a>
                  </div>
                  <div className="border-bottom w-100"></div>

                  <h4 className="ml-3 mt-4 mb-4 text-info sans-bold">
                    {t("Contact.Atención-al-cliente")}
                  </h4>
                  <div className="text-left mb-4">
                    <div className="col-ls-12 col-lg-12 col-md-12">
                      <span>{t("Contact.Atención-al-cliente-paragraf")}</span>
                    </div>
                  </div>
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <Link
                      to="/public-contact-form"
                      className="btn btn-primary rounded-pill w-50 mb-4 ml-4"
                    >
                      {t("Contact.Contáctanos")}
                    </Link>
                    <span
                      onClick={() => this.setState({ showContact: true })}
                      role="button"
                      className="text-primary"
                    >
                      {t("Contact.More-information")}
                    </span>
                  </div>
                  <div className="border-bottom w-100"></div>

                  {authenticationService.currentUserValue && (
                    <React.Fragment>
                      <h4 className="card-title text-info mb-4 sans-bold ml-3 mt-4">
                        {t("Contact.Incidencia-con-el-pedido")}
                      </h4>
                      <div className="text-left mb-4">
                        <div className="col-ls-12 col-lg-12 col-md-12">
                          <span>
                            {t("Contact.Incidencia-con-el-pedido-paragraf")}
                          </span>
                        </div>
                      </div>
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <Link
                          to="/private-contact-form"
                          className="btn btn-primary rounded-pill w-50 mb-4 ml-4"
                        >
                          {t("Contact.Contáctanos")}
                        </Link>
                        <span
                          onClick={() => this.setState({ showContact: true })}
                          role="button"
                          className="text-primary"
                        >
                          {t("Contact.More-information")}
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                </div>

                <div className="col-lg-7 col-md-12 col-ls-12">
                  <img className="img-fluid" src={image} alt="Contact" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default withTranslation()(Contact);
