import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ControlledCarousel from "../Carousel/Carousel";
import close from "../../assets/img/buttons/TOCLOSE.svg";
import config from "../../config/config";
import {ScreenName} from "../../analytics/analytics.config";
import {analyticsEvents} from "../../analytics/Analytics";

// Download App Banner
import MobileBanner from "../MobileBanner/MobileBanner";

// Services
import { newsService } from "../../services/news.service";
import { popupService } from "../../services/popups.service";
import "./Home.scss";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      news: [],
      popup: [],
      carousel: [],
      show: false,
      today: new Date().toLocaleDateString(),
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.getNewsHome();
    this.getPopups();
    if (!window.localStorage.getItem("Today")) {
      window.localStorage.setItem("Today", new Date().toLocaleDateString());
      this.setState({ show: true });
    } else if (this.state.today !== window.localStorage.getItem("Today")) {
      window.localStorage.setItem("Today", new Date().toLocaleDateString());

      this.setState({ show: true });
    }
    analyticsEvents.logScreenView(ScreenName.HOME)
  };

  getPopups = () => {
    popupService.getPopup().then((response) => {
      this.setState({ popup: response.data });
    });
  };

  getNewsHome = () => {
    newsService.getNewsHome().then((response) => {
      const initialArray = [...response.data];
      const initialArrayCarousel = [...response.data];
      const news = initialArray.splice(3);
      const carousel = initialArrayCarousel.slice(0, 3);
      this.setState({ news: news, carousel: carousel });
    });
  };

  linkRedirect = (val) => {
    let source = "home"
    analyticsEvents.logNewsClickEvent(val.id, val.name, source)
    if(val.url){
      return window.open(val.url, '_blank').focus();
    } else
    if(val.sec === "cercademi"){
      return this.props.history.push("/restaurantes");
    }else
    if(val.sec === "carta"){
      return this.props.history.push(`/carta/?id=${val.id}`);
    } else
    if(val.sec === "homedelivery"){
      return window.open(config.homeDeliveryUrl, "_self");
    }
  }

  render() {
    const { news, carousel, show } = this.state;
    return (
      <div className="container-fluid home">
        <div className="row align-items-center justify-content-center">
          <div className="principal_banner">
            {this.state.news[0] && <ControlledCarousel item={carousel} source={"home"} />}
          </div>
          {news.map((val, idx) => (
            <div className="col-md-5 mt-5" key={idx}>
              <div className="card border-0" style={{backgroundColor: "#f8f6f6"}}>
                <div key={idx} onClick={() => this.linkRedirect(val)} role="button" className="stretched-link">
                  <img
                    className="img-fluid border-rounded"
                    src={val.image}
                    alt="Banners"
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="separate"></div>
        </div>
        {show && this.state.popup && this.state.popup.length !== 0 && (
          <Modal
            className="text-center"
            centered
            size="lg"
            show={this.state.show}
            onHide={() => this.setState({ show: false })}
          >
            {this.state.popup.map((item, idx) => (
              <div key={idx}>
                <img
                  role="button"
                  className="ml-3 mt-3 close-size close-button text-left border-0 bg-transparent shadow-none"
                  onClick={() => this.setState({ show: false })}
                  src={close}
                  alt="Close"
                />

                <img
                  role="button"
                  onClick={() => this.linkRedirect(item)}
                  className="w-100 img-fluid border-rounded"
                  key={idx}
                  src={item.image}
                  alt="Popup"
                />
              </div>
            ))}
          </Modal>
        )}
        <MobileBanner />
      </div>
    );
  }
}

export default Home;
