import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Alergens from "./components/Alergens/Alergens";
import LoyaltyPopup from "./components/LoyaltyPopup/LoyaltyPopup";
import { authenticationService } from "./services/authentication.service";
import config from "./config/config";
import { withTranslation } from "react-i18next";
import { cookiesHelpers } from "./helpers/cookies/cookies.helpers";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
//smart bar
import SmartBanner from "./components/SmartBanner/SmartBanner";
// NavBar
import NavBar from "./components/NavBar/NavBar";

// Footer
import Footer from "./components/Footer/Footer";

// Home
import Home from "./components/Home/Home";

// Login
import Login from "./components/Login/Login";

// Logout
import Logout from "./components/Logout/Logout";

// History
import History from "./components/History/History";

// Quality
import Quality from "./components/Quality/Quality";

// Cookies
import Cookies from "./components/Cookies/Cookies";

// Privacy
import Privacy from "./components/Privacy/Privacy";

// Aviso Legal
import Legal from "./components/Legal/Legal";

// Page not found
import NotFound from "./components/NotFound/NotFound";

// Register
import Register from "./components/Register/Register";

// Forgot password
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";

// Personal data and change password
import PersonalData from "./components/PersonalData/PersonalData";
import ChangePassword from "./components/PersonalData/ChangePassword/ChangePassword";

// Restaurants
import Restaurants from "./components/Restaurants/Restaurants";

// Sostenibilidad
import Sostenibilidad from "./components/Sostenibilidad/Sostenibilidad";

// Contact page
import Contact from "./components/Contact/Contact";
import ContactForm from "./components/Contact/ContactForm/ContactForm";
import PrivateContactForm from "./components/Contact/PrivateContactForm/PrivateContactForm";

// Novedades
import Novedades from "./components/Novedades/Novedades";

// Mis recompensas
import Recompensas from "./components/Recompensas/Recompensas";
import LegalsRewards from "./components/Recompensas/LegalsRewards/LegalsRewards";

// Ongoing order bar
import OngoingOrderBar from "./components/OngoingOrder/OngoingOrderBar";

// Cookie popup
import PrivacyQuestionPopup from "./components/PrivacyQuestionPopup/PrivacyQuestionPopup";
// Cookie popup
import CookiePopup from "./components/CookiePopup/CookiePopup";

// Catalog Showcase
import CatalogShowcase from "./components/Carta/CatalogShowcase";

// Notification Bar
import NotificationBar from "./components/NotificationBar/NotificationBar";

// Restaurant contact
import RestauranteContact from "./components/Contact/RestauranteContact/RestauranteContact";

// General Condition
import GeneralCondition from "./components/GeneralCodition/GeneralCondition";

import SocialCommitment from "./components/SocialCommitment/SocialCommitment";
import { userService } from "./services/user.service";

let browserAnalysis = false;

if (cookiesHelpers.checkCookie(config.analyticsCookie)) {
  const newBrowserAnalysis = cookiesHelpers.getCookie(config.analyticsCookie);
  if (newBrowserAnalysis === "true") {
    browserAnalysis = true;
  }
}

firebase.initializeApp(config.firebaseConfig);

class RoutePath extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      error: false,
      message: "",
      showCategory: true,
      showCookieConfig: false,
      showButton: true,
      orderMenuHeight: false,
      browserAnalysis: false,
      privacyPolicyAccepted: "empty",
      loyaltyChangesAccepted: "empty",
      news: "",
      name: "",
    };
    firebase.analytics().setAnalyticsCollectionEnabled(browserAnalysis);
    this.notificationHandler = this.notificationHandler.bind(this);
    this.closeCookie = this.closeCookie.bind(this);
    this.handleShowButton = this.handleShowButton.bind(this);
    this.handleOrderBar = this.handleOrderBar.bind(this);
  }

  componentDidMount = () => {
    if (!cookiesHelpers.checkCookie(config.functionalityCookie)) {
        this.setState({ showButton: false });
    }
    userService
        .getUser()
        .then((response) => {
          this.setState({
            privacyPolicyAccepted: response.data.privacyPolicyAccepted,
            news: response.data.news,
            loyaltyChangesAccepted: response.data.loyaltyChangesAccepted,
            name: response.data.firstName,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            privacyPolicyAccepted: true,
            news: true,
            loyaltyChangesAccepted: true,
            name: "",
          });
        });
  };

  componentDidUpdate = (prevProps, prevState) => {
    console.log("{RoutePath.js}[componentDidUpdate](142) ", this.state);
    if (
      this.state.privacyPolicyAccepted === "empty" &&
      this.state.loyaltyChangesAccepted === "empty"
    ) {
      userService
        .getUser()
        .then((response) => {
          this.setState({
            privacyPolicyAccepted: response.data.privacyPolicyAccepted,
            news: response.data.news,
            loyaltyChangesAccepted: response.data.loyaltyChangesAccepted,
            name: response.data.firstName,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            privacyPolicyAccepted: true,
            news: true,
            loyaltyChangesAccepted: true,
            name: "",
          });
        });
    }
  };

  acceptPrivacyPolicy = (news) => {
    this.setState({
      privacyPolicyAccepted: true,
      news: news,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.privacyPolicyAccepted === "empty") return true;
    if (this.state.privacyPolicyAccepted !== nextState.privacyPolicyAccepted) {
      // Only update if data has changed
      return true;
    }
    if (this.state.showCategory !== nextState.showCategory) return true;
    // Otherwise, do not update
    return false;
  }

  notificationHandler = (success, error, message) => {
    this.setState({ success: success, error: error, message: message });
    setTimeout(() => {
      this.setState({ success: false, error: false, message: "" });
    }, 5000);
  };

  handleShowPolicy = (response) => {
    if (response && response.data) {
      this.setState({
        privacyPolicyAccepted: response.data.privacyPolicyAccepted,
        news: response.data.news,
        loyaltyChangesAccepted: response.data.loyaltyChangesAccepted,
        name: response.data.firstName,
      });
    }
  };

  goToMain = () => {
    window.location.href = config.homeDeliveryUrl;
    // window.location.href = "/";
  };

  closeCookie = () => {
    return this.setState({ showCookieConfig: false });
  };

  handleShowButton = () => {
    this.setState({ showButton: true });
  };

  handleOrderBar = () => {
    this.setState({ orderMenuHeight: true });
  };

  showCategory = () => {
    this.setState({ showCategory: true });
  };

  hideCategory = () => {
    console.log("ITT");
    this.setState({ showCategory: false });
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <BrowserRouter>
          <SmartBanner
            title={"Popeyes® España"}
            author={"Popeyes® España "}
            daysHidden={30}
            daysReminder={1}
            price={{ ios: t("Header.Install"), android: t("Header.Install") }}
            storeText={{ ios: "", android: "" }}
            button={t("Header.Open")}
          />
          <div className="sticky-top bg-white w-100">
            {this.state.message && (
              <NotificationBar
                success={this.state.success}
                error={this.state.error}
                message={this.state.message}
              />
            )}
            {authenticationService.currentUserValue && (
              <OngoingOrderBar handleOrderBar={this.handleOrderBar} />
            )}
            <NavBar handleShowCategory={this.showCategory} />
          </div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              path="/login"
              render={(props) => (
                <Login
                  {...props}
                  notificationHandler={this.notificationHandler}
                  handleShowPolicy={this.handleShowPolicy}
                />
              )}
            />
            <Route path="/alergens" component={Alergens} />
            <Route path="/logout" component={Logout} />
            <Route path="/historia" component={History} />
            <Route path="/calidad" component={Quality} />
            <Route path="/sostenibilidad" component={Sostenibilidad} />
            <Route path="/cookies" component={Cookies} />
            <Route path="/general-conditions" component={GeneralCondition} />
            <Route path="/aviso-legal" component={Legal} />
            <Route path="/politica-de-privacidad" component={Privacy} />
            <Route path="/condiciones-recompensas" component={LegalsRewards} />
            <Route path="/restaurant-contact" component={RestauranteContact} />
            <Route
              path="/register"
              render={(props) => (
                <Register
                  {...props}
                  notificationHandler={this.notificationHandler}
                />
              )}
            />
            <Route
              path="/forgot-password"
              render={(props) => (
                <ForgotPassword
                  {...props}
                  notificationHandler={this.notificationHandler}
                />
              )}
            />
            <Route path="/restaurantes" component={Restaurants} />
            <Route path="/contact" component={Contact} />
            <Route
              path="/public-contact-form"
              render={(props) => (
                <ContactForm
                  {...props}
                  notificationHandler={this.notificationHandler}
                />
              )}
            />
            <Route path="/novedades" component={Novedades} />
            {/*<Route path="/mis-recompensas" component={Recompensas} />*/}
            <Route
              path="/carta"
              render={(props) => (
                <CatalogShowcase
                  {...props}
                  showCategory={this.state.showCategory}
                  handleShowCategory={this.hideCategory}
                  orderMenuHeight={this.state.orderMenuHeight}
                />
              )}
            />
            <Route path="/social-commitment" component={SocialCommitment} />
            {authenticationService.currentUserValue ? (
              <React.Fragment>
                <Switch>
                  <Route
                    path="/personal-data"
                    render={(props) => (
                      <PersonalData
                        {...props}
                        notificationHandler={this.notificationHandler}
                      />
                    )}
                  />
                  <Route
                    path="/change-password"
                    render={(props) => (
                      <ChangePassword
                        {...props}
                        notificationHandler={this.notificationHandler}
                      />
                    )}
                  />
                  <Route
                    path="/private-contact-form"
                    render={(props) => (
                      <PrivateContactForm
                        {...props}
                        notificationHandler={this.notificationHandler}
                      />
                    )}
                  />
                  <Route component={NotFound} />
                </Switch>
              </React.Fragment>
            ) : (
              <Route component={NotFound} />
            )}
            <Route component={NotFound} />
          </Switch>
          <div className="fixed-bottom">
            {this.state.showButton && (
              <button
                className="btn btn-sm btn-primary cookie-button"
                onClick={() =>
                  this.setState({
                    showCookieConfig: !this.state.showCookieConfig,
                  })
                }
              >
                Cookie
              </button>
            )}
            {this.state.showCookieConfig && (
              <CookiePopup
                showCookie={false}
                showAdvance={true}
                closeAll={true}
                closeCookie={this.closeCookie}
                handleShowButton={this.handleShowButton}
              />
            )}
            <div
              className={
                !this.state.showButton
                  ? "cookie-animation-show"
                  : "cookie-animation-hide"
              }
            >
              {!this.state.showButton && (
                <CookiePopup handleShowButton={this.handleShowButton} />
              )}
            </div>
          </div>
          <div>
            <PrivacyQuestionPopup
              name={this.state.name}
              showPQP={!this.state.privacyPolicyAccepted}
              // showPQP={true}
              isNewsAccepted={this.state.news}
              closeAll={true}
              closeCookie={this.closeCookie}
              handleShowButton={this.handleShowButton}
              acceptPolicyCallback={
                this.state.loyaltyChangesAccepted ? this.goToMain : () => {}
              }
            />
          </div>

          <div>
            {this.state.name && (
              <PrivacyQuestionPopup
                name={this.state.name}
                showPQP={!this.state.privacyPolicyAccepted}
                // showPQP={true}
                isNewsAccepted={this.state.news}
                acceptPrivacyPolicy={this.acceptPrivacyPolicy}
                closeAll={true}
                closeCookie={this.closeCookie}
                handleShowButton={this.handleShowButton}
                acceptPolicyCallback={
                  this.state.loyaltyChangesAccepted ? this.goToMain : () => {}
                }
              />
            )}
          </div>
          <div>
            {this.state.privacyPolicyAccepted === true ? (
              <LoyaltyPopup
                showModal={!this.state.loyaltyChangesAccepted}
                acceptLoyaltyCallback={this.goToMain}
                news={this.state.news}
                closeAll={true}
              />
            ) : null}
          </div>
          <Footer />
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default withTranslation()(RoutePath);
