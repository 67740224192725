import React, { Component } from "react";
import "./NotificationBar.scss";

class NotificationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: this.props.success? this.props.success : false,
      error: this.props.error? this.props.error : false,
      message: this.props.message,
    };
  }

  componentDidMount= () => {
    if (this.state.success === true || this.state.error === true) {
      setTimeout(() => {
        this.setState({ success: false, error: false, message: "" });
      }, 5000);
    }
  };

  componentDidUpdate= () => {
    if (this.state.success === true || this.state.error === true || this.state.message) {
      this.setState({ success: false, error: false, message: "" });
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.success === true && (
          <div className="col-lg-12 align-self-auto success-bg-color d-flex justify-content-between align-items-center mb-1">
            <span className="ml-3">{this.props.message}</span>
            <button
              className="bg-transparent border-0"
              onClick={() => this.setState({ success: false })}
            >
              X
            </button>
          </div>
        )}
        {this.state.error === true && (
          <div className="col-lg-12 error-bg-color d-flex justify-content-between align-items-center mb-1">
            <span className="ml-3">{this.props.message}</span>
            <button
              className="bg-transparent border-0"
              onClick={() => this.setState({ error: false })}
            >
              X
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default NotificationBar;
