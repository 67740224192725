import React, { Component } from "react";
import { menuService } from "../../../services/menu.service";
import { authenticationService } from "../../../services/authentication.service";
import config from "../../../config/config";
import { ScreenName } from "../../../analytics/analytics.config";
import { analyticsEvents } from "../../../analytics/Analytics";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import close from "../../../assets/img/buttons/TOCLOSE.svg";
import back from "../../../assets/img/carta/BACK.svg";
import {Helmet} from "react-helmet";
import "./Carta.scss";

class Carta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
      carouselIndex: "",
      myLanguage: "",
      product: [],
      ModalShow: false,
      alergensModalShow: false,
      show: false,
      myIndex: "",
      position: 0,
      myRef: React.createRef([]),
    };
  }

  componentDidMount = () => {
    menuService.getCollections().then((response) => {
      this.setState({ menu: response.data });
    });
    window.scrollTo(0, 0);
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    if (id !== null) {
      this.detailsModal(id);
    } else {
      analyticsEvents.logScreenView(ScreenName.CATALOG);
    }
  };

  componentDidUpdate = () => {
    window.addEventListener("scroll", this.handlePosition);
    if (this.state.menu.length !== 0 && !this.state.show) {
      let newRef = this.state.myRef;
      newRef.current = [...Array(this.state.menu.length).keys()].map((_, i) => {
        if (newRef.current !== null) {
          return newRef.current[i] ?? React.createRef();
        } else {
          return null;
        }
      });
      if (this.state.myRef !== newRef) {
        this.setState({ myRef: newRef });
      }

      setTimeout(() => {
        this.setState({ show: true });
        if (this.props.categorySelected && this.state.menu.length !== 0) {
          this.state.menu.map((val, idx) => {
            if (val.id === this.props.categorySelected) {
              return this.executeScrollBigMenu(idx);
            }
            return true;
          });
        }
      }, 300);
    }

    const newLanguage = window.localStorage.getItem("i18nextLng");
    if (newLanguage !== this.state.myLanguage) {
      config.language = newLanguage;
      menuService.getCollections().then((response) => {
        this.setState({ menu: response.data, myLanguage: newLanguage });
      });
    }
  };

  handlePosition = () => {
    this.setState({ position: window.pageYOffset });
  };

  executeScroll = (idx) => {
    let myRef = this.state.myRef.current !== null && this.state.myRef;
    let pos = myRef.current[idx].current.style.position;
    let top = myRef.current[idx].current.style.top;
    myRef.current[idx].current.style.position = "relative";
    if (window.innerWidth <= 1000) {
      myRef.current[idx].current.style.top = "-180px";
    } else {
      myRef.current[idx].current.style.top = "-140px";
    }
    myRef.current[idx].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    myRef.current[idx].current.style.top = top;
    myRef.current[idx].current.style.position = pos;
    this.setState({ myIndex: idx });
  };

  executeScrollBigMenu = (idx) => {
    let myRef = this.state.myRef.current !== null && this.state.myRef;
    let pos = myRef.current[idx].current.style.position;
    let top = myRef.current[idx].current.style.top;
    myRef.current[idx].current.style.position = "relative";
    if (window.innerWidth <= 1000) {
      myRef.current[idx].current.style.top = "-90px";
    } else {
      myRef.current[idx].current.style.top = "-60px";
    }
    myRef.current[idx].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    myRef.current[idx].current.style.top = top;
    myRef.current[idx].current.style.position = pos;
    this.setState({ myIndex: idx });
  };

  detailsModal = (id) => {
    menuService.getProductDetails(id).then((response) => {
      this.setState({ product: response.data, ModalShow: true });
      analyticsEvents.logScreenView(ScreenName.ITEM_DETAIL);
      analyticsEvents.logProductClickEvent(
        response.data.keyname,
        response.data.name
      );
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
      <Helmet>
        <title>Carta - Popeyes Louisiana Kitchen</title>
      </Helmet>
      <div className="container-fluid carta">
        {this.state.show ? (
          <div className="row d-flex justify-content-center">
            <div className="bg-white align-self-center d-flex align-items-center carousel-menu">
              {this.state.menu.length !== 0 &&
                this.state.menu.map((item, idx) => (
                  <div
                    role="button"
                    onClick={() => this.executeScrollBigMenu(idx)}
                    className="menu d-flex flex-column mt-3 mb-3"
                    key={idx}
                  >
                    <img
                      className="image-menu align-self-top"
                      src={item.thumbnail}
                      alt="Menu"
                    />
                    <div
                      className={
                        this.state.myIndex && this.state.myIndex === idx
                          ? "menu-underline text-primary text-center align-self-bottom sans-bold text-size-menu"
                          : "text-primary text-center align-self-bottom sans-bold text-size-menu"
                      }
                    >
                      {item.name}
                    </div>
                  </div>
                ))}
            </div>
            {this.state.position > 190 && (
              <div
                className="bg-white align-self-center d-flex align-items-center carousel-menu"
                style={{ position: "sticky" }}
              >
                {this.state.menu.length !== 0 &&
                  this.state.menu.map((item, idx) => (
                    <div
                      role="button"
                      onClick={() => this.executeScroll(idx)}
                      className="mini-menu d-flex mt-3 mb-3"
                      key={idx}
                    >
                      <ul className="list-group list-group-horizontal">
                        <div
                          className={
                            this.state.myIndex && this.state.myIndex === idx
                              ? "list-group-item list-group-item-action d-flex flex-column text-center align-items-center border-0 "
                              : "list-group-item list-group-item-action d-flex flex-column align-items-center border-0 text-center "
                          }
                        >
                          <div
                            className={
                              this.state.myIndex && this.state.myIndex === idx
                                ? "text-nowrap text-primary menu-underline text-primary sans-bold text-size-mini-menu"
                                : "text-nowrap text-primary sans-bold text-size-mini-menu text-primary"
                            }
                          >
                            {item.name}
                          </div>
                        </div>
                      </ul>
                    </div>
                  ))}
              </div>
            )}

            {this.state.menu.map((item, idx) => (
              <div
                key={idx}
                ref={
                  this.state.myRef.current !== null &&
                  this.state.myRef.current[idx]
                }
                className="col-md-12 mb-5"
              >
                {item.children.length === 0 && (
                  <h4 className="sans-bold mt-5">{item.name}</h4>
                )}
                {item.children.length !== 0 && item.products.length !== 0 && (
                  <h4 className="sans-bold mt-5">{item.name}</h4>
                )}

                {item.children.length !== 0 && item.products.length !== 0 && (
                  <div className="row align-items-center">
                    {item.products.map((val, idx) => (
                      <div key={idx} className="col-lg-4 col-md-6 mt-4">
                        <div
                          role="button"
                          onClick={() => this.detailsModal(val.id)}
                          className="card d-flex card-rounded"
                        >
                          <div className="card-height d-flex mb-2 p-2">
                            <div className="text-width d-flex flex-column">
                              <div className="sans-bold card-name mb-1">
                                {val.name}
                              </div>
                              <div className="card-description">
                                {val.description}
                              </div>
                              <div className="mt-auto btn d-flex align-self-stretch justify-content-center btn-primary rounded-pill button-width">
                                <FontAwesomeIcon size="lg" icon={faPlus} />
                              </div>
                            </div>
                            <div className="image-width d-flex align-items-center justify-content-end">
                              <img
                                className="img-fluid"
                                src={val.image}
                                alt="Banners"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {item.children.length !== 0 &&
                  item.children.map((val, idx) => (
                    <React.Fragment key={idx}>
                      <h4 className="sans-bold mt-5">{val.name}</h4>
                      <div className="row align-items-center">
                        {val.products.map((val, index) => (
                          <div key={index} className="col-lg-4 col-md-6 mt-4">
                            <div
                              role="button"
                              onClick={() => this.detailsModal(val.id)}
                              className="card d-flex card-rounded"
                            >
                              <div className="card-height d-flex mb-2 p-2">
                                <div className="text-width d-flex flex-column">
                                  <div className="sans-bold card-name mb-1">
                                    {val.name}
                                  </div>
                                  <div className="card-description">
                                    {val.description}
                                  </div>
                                  <div className="mt-auto btn d-flex align-self-stretch justify-content-center btn-primary rounded-pill button-width">
                                    <FontAwesomeIcon size="lg" icon={faPlus} />
                                  </div>
                                </div>
                                <div className="image-width d-flex align-items-center justify-content-end">
                                  <img
                                    className="img-fluid"
                                    src={val.image}
                                    alt="Banners"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  ))}

                {item.children.length === 0 && (
                  <div className="row align-items-center">
                    {item.products.map((val, idx) => (
                      <div className="col-lg-4 col-md-6 mt-4" key={idx}>
                        <div
                          role="button"
                          onClick={() => this.detailsModal(val.id)}
                          className="card d-flex card-rounded"
                        >
                          <div className="card-height d-flex mb-2 p-2">
                            <div className="text-width d-flex flex-column">
                              <div className="sans-bold card-name mb-1">
                                {val.name}
                              </div>
                              <div className="card-description">
                                {val.description}
                              </div>
                              <div className="mt-auto btn d-flex align-self-stretch justify-content-center btn-primary rounded-pill button-width">
                                <FontAwesomeIcon size="lg" icon={faPlus} />
                              </div>
                            </div>
                            <div className="image-width d-flex align-items-center justify-content-end">
                              <img
                                className="img-fluid"
                                src={val.image}
                                alt="Banners"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="carta w-100 d-flex justify-content-center align-items-center">
            <div className="snippet">
              <div className="stage">
                <div className="dot-elastic"></div>
              </div>
            </div>
          </div>
        )}
        {/* Product Details Modal*/}
        {this.state.ModalShow && !this.state.alergens && (
          <Modal
            className="text-center"
            centered
            size="md"
            show={this.state.ModalShow}
            onHide={() => this.setState({ ModalShow: false })}
          >
            <Modal.Body>
              <div className="d-flex flex-column justify-content-center">
                <button
                  className="text-left border-0 bg-transparent shadow-none"
                  onClick={() => this.setState({ ModalShow: false })}
                >
                  <img className="close-size" src={close} alt="Close" />
                </button>
                <div>
                  <img
                    className="img-fluid"
                    src={this.state.product.image}
                    alt="Products"
                  />
                </div>
                <h4 className="sans-bold text-left mt-3 ml-2">
                  {this.state.product.name}
                </h4>
                <p className="mt-2 ml-2 text-left">
                  {this.state.product.description}
                </p>
                {this.state.product.nutrition.weight !== 0 ||
                this.state.product.allergens.length !== 0 ? (
                  <div
                    role="button"
                    onClick={() => this.setState({ alergens: true })}
                    className="text-primary mt-2 text-right mr-2"
                  >
                    <span className="allergens-border">
                      {t("Carta.Nutritional-information")}
                    </span>
                  </div>
                ) : null}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="w-100 d-flex justify-content-center align-items-center">
                <a
                  href={
                    !authenticationService.currentUserValue
                      ? "/login"
                      : config.apiUrlHD + "/addresses/"
                  }
                  className="btn btn-info rounded-pill w-100"
                >
                  {t("Carta.Hacer-pedido")}
                </a>
              </div>
            </Modal.Footer>
          </Modal>
        )}
        {/* End Product Details Modal */}

        {/* Allergens Modal */}
        {this.state.ModalShow && this.state.alergens && (
          <Modal
            className="text-center"
            centered
            size="sm"
            show={this.state.alergens}
            onHide={() => this.setState({ alergens: false })}
          >
            <Modal.Body>
              <div className="d-flex flex-column justify-content-center">
                <button
                  className="text-left border-0 bg-transparent shadow-none"
                  onClick={() => this.setState({ alergens: false })}
                >
                  <img className="close-size" src={back} alt="Back" />
                </button>

                {this.state.product.nutrition.weight !== 0 && (
                  <React.Fragment>
                    <h4 className="sans-bold ml-2 mt-4 text-left">
                      {t("Carta.Valor-nutritional")}
                    </h4>
                    <div className="d-flex flex-column text-left mt-2 ml-2 mr-2">
                      <div className="d-flex justify-content-between">
                        <p>{t("Carta.Weight")}</p>
                        <p>{this.state.product.nutrition.weight}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>{t("Carta.Kcal")}</p>
                        <p>{this.state.product.nutrition.kcal}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>{t("Carta.Proteins")}</p>
                        <p>{this.state.product.nutrition.proteins}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>{t("Carta.Carbs")}</p>
                        <p>{this.state.product.nutrition.carbs}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>{t("Carta.Sugar")}</p>
                        <p>{this.state.product.nutrition.sugar}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>{t("Carta.Fat")}</p>
                        <p>{this.state.product.nutrition.fat}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>{t("Carta.Transfat")}</p>
                        <p>{this.state.product.nutrition.transfat}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>{t("Carta.Fibers")}</p>
                        <p>{this.state.product.nutrition.fibers}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>{t("Carta.Sodium")}</p>
                        <p>{this.state.product.nutrition.sodium}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>{t("Carta.Salt")}</p>
                        <p>{this.state.product.nutrition.salt}</p>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {this.state.product.allergens.length !== 0 && (
                  <React.Fragment>
                    <h4 className="mt-3 ml-2 text-left sans-bold">
                      {t("Carta.Allergens")}
                    </h4>
                    {this.state.product.allergens.map((item, idx) => (
                      <div
                        key={idx}
                        className="d-flex justify-content-between align-items-center ml-2 mt-4"
                      >
                        <span className="text-left">{item.name}</span>
                        <img
                          src={item.image}
                          alt="Allergens"
                          className="close-size"
                        />
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </Modal.Body>
          </Modal>
        )}
        {/* End Allergens Modal */}
      </div>
      </>
    );
  }
}

export default withTranslation()(Carta);
