import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import config from "../../config/config";
import {analyticsEvents} from "../../analytics/Analytics";

export default function ControlledCarousel(props) {
  const [index, setIndex] = useState(0);
  const history = useHistory();

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const linkRedirect = (val) => {
    analyticsEvents.logNewsClickEvent(val.id, val.name, props.source)
    if (val.url) {
      return window.open(val.url, "_blank").focus();
    } else if (val.sec === "cercademi") {
      return history.push("/restaurantes");
    } else if (val.sec === "carta") {
      return history.push(`/carta/?id=${val.id}`);
    } else if (val.sec === "homedelivery") {
      return window.open(config.homeDeliveryUrl, "_self");
    }
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {props.item.map((val, idx) => (
        <Carousel.Item key={idx}>
          <div
            role="button"
            className="stretched-link"
            onClick={() => linkRedirect(val)}
          >
            <img className="d-block w-100" src={val.image} alt="Popeyes" />
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
