import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { userService } from "../../services/user.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import warning             from "../../assets/img/delete/warning.svg";
import close               from "../../assets/img/buttons/TOCLOSE.svg";
import {ScreenName} from "../../analytics/analytics.config";
import {analyticsEvents} from "../../analytics/Analytics";
import "./PersonalData.scss";

class PersonalData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      news: "",
      open: false,
      message: "",
      success: false,
      error: false,
      loading: false,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    userService.getUser().then((response) => {
      this.setState({
        email: response.data.email,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        phone: response.data.phone,
        news: response.data.news,
      });
    });
    analyticsEvents.logScreenView(ScreenName.PERSONAL_DATA)
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { email, firstName, lastName, phone, news } = this.state;
    this.setState({ loading: true });
    userService
      .updateUser(email, firstName, lastName, phone, news)
      .then(() => {
        let message = "";
        if (window.localStorage.getItem("i18nextLng") === "es") {
          message = "Usuario actualizado con éxito";
        } else if (window.localStorage.getItem("i18nextLng") === "en") {
          message = "User updated successfully";
        } else if (window.localStorage.getItem("i18nextLng") === "cat") {
          message = "L'usuari s'ha actualitzat correctament";
        }
        this.setState({
          loading: false,
        });
        const error = false;
        const success = true;
        this.props.notificationHandler(success, error, message);
      },
      (errorMessage) => {
        this.setState({
          loading: false,
        });
        const message = errorMessage
        const error = true;
        const success = false;
        this.props.notificationHandler(success, error, message);
      }
      );
  };

  handleDelete = () => {
    this.setState({ loading: true });
    userService
      .deleteUser()
      .then(() => {
        let message = "";
        if (window.localStorage.getItem("i18nextLng") === "es") {
          message = "Usuario eliminado exitosamente";
        } else if (window.localStorage.getItem("i18nextLng") === "en") {
          message = "User deleted successfully";
        } else if (window.localStorage.getItem("i18nextLng") === "cat") {
          message = "L'usuari s'ha suprimit correctament";
        }
        this.setState({
          loading: false,
          open: false,
        });
        const error = false;
        const success = true;
        this.props.notificationHandler(success, error, message);
        localStorage.removeItem("Popeyes-Token");
        window.location.href = "/";
      },
      (errorMessage) => {
        this.setState({
          loading: false,
        });
        const message = errorMessage
        const error = true;
        const success = false;
        this.props.notificationHandler(success, error, message);
      }
      );
  };

  handleModal = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { t } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid w-100 personal-data">
          <div className="row align-items-center justify-content-center">
            <div className="title bg-primary d-flex justify-content-center align-items-center w-100">
              <div>
                <h1 className="text-white title-h1 mt-4">
                  {t("Profile.Datos-personales")}
                </h1>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="card border-0 d-flex justify-content-center">
                <div className="card-body d-flex justify-content-center">
                  <div className="col-md-6">
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <label className="text-secondary">
                          {t("Form.Nombre")}
                        </label>
                        <input
                          required
                          className="form-control"
                          type="text"
                          name="firstName"
                          value={this.state.firstName}
                          onChange={(event) =>
                            this.setState({ firstName: event.target.value })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label className="text-secondary">
                          {t("Form.Apellido")}
                        </label>
                        <input
                          required
                          className="form-control"
                          type="text"
                          name="LastName"
                          value={this.state.lastName}
                          onChange={(event) =>
                            this.setState({ lastName: event.target.value })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label className="text-secondary">
                          {t("Form.Email")}
                        </label>
                        <input
                          required
                          className="form-control"
                          type="email"
                          name="email"
                          value={this.state.email}
                          onChange={(event) =>
                            this.setState({ email: event.target.value })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label className="text-secondary">
                          {t("Form.Teléfono")}
                        </label>
                        <input
                          required
                          className="form-control"
                          type="number"
                          name="phone"
                          value={this.state.phone}
                          onChange={(event) =>
                            this.setState({ phone: event.target.value })
                          }
                        />
                      </div>
                      <div className="form-group d-flex justify-content-between align-items-center border-top">
                        <div className="round mt-3 mr-4">
                          <input
                            type="checkbox"
                            id="checkbox"
                            name="news"
                            checked={this.state.news}
                            onChange={() =>
                              this.setState({ news: !this.state.news })
                            }
                          />
                          <label htmlFor="checkbox"></label>
                        </div>
                        <span className="text-secondary mt-3">
                          {t("Form.Recibir-comunicaciones")}
                        </span>
                      </div>

                      <Link
                        className="text-decoration-none mt-3"
                        to="/change-password"
                      >
                        <div className="form-group d-flex justify-content-between border-top text-dark">
                          <span className="mt-3 text-secondary">
                            {t("Form.Contraseña")}
                          </span>
                          <div className="mt-3 mr-1">
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </div>
                      </Link>

                      <div role="button" onClick={this.handleModal}>
                        <div className="form-group d-flex justify-content-between border-top">
                          <span className="mt-3 text-secondary">
                            {t("Profile.Borrar-mi-cuenta")}
                          </span>
                          <div className="mt-3 mr-1">
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </div>
                      </div>
                      <Modal
                        className="text-center"
                        centered
                        size="md"
                        show={this.state.open}
                        onHide={this.handleModal}
                      >
                        <Modal.Body>
                          <div className="d-flex flex-column justify-content-center">
                            <button
                              className="text-left border-0 bg-transparent shadow-none"
                              onClick={() => this.setState({ open: false })}
                            >
                              <img
                                className="close-size"
                                src={close}
                                alt="Close"
                              />
                            </button>
                            <div>
                              <img
                                className="nola-size"
                                src={warning}
                                height="181"
                                width="138"
                                alt="warning"
                              />
                            </div>
                            <span className="mt-3 sans-bold">
                              {t("Profile.Borrar-mi-cuenta")}
                            </span>
                            <br />
                            <span className="p-5 top mb-4">
                              {t("Profile.Borrar-mi-cuenta-paragraf-1")}{" "}
                              {t("Profile.Borrar-mi-cuenta-paragraf-2")}
                            </span>
                          </div>
                          <div className="top-buttons mb-3">
                            <button
                              className="w-25 btn btn-primary rounded-pill mr-2"
                              onClick={this.handleModal}
                            >
                              {t("Profile.Volver")}
                            </button>
                            <button
                              className="btn btn-danger rounded-pill ml-2"
                              onClick={this.handleDelete}
                            >
                              {t("Profile.Borrar-mi-cuenta")}
                            </button>
                          </div>
                        </Modal.Body>
                      </Modal>
                      <div className="form-group d-flex justify-content-center mt-5">
                        <button
                          type="submit"
                          className="w-50 btn btn-primary rounded-pill submit-button"
                        >
                          {t("Profile.Guardar")}{" "}
                          {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(PersonalData);
